import { observer } from "mobx-react-lite";
import { ReactNode } from "react";

import {
  Card,
  dataAttribute,
  DataAttributes,
  FontSizes,
  IconButton,
  Link,
  MessageBar,
  MessageBarType,
  Stack,
  StateBlock,
  TooltipHost
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  ClaimStatuses,
  ErrorMessageDto
} from "@libs/gateways/acc/AccGateway.dtos.ts";
import {
  AppointmentStatusCode,
  CalendarEventType
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { ClaimAdjustmentLodgeError } from "@modules/acc/screens/claim-adjustment/components/ClaimAdjustmentLodgeError.tsx";
import { ClaimDiagnosisOverview } from "@modules/acc/screens/claim-management/components/ClaimDiagnosisOverview.tsx";
import { ClaimFormDataAttributes } from "@modules/acc/screens/claim/components/ClaimFormEnums.ts";
import { ClaimStatusBadge } from "@modules/acc/screens/shared-components/claim-status-badge/ClaimStatusBadge.tsx";
import { ErrorMessageBar } from "@modules/acc/screens/shared-components/ErrorMessageBar.tsx";
import { ConditionModal } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/condition-modal/ConditionModal.tsx";
import { ConditionContext } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/ConditionContext.ts";
import { ConditionHelper } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/ConditionHelper.ts";
import { getOverviewCardErrors } from "@modules/clinical/utils/clinical.utils.ts";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { DiagnosisListItem } from "../DiagnosisList.tsx";
import { OverviewCardGetStatusError } from "./OverviewCardGetStatusError.tsx";
import { OverviewCardWarning } from "./OverviewCardWarning.tsx";

interface OverviewCardProps {
  cardId: string;
  status?: string;
  referralIn?: boolean;
  canEdit?: boolean;
  errorMessageList?: ErrorMessageDto[];
  editDisabled?: boolean;
  isClaim?: boolean;
  conditionHelper: ConditionHelper;
  insurerStateBlock?: ReactNode;
  initialConsultDate?: DateTime;
  initialCalendarEvent?: CalendarEvent;
  onDialogOpened: () => void;
  isViewMode?: boolean;
  diagnoses: DiagnosisListItem[];
}

interface OverviewCardBaseProps extends OverviewCardProps {
  conditionHelper: ConditionHelper;
}

export const OverviewCardBase: React.FC<OverviewCardBaseProps> = observer(
  ({
    errorMessageList,
    conditionHelper,
    cardId,
    status,
    editDisabled,
    isClaim,
    insurerStateBlock,
    referralIn,
    initialConsultDate,
    initialCalendarEvent,
    onDialogOpened,
    diagnoses
  }) => {
    const { booking, acc } = useStores();

    const statusText = acc.ref.claimStatuses.keyTextValues.find(
      x => x.key === status
    )?.text;

    const handleDialogOpened = () => {
      onDialogOpened();
    };

    const alignItems = insurerStateBlock ? "initial" : "center";

    const stateBlockStyles = {
      root: {
        alignItems,
        padding: "12px 16px",
        width: 0
      },
      wrapper: {
        alignItems
      }
    };

    const errors = getOverviewCardErrors(errorMessageList);

    const messageBarIconStyles = { iconContainer: { alignItems: "center" } };

    return (
      <div id={cardId}>
        <Card
          heading="Overview"
          headingLevel="section-heading"
          iconName="DocumentSet"
          button={
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 16 }}
            >
              <ClaimStatusBadge claimStatus={status} />
              <TooltipHost
                content={
                  editDisabled
                    ? `Cannot edit ${statusText?.toLowerCase()} ${
                        isClaim ? "claim" : "condition"
                      }`
                    : undefined
                }
              >
                <IconButton
                  {...dataAttribute(
                    DataAttributes.Element,
                    ClaimFormDataAttributes.overviewEditButton
                  )}
                  onClick={() => handleDialogOpened()}
                  iconProps={{
                    iconName: "Edit",
                    styles: {
                      root: {
                        fontSize: FontSizes.size16
                      }
                    }
                  }}
                  disabled={editDisabled}
                />
              </TooltipHost>
            </Stack>
          }
        >
          <Stack tokens={{ childrenGap: 8 }}>
            {status === ClaimStatuses.GetStatusError && errors.firstError && (
              <OverviewCardWarning
                allAttemptsUsedUp={errors.attemptsUsedUp}
                attemptNumber={errors.attemptNumber}
                claimWithClientError={errors.claimWithClientError}
                lastRequestedDateTime={errors.lastRequestedDateTime}
                maxAttempts={errors.maxAttempts}
                nextRequestDateTime={errors.nextRequestDateTime}
              />
            )}

            {(status === ClaimStatuses.Held ||
              status === ClaimStatuses.Pending) &&
              errors.firstError && (
                <MessageBar
                  messageBarType={MessageBarType.warning}
                  styles={messageBarIconStyles}
                >
                  Claim status update queued and will be sent{" "}
                  {errors.nextRequestDateTime}
                </MessageBar>
              )}

            <OverviewCardGetStatusError
              conditionHelper={conditionHelper}
              errorDetails={errors.errorDetails}
              errorMessage={errors.errorMessage}
              status={status}
            />

            {errorMessageList &&
              status !== ClaimStatuses.GetStatusError &&
              status !== ClaimStatuses.Held &&
              status !== ClaimStatuses.Pending && (
                <ErrorMessageBar errorMessageList={errorMessageList} />
              )}

            {isClaim && referralIn && conditionHelper.condition?.claim?.id && (
              <ClaimAdjustmentLodgeError
                hideLink
                claimId={conditionHelper.condition.claim.id}
              />
            )}
            <Stack horizontal tokens={{ childrenGap: 16 }}>
              <StateBlock
                styles={stateBlockStyles}
                labelText="Referral"
                descText={referralIn ? "Referred" : "Not referred"}
              />
              {insurerStateBlock}
              <StateBlock
                styles={stateBlockStyles}
                labelText="Initial consult"
                descText={
                  initialConsultDate &&
                  initialCalendarEvent?.appointmentStatus ===
                    AppointmentStatusCode.Completed ? (
                    initialConsultDate.toDayDefaultFormat()
                  ) : (
                    <Link
                      onClick={() => {
                        if (initialCalendarEvent) {
                          booking.ui.showCalendarEventDialog({
                            type: CalendarEventType.Appointment,
                            id: initialCalendarEvent.id
                          });
                        } else {
                          booking.ui.showCalendarEventDialog({
                            type: CalendarEventType.Appointment,
                            initialValues: {
                              patientId: conditionHelper.patientId,
                              episodeOfCareId:
                                conditionHelper.condition?.episodeOfCareId,
                              startDate: DateTime.jsDateNow(),
                              expiryDate: DateTime.jsDateNow()
                            }
                          });
                        }
                      }}
                    >
                      {initialConsultDate
                        ? initialConsultDate.toDayDefaultFormat()
                        : "Book initial consult"}
                    </Link>
                  )
                }
              />
            </Stack>
            <ClaimDiagnosisOverview diagnoses={diagnoses} />
          </Stack>
        </Card>
        <ConditionContext.Provider value={conditionHelper}>
          <ConditionModal />
        </ConditionContext.Provider>
      </div>
    );
  }
);

export const OverviewCard = withFetch(
  x => [x.acc.ref.claimStatuses.load()],
  OverviewCardBase
);
