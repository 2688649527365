import { computed, observable, runInAction } from "mobx";

import { DateTime } from "@bps/utils";
import {
  AddClaimDto,
  ClaimDto,
  ClaimProviderDto,
  ClaimStatuses
} from "@libs/gateways/acc/AccGateway.dtos.ts";
import {
  DiagnosisDataItemDto,
  EpisodeOfCareDto,
  MedicalHistoryClinicalDataItemDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ClaimFormLabels } from "@modules/acc/screens/claim/components/ClaimFormEnums.ts";
import {
  addNewClaim,
  convertToClaimPatient,
  patchExistingClaim,
  trimHpiCpn
} from "@modules/acc/screens/claim/components/utils.ts";
import { ConditionsSidePanelHelper } from "@modules/clinical/screens/patient-record/components/claims/ConditionsSidePanelHelper.ts";
import { ClaimManagementModalFormValues } from "@shared-types/acc/claim-management-modal-values.type.ts";
import { ICondition } from "@shared-types/clinical/condition.interface.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { Claim } from "@stores/acc/models/Claim.ts";
import { Condition } from "@stores/clinical/models/Condition.ts";
import { Contact } from "@stores/practice/models/Contact.ts";

import { ConditionModalFormValues } from "../condition-modal/Condition.types.ts";

export class ConditionHelper {
  constructor(
    private root: IRootStore,
    private _patientId: string,
    private options: {
      onSubmit: (
        values: ConditionModalFormValues,
        helper: ConditionHelper
      ) => Promise<void>;
      onSubmitSucceeded?: (condition: ICondition) => void;
      initialCondition?: ICondition;
      sidePanelHelper?: ConditionsSidePanelHelper;
      linkConditionToEncounter?: (
        episodeOfCareId: string,
        claimId: string | undefined
      ) => Promise<void>;
    }
  ) {
    if (this.options.initialCondition)
      this.condition = this.options.initialCondition;
    runInAction(() => {
      this._initialCondition = this.options.initialCondition;
    });

    this._onSubmitSucceeded = this.options.onSubmitSucceeded;
  }

  @observable
  private _initialCondition: ICondition | undefined;

  private _onSubmitSucceeded;

  get acc() {
    return this.root.acc;
  }

  get userExperience() {
    return this.root.userExperience;
  }

  get clinical() {
    return this.root.clinical;
  }

  get practice() {
    return this.root.practice;
  }

  get notification() {
    return this.root.notification;
  }

  get patientId() {
    return this._patientId;
  }

  private _condition: ICondition | undefined;

  public get condition(): ICondition | undefined {
    return this._condition;
  }

  public set condition(condition: ICondition) {
    this._condition = condition;
  }

  @computed
  public get initialCondition(): ICondition | undefined {
    return this._initialCondition;
  }

  public get claimHasInvalidTransitionStatus() {
    const invalidStatuses: string[] = [
      ClaimStatuses.Pending,
      ClaimStatuses.Held,
      ClaimStatuses.NotVerified,
      ClaimStatuses.Queued
    ];

    const hasInvalidStatus =
      !!this.initialCondition?.claim?.claimStatus &&
      invalidStatuses.includes(this.initialCondition.claim.claimStatus);

    return hasInvalidStatus;
  }

  public get claimHasBeenBilled() {
    const billed = this.initialCondition?.claim?.billed;
    const hasBeenBilled = !!billed && billed > 0;

    return hasBeenBilled;
  }

  @observable
  hiddenConditionModal: boolean = true;

  setHiddenConditionModal = (value: boolean) => {
    runInAction(() => {
      this.hiddenConditionModal = value;
    });
  };

  patientCommunications = (patientId: string) => {
    return this.practice?.contactsMap?.get(patientId)?.communications;
  };

  submitConditionModal = async (values: ConditionModalFormValues) => {
    await this.options.onSubmit(values, this);
  };

  createCondition = async (
    values: ConditionModalFormValues,
    providerId?: string,
    medicalHistories?: MedicalHistoryClinicalDataItemDto[]
  ) => {
    const medicalHistoriesForCondition = medicalHistories?.filter(
      x => x.diagnosisCode?.originalText === values?.diagnosis
    );

    const selectedDiagnoses: DiagnosisDataItemDto[] | undefined =
      medicalHistoriesForCondition?.map(x => ({
        diagnosisCode: x.diagnosisCode,
        diagnosisSide: x.diagnosisSide,
        isPrimaryDiagnosis: x.isPrimary
      }));

    // Create a new EpisodeOfCare
    const episodeOfCare = await this.clinical.addEpisodeOfCare({
      patientId: this.patientId,
      diagnoses: selectedDiagnoses,
      ...(values.referralIn && {
        referralNumber: values.referralNumber,
        referralProvider: values.referralProvider,
        referralDate: DateTime.jsDateToISODate(values.referralDate),
        isReferral: values.referralIn
      })
    });

    const condition: ICondition = {
      episodeOfCareId: episodeOfCare.id,
      primaryDiagnosis: values.diagnosis
        ? values.diagnosis
        : ClaimFormLabels.undiagnosed,
      referralNumber: episodeOfCare.referralNumber,
      referralProvider: episodeOfCare.referralProvider,
      referralDate: DateTime.jsDateFromISO(episodeOfCare.referralDate),
      isPrivate: true,
      createdDate: DateTime.fromISO(episodeOfCare.changeLog?.createdDate)!,
      isReferral: episodeOfCare.isReferral
    };

    // Is it private condition?
    if (values.private) {
      this.condition = condition;
    } else {
      // It is a claim condition. So create a new claim and claim-episodeOfCare

      // Create a new claim
      const claim = await this.addClaim(values, providerId);
      claim.sendAccForm = values.sendAccForm;

      // Create a new claim-episodeOfCare
      await this.acc.addClaimEpisodeOfCare(claim.id, episodeOfCare.id);

      this.condition = {
        ...condition,
        claim,
        patientId: claim.patientId,
        providerId: claim.providerId,
        isPrivate: false,
        sendAccForm: claim.sendAccForm
      };
    }

    this.options.linkConditionToEncounter &&
      (await this.options.linkConditionToEncounter(
        this.condition.episodeOfCareId,
        this.condition.claim?.id
      ));
  };

  updateCondition = async (
    values: ConditionModalFormValues,
    providerId?: string
  ) => {
    if (!this.initialCondition) return;

    if (this.hasClaimWritePermission()) {
      await this.handlePrivacyChange(values, providerId);
      if (!values.private) {
        await this.updateClaimAndCheckStatus(values, providerId);
      }
    }
    await this.updateEpisodeOfCare(values);

    this.condition = this.initialCondition;
  };

  private updateClaimAndCheckStatus = async (
    values: ConditionModalFormValues,
    providerId?: string
  ) => {
    await this.updateClaim(values, providerId);
    await this.queueCheckClaimStatusIfClaimNumberChanged(values);
  };

  updateClaimCondition = async (
    values: ConditionModalFormValues,
    providerId?: string
  ) => {
    if (
      !this.hasClaimWritePermission() ||
      !this.initialCondition ||
      !values.claimId
    )
      return;

    const claim = await this.updateClaim(values, providerId);
    this.updateReferralInfo(values, claim);
    await Promise.all([
      this.handlePrivacyChange(values),
      this.queueCheckClaimStatusIfClaimNumberChanged(values)
    ]);

    this.condition = this.initialCondition;
    return claim;
  };

  private hasClaimWritePermission(): boolean {
    return this.root.core.hasPermissions(Permission.ClaimWrite);
  }

  private handlePrivacyChange = async (
    values: ConditionModalFormValues,
    providerId?: string
  ) => {
    if (!this.initialCondition) return;
    if (values.private === this.initialCondition.isPrivate) return;

    if (values.private) {
      await this.changeToPrivate(this.initialCondition.claim?.id);
    } else {
      await this.changeToPublic(values, providerId);
    }

    runInAction(() => {
      if (this.condition) {
        this.condition.isPrivate = !!values.private;
      }
    });
  };

  private changeToPrivate = async (claimId?: string) => {
    if (!claimId) return;

    await this.acc.patchClaim({ id: claimId, private: true });
    await this.unlinkClaimFromEOC(claimId);
    await this.unlinkClaimAppointmentAndEncounter(claimId);
  };

  private changeToPublic = async (
    values: ConditionModalFormValues,
    providerId?: string
  ) => {
    const claim = await this.addClaim(values, providerId);
    runInAction(() => {
      if (this.condition) {
        this.condition.claim = claim;
      }
    });
    await this.addClaimEpisodeOfCareAndLinkToAppointments(claim.id);
    await this.linkClaimAppointment(claim.id);
  };

  private updateEpisodeOfCare = async (values: ConditionModalFormValues) => {
    if (!this.initialCondition) return;

    const episodeOfCare = await this.clinical.getEpisodeOfCare(
      this.initialCondition.episodeOfCareId
    );

    const updatedEOC = await this.clinical.updateEpisodeOfCare(
      this.createUpdatedEOCData(episodeOfCare, values),
      episodeOfCare.id
    );
    this.updateInitialConditionWithEOC(updatedEOC);
  };

  private createUpdatedEOCData = (
    episodeOfCare: EpisodeOfCareDto,
    values: ConditionModalFormValues
  ) => {
    return {
      ...episodeOfCare,
      referralNumber: this.getReferralNumber(values),
      referralProvider: values.referralIn ? values.referralProvider : undefined,
      referralDate: values.referralIn
        ? DateTime.jsDateToISODate(values.referralDate)
        : undefined,
      isReferral: values.referralIn
    };
  };

  private getReferralNumber = (values: ConditionModalFormValues) => {
    if (values.referralIn) return values.referralNumber;
    if (
      this.initialCondition?.claim?.canUpdateClaimNumber &&
      values.referralNumber !== this.initialCondition.referralNumber
    ) {
      return values.referralNumber;
    }
    return undefined;
  };

  private updateInitialConditionWithEOC = (updatedEOC: EpisodeOfCareDto) => {
    runInAction(() => {
      if (this.initialCondition) {
        this._initialCondition = {
          ...this.initialCondition,
          referralNumber: updatedEOC.referralNumber,
          referralProvider: updatedEOC.referralProvider,
          referralDate: DateTime.jsDateFromISO(updatedEOC.referralDate),
          isReferral: updatedEOC.isReferral
        };
      }
    });
  };

  private updateReferralInfo = (
    values: ConditionModalFormValues,
    claim: Claim
  ) => {
    runInAction(() => {
      if (this.initialCondition) {
        this.initialCondition.referralNumber = values.referralIn
          ? claim.claimNumber
          : undefined;
        this.initialCondition.referralDate = values.referralIn
          ? values.referralDate
          : undefined;
        this.initialCondition.referralProvider = values.referralIn
          ? values.referralProvider
          : undefined;
        this.initialCondition.isReferral = values.referralIn;
      }
    });
  };

  private unlinkClaimFromEOC = async (claimId: string) => {
    if (!this.initialCondition) return;

    const claimEpisodesOfCare = await this.acc.getClaimEpisodesOfCare({
      claimId,
      episodesOfCare: [this.initialCondition.episodeOfCareId]
    });

    if (claimEpisodesOfCare.length) {
      const claimEOC = claimEpisodesOfCare[0];
      claimEOC.isActive = false;
      await this.acc.updateClaimEpisodeOfCare(claimEOC);
    }
  };

  private unlinkClaimAppointmentAndEncounter = async (claimId: string) => {
    if (!this.initialCondition) return;
    if (
      this.options.sidePanelHelper?.isLinkedCondition(
        this.initialCondition.episodeOfCareId
      )
    ) {
      await this.options.sidePanelHelper?.unlinkClaimAppt(claimId);
    }
  };

  private linkClaimAppointment = async (claimId: string) => {
    if (!this.initialCondition) return;
    if (
      this.options.sidePanelHelper?.isLinkedCondition(
        this.initialCondition.episodeOfCareId
      )
    ) {
      await this.options.sidePanelHelper?.linkClaimAppointment(claimId);
    }
  };

  addClaimEpisodeOfCareAndLinkToAppointments = async (claimId: string) => {
    if (!this.initialCondition) return;

    await this.acc.addClaimEpisodeOfCare(
      claimId,
      this.initialCondition.episodeOfCareId
    );

    // Link any previously created private appointments
    if (this.initialCondition.calendarEventIds) {
      await Promise.all(
        this.initialCondition.calendarEventIds.map(id =>
          this.acc
            .getClaimAppointmentDtos({ calendarEventId: id })
            .then(claimApps => {
              return !claimApps.length
                ? this.acc.addClaimAppointment({
                    claimId,
                    calendarEventId: id
                  })
                : undefined;
            })
        )
      );
    }
  };

  private getClaimDto = (
    values: ClaimManagementModalFormValues,
    patient?: Contact
  ): AddClaimDto => {
    const claimPatient = convertToClaimPatient({
      practice: this.practice,
      contact: patient
    });

    const {
      isDischarged,
      discharged,
      referralIn,
      claimNumber,
      insurerContactId,
      referralDate,
      ...rest
    } = values;

    return {
      ...rest,
      ...claimPatient,
      referralIn: referralIn ?? false,
      id: values.id,
      claimNumber,
      statusCode: values.claimStatusCode,
      discharged: isDischarged
        ? discharged || DateTime.now().toISODate()
        : undefined,
      insurerContactId: values.private ? undefined : insurerContactId,
      patientDateOfBirth: claimPatient
        ? DateTime.jsDateToISODate(claimPatient.patientDateOfBirth)
        : undefined,
      referralDate: DateTime.jsDateToISODate(referralDate)
    };
  };

  handleSubmitSucceeded = (values: ConditionModalFormValues) => {
    const typeDescription = values.private ? "private" : "claim";
    if (this.initialCondition)
      this.notification.success(
        `The ${typeDescription} condition has been updated`
      );
    else
      this.notification.success(
        `A new ${typeDescription} condition has been created`
      );

    this.setHiddenConditionModal(true);

    if (this._onSubmitSucceeded && this.condition) {
      this._onSubmitSucceeded(this.condition);
    }
  };

  private async queueCheckClaimStatusIfClaimNumberChanged(
    values: ConditionModalFormValues
  ) {
    if (
      !values.private &&
      this.initialCondition &&
      values.referralNumber &&
      values.referralNumber !== this.initialCondition.referralNumber &&
      this.condition?.claim?.canUpdateClaimNumber
    ) {
      const claimId = this.initialCondition.claim?.id;

      if (claimId) {
        await this.acc.queueCheckClaimStatus(claimId);

        runInAction(() => {
          this.initialCondition!.referralNumber = values.referralNumber;
        });
      }
    }
  }

  private async addClaim(
    values: ConditionModalFormValues,
    providerId?: string
  ): Promise<Claim> {
    // Get the patient
    const patient = await this.root.practice.getContact(this.patientId);
    const claimProviderDTO = providerId
      ? await this.getClaimProvider(providerId, values.referralIn)
      : {};

    const claimValues: ClaimManagementModalFormValues = {
      claimNumber: values.referralNumber?.toUpperCase(),
      patientId: this.patientId,
      providerId,
      referralIn: values.referralIn,
      insurerContactId: values.insurerContactId,
      claimStatusCode: values.claimStatusCode
    };

    const orgUnitData = this.condition?.claim?.getOrgUnitData();

    const claimDto = {
      ...this.getClaimDto(claimValues, patient),
      ...claimProviderDTO,
      ...orgUnitData
    };

    const insurer = values.insurerContactId
      ? await this.root.practice.getContact(values.insurerContactId)
      : undefined;
    claimDto.insurerName = insurer ? insurer.name : undefined;

    const parentOrgUnit = this.root.core.location?.parentOrgUnit?.id
      ? await this.root.practice.getOrgUnit(
          this.root.core.location.parentOrgUnit.id
        )
      : undefined;

    claimDto.hpiOrganisationNumber =
      parentOrgUnit?.nzOrgUnitIdentifier?.hpiOrganisationId;
    claimDto.practiceName =
      parentOrgUnit?.nzOrgUnitIdentifier?.hpiOrganisationName;
    claimDto.referralDate = DateTime.jsDateToISODate(values.referralDate);
    claimDto.referralProvider = values.referralProvider;
    claimDto.referralIn = values.referralIn ?? false;
    claimDto.statusCode =
      values.claimStatusCode ||
      (values.referralIn ? ClaimStatuses.NotVerified : undefined);

    let claim: Claim;

    if (claimDto.id) {
      claim = await patchExistingClaim(
        { ...claimDto, id: claimDto.id },
        this.root.acc
      );
    } else {
      claim = await addNewClaim(claimDto, this.root.acc);
    }

    return claim;
  }

  private async updateClaim(
    values: ConditionModalFormValues,
    providerId?: string
  ): Promise<Claim> {
    const claimProviderDTO = providerId
      ? await this.getClaimProvider(providerId, values.referralIn)
      : {};

    // Update the claim
    const claimValues: ClaimManagementModalFormValues = {
      claimNumber:
        values.referralIn || this.condition?.claim?.canUpdateClaimNumber
          ? values.referralNumber?.toUpperCase()
          : undefined,
      private: values.private,
      patientId: this.patientId,
      referralIn: values.referralIn,
      referralProvider: values.referralIn ? values.referralProvider : undefined,
      referralDate: values.referralIn ? values.referralDate : undefined,
      insurerContactId: values.insurerContactId,
      claimStatusCode: values.claimStatusCode
        ? values.claimStatusCode
        : ClaimStatuses.Incomplete,
      id: values.claimId
    };

    const insurer = values.insurerContactId
      ? await this.root.practice.getContact(values.insurerContactId)
      : undefined;

    const claim = values.claimId
      ? await this.root.acc.getClaim(values.claimId)
      : undefined;

    const {
      isDischarged,
      discharged,
      referralIn,
      claimNumber,
      insurerContactId,
      referralDate,
      ...rest
    } = claimValues;

    const claimToUpdate: Partial<ClaimDto> = {
      ...rest,
      ...claimProviderDTO,
      id: claimValues.id,
      claimNumber: claimNumber ? claimNumber : claim?.claimNumber,
      statusCode: values.claimStatusCode,
      referralIn: referralIn ?? claim?.referralIn ?? false,
      providerId: providerId ? providerId : claim?.providerId,
      discharged: isDischarged
        ? discharged || DateTime.now().toISODate()
        : undefined,
      insurerContactId: values.private ? undefined : insurerContactId,
      referralDate: DateTime.jsDateToISODate(referralDate),
      insurerName: insurer ? insurer.name : undefined,
      errorMessages:
        claimValues.claimStatusCode === ClaimStatuses.Accepted ||
        claimValues.claimStatusCode === ClaimStatuses.Accredited
          ? undefined
          : claim?.errorMessageList
    };

    if (claim?.eTag) {
      claimToUpdate.eTag = claim?.eTag;
    }

    let updatedClaim: Claim;

    if (claimToUpdate.id) {
      updatedClaim = await patchExistingClaim(
        { ...claimToUpdate, id: claimToUpdate.id },
        this.root.acc
      );
    } else {
      updatedClaim = await addNewClaim(claimToUpdate, this.root.acc);
    }

    return updatedClaim;
  }

  private async getClaimProvider(providerId: string, referralIn?: boolean) {
    const claimProvider: ClaimProviderDto = {};

    // Get the provider details
    const providerUser = await this.root.core.getUser(providerId);
    claimProvider.providerFirstName = providerUser?.firstName;
    claimProvider.providerMiddleName = providerUser?.middleName;
    claimProvider.providerLastName = providerUser?.lastName;

    const provider = await this.root.practice.getProvider(providerId);
    //max accepted hpi cpn length by ACC is 6
    claimProvider.hpiCpn = !referralIn ? trimHpiCpn(provider?.cpn) : undefined;
    const providerTypes = this.acc.getAvailableProviderTypes(
      provider?.contractTypes
    );

    if (providerTypes.length === 1) {
      claimProvider.providerTypeCode = providerTypes[0].key;
    }

    return claimProvider;
  }

  @computed
  get initialValues(): ConditionModalFormValues {
    const initialValues = {
      private:
        this.initialCondition?.isPrivate ??
        (!this.initialCondition &&
        this.root.core.hasPermissions(Permission.ClaimWrite)
          ? undefined
          : true),
      insurerContactId: this.initialCondition?.claim?.insurerContactId,
      insurerName: this.initialCondition?.claim?.insurerName,
      referralIn: this.initialCondition?.isReferral,
      referralNumber: this.initialCondition?.referralNumber,
      sendAccForm: this.initialCondition?.claim?.sendAccForm,
      referralProvider: this.initialCondition?.referralProvider,
      referralDate: this.initialCondition?.referralDate,
      claimStatusCode: this.initialCondition?.claim?.dto.statusCode,
      claimId: this.initialCondition?.claim?.dto.id
    };

    return initialValues;
  }

  public static getInitialCondition = (condition: Condition): ICondition => {
    return {
      episodeOfCareId: condition.id,
      primaryDiagnosis: condition.primaryDiagnosis,
      injuryDate: condition.injuryDate,
      referralNumber: condition.referralNumber,
      referralProvider: condition.referralProvider,
      referralDate: condition.referralDate?.toJSDate(),
      discharged: condition.discharged,
      isPrivate: condition.isPrivate,
      createdDate: condition.createdDate,
      patientId: condition.patientId,
      isReferral: condition.isReferral,
      claim: condition.claim,
      sendAccForm: condition.claim?.sendAccForm
    };
  };

  public static getClaimBadgeText = (condition?: ICondition) => {
    if (condition?.claim?.claimNumber) {
      return condition?.claim?.claimNumber;
    }

    if (condition && condition.isPrivate) {
      return "Private";
    }

    return "Not lodged";
  };
}
