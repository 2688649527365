import { useForm } from "react-final-form";

import {
  ActionButton,
  dataAttribute,
  DataAttributes,
  Heading,
  Stack
} from "@bps/fluent-ui";
import { newGuid } from "@bps/utils";
import { KeyTextValue } from "@libs/api/ref-data/RefDataAccessor.ts";
import { PaymentMethod } from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { useAllocationField } from "@modules/billing/screens/shared-components/allocation-field/hooks/useAllocationField.ts";
import { ManualAllocationModal } from "@modules/billing/screens/shared-components/allocation-field/manual-allocation-modal/ManualAllocationModal.tsx";
import { ItemSelectionMode } from "@modules/billing/screens/shared-components/allocation-field/types.ts";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";

import { useAllocationFormContext } from "../../context/AllocationFormContext.ts";
import {
  AllocationFormValues,
  allocationNameOf,
  PaymentFormValues
} from "../AllocationForm.types.ts";
import { PaymentMethodsField } from "./PaymentMethodsField.tsx";

export interface PaymentOption extends KeyTextValue<string> {
  max?: number;
}

export const PaymentsField: React.FC = () => {
  const { onPaymentLengthChange } = useAllocationFormContext();

  const { autoAllocateItems, allocatableAmount, allocationOption } =
    useAllocationField();

  const form = useForm<AllocationFormValues>();

  const { fields } = useFieldArray<PaymentFormValues>(
    allocationNameOf("payments"),
    { subscription: { value: true } }
  );

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <Stack horizontal horizontalAlign="space-between">
        <Heading labelPaddings children="Payment methods" />
        <ActionButton
          {...dataAttribute(
            DataAttributes.Element,
            "add-another-allocation-button"
          )}
          iconProps={{ iconName: "Add" }}
          onClick={() => {
            fields.push({
              amount: 0,
              method: PaymentMethod.Eftpos,
              id: newGuid()
            });
            onPaymentLengthChange &&
              onPaymentLengthChange({
                index: fields?.length || 0 + 1,
                values: form.getState().values,
                form,
                removed: false
              });
          }}
        >
          Add another
        </ActionButton>
      </Stack>
      <Stack
        tokens={{ childrenGap: 8 }}
        styles={(_, theme) => ({
          root: {
            padding: 8,
            border: `1px solid ${theme.palette.neutralLight}`,
            borderRadius: 2
          }
        })}
      >
        <PaymentMethodsField />
      </Stack>
      {allocationOption?.itemSelectionMode !== ItemSelectionMode.none &&
        allocationOption?.itemSelectionMode !== ItemSelectionMode.hidden && (
          <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 8 }}>
            <ManualAllocationModal />
            <ActionButton
              iconProps={{ iconName: "ActivateOrders" }}
              onClick={() => {
                autoAllocateItems(
                  allocatableAmount,
                  form.getState().values.allocations ?? []
                );
              }}
              text="Auto allocate to items"
            />
          </Stack>
        )}
    </Stack>
  );
};
