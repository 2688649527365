import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";

import { confirm, DefaultButton, Dialog, Heading, Stack } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  AttendeeTypeEnum,
  CalendarEventPriority,
  CalendarEventType
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { AppointmentFormValues } from "@shared-types/booking/appointment-form-values.types.ts";
import { toAttendees } from "@stores/booking/models/CalendarEvent.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { Selection } from "@ui-components/ShimmeredDetailsList/Selection.ts";

import { AppointmentInfoCard } from "../../../booking-calendar/components/appointment-dialog/components/AppointmentInfoCard.tsx";
import {
  WaitingListDialogTable,
  WaitingListItemModelWithKey
} from "./WaitingListDialogTable.tsx";
import { WaitingListRebookAppointmentButtons } from "./WaitingListRebookAppointmentButtons.tsx";

interface WaitingListReBookAppointmentDialogProps {
  providerId: string | undefined;
  startTime: DateTime;
  endTime: DateTime;
  orgUnitId: string | undefined;
}

const WaitingListReBookAppointmentDialogBase: React.FC<WaitingListReBookAppointmentDialogProps> =
  observer(({ providerId, startTime, endTime, orgUnitId }) => {
    const { booking, core, notification } = useStores();
    const { deleteCancelledAppointmentDetails, updateSelectedCount } =
      booking.ui;

    const startDate = startTime.toJSDate();
    const apptStartTime = startTime.toTimeInputFormat();

    const duration = endTime.diff(startTime, "minutes").minutes;
    const [hidden, setHidden] = useState<boolean>(false);

    const waitingListResults = Array.from(
      booking.waitingListsMap.values()
    ).filter(
      value =>
        (value.orgUnitId === orgUnitId || value.anyLocation) &&
        (value.attendees.find(x => x.attendeeId === providerId) ||
          value.anyProvider) &&
        value.until &&
        DateTime.fromISO(value.until).startOf("day") >=
          startTime.startOf("day") &&
        duration &&
        (Number(value.duration) || 0) <= duration
    );

    const handleDismiss = () => {
      setHidden(true);
      deleteCancelledAppointmentDetails();
      booking.deleteTemporaryReservation();
    };

    if (waitingListResults.length === 0) {
      deleteCancelledAppointmentDetails();
    } else {
      booking.addTemporaryReservation({
        priority: CalendarEventPriority.Low,
        type: CalendarEventType.TemporaryReservation,
        startTime: startTime.toISO(),
        endTime: endTime.toISO(),
        bookedBy: core.userId,
        attendees: toAttendees({ userId: providerId }),
        orgUnitId: core.locationId
      });
    }

    const selection = useRef(
      new Selection<WaitingListItemModelWithKey>({
        onSelectionChanged: () => {
          const count = selection.current.getSelection().length;
          if (booking.ui.cancelledAppointmentDetails?.selectedCount !== count) {
            updateSelectedCount(count);
          }
        }
      })
    );

    const confirmWLItem = () => {
      const index = selection.current.getSelectedIndices();
      const itemKey = index[0];
      const wlItem = waitingListResults[itemKey];
      const patientId = wlItem.attendees?.find(
        x => x.type === AttendeeTypeEnum.contact
      )?.attendeeId;

      const initialValues: AppointmentFormValues = {
        providerId: providerId ?? "",
        startDate,
        startTime: apptStartTime,
        patientId: patientId ?? "",
        patientAddedFromWL: true,
        waitingListIds: [wlItem.id],
        originalWaitingListBookingId: wlItem.calenderEventId,
        appointmentTypeId: wlItem.appointmentTypeId,
        duration: wlItem.duration,
        orgUnitId: wlItem.orgUnitId
      };

      booking.ui.showCalendarEventDialog({
        type: CalendarEventType.Appointment,
        initialValues
      });

      setHidden(true);
      deleteCancelledAppointmentDetails();
    };

    const deleteWLItem = async () => {
      const selectedIndices = selection.current.getSelectedIndices();
      if (!selectedIndices.length) return;

      const itemKey = selectedIndices[0];
      const items = selection.current.getItems();
      const wlItem = items[itemKey];

      const patientFullName = wlItem.contact?.model?.fullName;

      if (
        await confirm({
          dialogContentProps: {
            title: "Remove from waiting list",
            subText: `Are you sure you want to remove ${patientFullName} from the waiting list?`
          },
          confirmButtonProps: {
            text: "Confirm"
          }
        })
      ) {
        try {
          if (selection.current.isIndexSelected(itemKey)) {
            await booking.deleteWaitingListRecord(wlItem.id);
          }
        } catch (error) {
          notification.error(error.message);
        }
      }
    };

    return (
      <Stack>
        <Dialog
          styles={{ root: { overflowY: "none" } }}
          hidden={hidden}
          onDismiss={handleDismiss}
          dialogContentProps={{
            title: (
              <Heading variant="modal-heading">
                Replace appointment from waiting list
              </Heading>
            ),
            showCloseButton: true,
            styles: {
              content: {
                display: "flex",
                flexDirection: "column"
              }
            }
          }}
          minWidth={950}
        >
          <AppointmentInfoCard
            providerId={providerId}
            startTime={apptStartTime}
            startDate={startDate}
            duration={duration}
          />
          <WaitingListDialogTable
            items={waitingListResults}
            selection={selection}
          />

          <Stack
            horizontal
            horizontalAlign="end"
            styles={{ root: { paddingTop: 16 } }}
            tokens={{ childrenGap: 8 }}
          >
            <WaitingListRebookAppointmentButtons
              confirmWLItem={confirmWLItem}
              deleteWLItem={deleteWLItem}
            />

            <DefaultButton
              id="cancelAddPtWLDialog"
              onClick={handleDismiss}
              text="Cancel"
            />
          </Stack>
        </Dialog>
      </Stack>
    );
  });

const WaitingListReBookAppointmentDialog = observer(() => {
  const { booking } = useStores();

  if (booking.ui.cancelledAppointmentDetails) {
    const { providerId, timeOptions, orgUnitId } =
      booking.ui.cancelledAppointmentDetails;

    if (timeOptions) {
      const { startTime, endTime } = timeOptions;
      if (startTime && endTime) {
        return (
          <DataFetcher
            fetch={() => booking.getWaitingListRecordsFilteredValues({})}
          >
            {() => (
              <WaitingListReBookAppointmentDialogBase
                providerId={providerId}
                startTime={startTime}
                endTime={endTime}
                orgUnitId={orgUnitId}
              />
            )}
          </DataFetcher>
        );
      }
    }
  }
  return null;
});

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default WaitingListReBookAppointmentDialog;
