import { IReportColumn } from "bps-powerbi-helper";
import { useField, useForm } from "react-final-form";

import {
  DetailsList,
  FontIcon,
  Heading,
  IDragDropEvents,
  mergeStyles,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";

import { DropZone } from "../DropZone.tsx";
import { ReportColumnChooserValues } from "./ReportColumnChooserValues.ts";

const nameOf = nameOfFactory<ReportColumnChooserValues>();

export interface ReportColumnChooserListProps {
  heading: string;
  items: IReportColumn[];
  field: keyof ReportColumnChooserValues;
  dropZoneText: string;
  background?: string;
  doubleClickTo?: keyof ReportColumnChooserValues;
  onDrop?: (droppedAtItem: IReportColumn) => void;
  onDragStart?: (draggedItems: IReportColumn[]) => void;
}

export const ReportColumnChooserList = ({
  items,
  dropZoneText,
  heading,
  field,
  background,
  doubleClickTo
}: ReportColumnChooserListProps) => {
  const { batch, mutators, change, getState } =
    useForm<ReportColumnChooserValues>();

  const { values } = getState();

  const theme = useTheme();
  const {
    input: { value: dragFromItem }
  } = useField<IReportColumn>(nameOf("dragFromItem"), {
    subscription: { value: true }
  });

  const {
    input: { value: dragFromField }
  } = useField<keyof ReportColumnChooserValues>(nameOf("dragFromField"), {
    subscription: { value: true }
  });

  const findIndex = (list: IReportColumn[], item: IReportColumn) => {
    return list.findIndex(x => x.powerBiName === item.powerBiName);
  };

  const handleDrop = (insertIndex: number) => {
    batch(() => {
      const fromColumns = values[dragFromField] as IReportColumn[];
      const index = findIndex(fromColumns, dragFromItem);
      if (index >= 0) {
        mutators.remove(dragFromField, index);
      }
      mutators.insert(field, insertIndex, dragFromItem);
      change(nameOf("dragFromItem"), undefined);
    });
  };

  const onItemInvoked = (index: number) => {
    doubleClickTo &&
      batch(() => {
        const fromItemList = items[index];
        mutators.remove(field, index);
        mutators.push(doubleClickTo, fromItemList);
        change(nameOf("dragFromField"), field);
      });
  };

  const columns = [
    {
      name: "Icon",
      key: "icon",
      minWidth: 20,
      maxWidth: 20,
      onRender: () => (
        <FontIcon
          iconName="GripperBarHorizontal"
          styles={{ root: { fontSize: 16 } }}
        />
      )
    },
    {
      name: "name",
      key: "name",
      minWidth: 20,
      fieldName: "powerBiName"
    }
  ];

  const dragEnterClass = mergeStyles({
    backgroundColor: theme.palette.neutralLight
  });

  const dragDropEvents: IDragDropEvents = {
    canDrop: () => true,
    canDrag: () => true,
    onDrop: droppedAtItem => {
      const index = findIndex(items, droppedAtItem);
      handleDrop(index);
    },
    onDragEnter: () => {
      // return string is the css classes that will be added to the entering element.
      return dragEnterClass;
    },
    onDragLeave: () => {
      return;
    },
    onDragStart: draggedItem => {
      batch(() => {
        change(nameOf("dragFromItem"), draggedItem);
        change(nameOf("dragFromField"), field);
      });
    },
    onDragEnd: () => {
      batch(() => {
        change(nameOf("dragFromItem"), undefined);
      });
    }
  };

  const gridStyles = {
    root: {
      overflowX: "hidden",
      overflowY: "auto",
      background
    },
    contentWrapper: {
      overflowY: "auto",
      overflowX: "visible",
      width: "100%",
      background
    },
    focusZone: {
      background: background ? theme.palette.white : undefined
    }
  };

  return (
    <Stack
      styles={{
        root: {
          height: "100%",
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: theme.palette.neutralLight,
          padding: "8px",
          background
        }
      }}
    >
      <Heading>{heading}</Heading>
      <Stack
        styles={{
          root: {
            padding: "8px",
            overflowX: "hidden",
            overflowY: "auto",
            height: "100%"
          }
        }}
      >
        <DetailsList
          styles={gridStyles}
          onRenderMissingItem={() => {
            // Whenever our list contains a null, we will render this instead of the normal row.
            // We can therefore use this to be able to render a special control at the end of the list
            // This allows us to have a drop zone at the end of a list (or at the start of an empty list)
            return (
              <DropZone
                textProps={{ text: dropZoneText }}
                showBoxShadow={false}
                onDrop={() => handleDrop(items.length)}
              />
            );
          }}
          onRenderRow={(props, defaultRender) => {
            return props && defaultRender
              ? defaultRender({
                  ...props,
                  styles: {
                    root: { background }
                  }
                })
              : null;
          }}
          isHeaderVisible={false}
          dragDropEvents={dragDropEvents}
          onItemInvoked={(_, index) => index && onItemInvoked(index)}
          columns={columns}
          items={items.length ? [...items, null] : [null]}
        />
      </Stack>
    </Stack>
  );
};
