import { FormApi } from "final-form";
import { observer } from "mobx-react-lite";
import React, { useRef } from "react";

import { confirm } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { AttendeeTypeEnum } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { BusinessRoleClasses } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useBookingCalendarScreenContext } from "@modules/booking/screens/booking-calendar/context/BookingCalendarScreenContext.tsx";
import { toAttendees } from "@stores/booking/models/CalendarEvent.ts";
import { WaitingListItemModel } from "@stores/booking/models/WaitingListModel.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { SubmissionFormDialog } from "@ui-components/form/submission-form-dialog/SubmissionFormDialog.tsx";

import { WaitingListItem } from "../../WaitingListScreen.types.ts";
import { WaitingListItemFormFields } from "./WaitingListItemFormFields.tsx";
import { WaitingListItemFormValidator } from "./WaitingListItemFormValidator.ts";
import { WaitingListItemFormValues } from "./WaitingListItemFormValues.ts";

export interface WaitingListDialogProps {
  title: string;
  item?: WaitingListItem;
  closeDialog: (value: boolean) => void;
}

const validator = new WaitingListItemFormValidator();

export const WaitingListItemDialog: React.FunctionComponent<WaitingListDialogProps> =
  observer(({ title, item, closeDialog }) => {
    const { startDate } = useBookingCalendarScreenContext();

    const { booking, core, practice } = useStores();
    const handleDismiss = () => {
      closeDialog(true);
    };

    const getWaitingListDefaultValues = () => {
      if (item) {
        const patientId = item.attendees.filter(
          val => val.type === AttendeeTypeEnum.contact
        )[0].attendeeId;

        const providerId = item.attendees.filter(
          val => val.type === AttendeeTypeEnum.user
        )[0].attendeeId;

        return {
          id: item.id,
          appointmentTypeId: item.appointmentTypeId,
          duration: item.duration,
          priority: item.priority,
          anyProvider: item.anyProvider,
          anyLocation: item.anyLocation,
          expiryDate: DateTime.fromISO(item.until).toJSDate(),
          patientId,
          providerId,
          comments: item.content,
          orgUnitId: item.orgUnitId ?? core.locationId
        } as WaitingListItemFormValues;
      }

      const appointmentType = booking.activeAppointmentTypes[0];
      const expiryDate = startDate.toJSDate();

      return {
        bookedBy: core.userId,
        duration: appointmentType && appointmentType.duration,
        appointmentTypeId: appointmentType && appointmentType.id,
        expiryDate,
        anyProvider: false,
        anyLocation: false,
        priority: 3,
        providerId: core.user?.businessRoleClasses.includes(
          BusinessRoleClasses.Provider
        )
          ? core.userId
          : undefined,
        orgUnitId: core.user?.availableOrgUnitIds
          ? core.user?.availableOrgUnitIds[0]
          : core.locationId
      };
    };

    const initialValues = useRef<Partial<WaitingListItemFormValues>>({
      ...getWaitingListDefaultValues()
    });

    const onSubmit = async (values: WaitingListItemFormValues) => {
      const baseRequest = {
        until: DateTime.fromJSDate(values.expiryDate).toISO(),
        priority: values.priority,
        anyProvider: values.anyProvider,
        anyLocation: values.anyLocation,
        bookedBy: values.bookedBy || core.userId,
        content: values.comments,
        attendees: toAttendees({
          contactId: values.patientId,
          userId: values.providerId
        }),
        appointmentTypeId: values.appointmentTypeId,
        duration: values.duration,
        orgUnitId: values.orgUnitId ?? core.locationId
      };
      if (item) {
        if (item.startTime) {
          const startTime = DateTime.fromISO(item.startTime);
          const endTime = startTime?.plus({ minutes: Number(values.duration) });
          baseRequest["startTime"] = startTime;
          baseRequest["endTime"] = endTime;
        }
        try {
          await booking.updateWaitingListItem({
            ...baseRequest,
            id: item.id
          });
          booking.notification.success("waiting list item is updated");
        } catch (error) {
          booking.notification.error(error.message);
        }
      } else {
        await booking.addWaitingListItem(baseRequest);
      }
    };

    const onSplitButtonsSubmit = async (
      form: FormApi<WaitingListItemFormValues>
    ) => {
      const values = form.getState().values;
      const findAttendee = Array.from<WaitingListItemModel>(
        booking.waitingListsMap.values()
      ).filter(entries =>
        entries.attendees.find(x => x.attendeeId === values.patientId!)
      );

      const showPrompt =
        findAttendee.filter(
          x =>
            DateTime.fromISO(x.until).toISODate() >= DateTime.now().toISODate()
        ).length > 0 && !initialValues.current.id;

      if (showPrompt) {
        const patientName = practice.contactsMap.get(values.patientId!)?.name;
        const isConfirmed = await confirm({
          confirmButtonProps: {
            text: "Yes"
          },
          cancelButtonProps: {
            text: "No"
          },
          dialogContentProps: {
            title: "Already on waiting list",
            subText: `${patientName} is already on the waiting list. Are you sure you want to add them again?`
          }
        });
        if (isConfirmed) {
          form.submit();
        }
      } else {
        form.submit();
      }
    };

    return (
      <DataFetcher
        fetch={({ booking }) => booking.loadAppointmentTypes()}
        noExceptionsHandlers
      >
        {(_data, loading, error) => (
          <SubmissionFormDialog
            dialogName="Waiting list item dialog"
            loadingData={loading}
            dataLoadingError={error?.message}
            validate={validator.validate}
            onSubmit={onSubmit}
            onSubmitSucceeded={handleDismiss}
            initialValues={initialValues.current}
            buttonsProps={{
              onSubmit: onSplitButtonsSubmit,
              cancelButtonProps: {
                id: "cancel-waiting-list"
              },
              submitButtonProps: {
                id: "submit-waiting-list"
              },
              hideButtonsSeparator: true
            }}
            dialogProps={{
              onDismiss: handleDismiss,
              minWidth: 600,
              dialogContentProps: { title }
            }}
          >
            {() => <WaitingListItemFormFields />}
          </SubmissionFormDialog>
        )}
      </DataFetcher>
    );
  });
