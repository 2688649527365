import { observer } from "mobx-react-lite";

import { CenteredLargeSpinner, Stack } from "@bps/fluent-ui";
import { BhbProvidersFormInner } from "@modules/settings/screens/bhb/online-settings/bhb-configuration-card/providers-form/BhbProvidersForm.tsx";
import { BhbProvider } from "@stores/bhb/models/BhbProvider.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { UserOnlineBookingProfileFormHelper } from "./UserOnlineBookingProfileFormHelper.ts";
import { UserOnlineBookingProfileSettingsWarning } from "./UserOnlineBookingProfileSettingsWarning.tsx";

interface UserOnlineBookingProfileSettingsProps {
  bhbProviders: BhbProvider[];
  user: User;
}

export const UserOnlineBookingProfileSettings = observer(
  ({ bhbProviders, user }: UserOnlineBookingProfileSettingsProps) => {
    const { bhb, booking, userExperience } = useStores();

    const fetch = async () => {
      const [apptTypesForLocation, userSetting, userAvailability] =
        await Promise.all([
          bhb.getAppointmentTypesForLocation(),
          userExperience.getUserSetting(user.id),
          booking.getUserAvailability(user.id)
        ]);

      const apptTypes = apptTypesForLocation
        ? UserOnlineBookingProfileFormHelper.reduceToOnlineApptTypes(
            apptTypesForLocation
          )
        : [];

      return {
        apptTypes,
        userSetting,
        // Bhb providers aren't deleted when the corresponding working hours are deleted so we have to hide them
        bhbProvidersWithWorkingHours: bhbProviders.filter(bhbProvider =>
          userAvailability.schedules.some(
            schedule => schedule.orgUnitId === bhbProvider.locationId
          )
        )
      };
    };

    return (
      <Stack styles={{ root: { margin: "16px 64px" } }}>
        <DataFetcher fetch={fetch} fallback={<CenteredLargeSpinner />}>
          {({ apptTypes, userSetting, bhbProvidersWithWorkingHours }) => (
            <Stack tokens={{ childrenGap: 16 }}>
              <UserOnlineBookingProfileSettingsWarning user={user} />
              <BhbProvidersFormInner
                providers={bhbProvidersWithWorkingHours}
                apptTypes={apptTypes}
                userSettings={[userSetting]}
                users={[user]}
                singleProvider
              />
            </Stack>
          )}
        </DataFetcher>
      </Stack>
    );
  }
);
