import {
  ActionButton,
  MessageBar,
  MessageBarType,
  Stack,
  Text
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import {
  BloodPressureDataItemDto,
  SideOfBody
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DeleteButton } from "@ui-components/DeleteButton.tsx";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";

import { Positions } from "./GeneralExamination.types.ts";
import { GeneralHighLowSevereWarningMessageBar } from "./GeneralHighLowSevereWarningMessageBar.tsx";

interface BloodPressuresListProps {
  wrapValues?: boolean;
}

const BloodPressuresListBase: React.FunctionComponent<
  BloodPressuresListProps
> = ({ wrapValues }) => {
  const { clinical } = useStores();
  const { fields: bloodPressures } =
    useFieldArray<BloodPressureDataItemDto>("bloodPressures");

  const getSideOfBodyOptions = () => {
    return clinical.ref.sidesOfBody.keyTextValues.filter(
      x => x.key !== SideOfBody.Both && x.key !== SideOfBody.Neither
    );
  };

  const addNewBloodPressure = () => {
    bloodPressures.push({
      systolic: undefined,
      diastolic: undefined,
      arm: undefined,
      bPPosition: undefined,
      timeStamp: DateTime.now().toISO()
    });
  };

  const removeBPRow = (index: number) => {
    bloodPressures.remove(index);
  };

  const renderSystolicPositionWarning = (
    currentBP: BloodPressureDataItemDto
  ) => {
    const { systolic, bPPosition, arm } = currentBP;
    if (bPPosition === Positions.Standing && systolic) {
      const comparableBP = bloodPressures.value.filter(
        x =>
          (x.bPPosition === Positions.Lying ||
            x.bPPosition === Positions.Sitting) &&
          x.arm === arm
      );

      if (
        comparableBP.some(x =>
          x.systolic ? systolic - x.systolic <= -20 : false
        )
      ) {
        return (
          <MessageBar messageBarType={MessageBarType.severeWarning}>
            Postural drop in systolic BP
          </MessageBar>
        );
      }
      return null;
    }
    return null;
  };

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <Text variant="large">Blood pressure</Text>
      {bloodPressures.map((name, index) => {
        const currentBP = bloodPressures.value[index];
        const systolic = currentBP?.systolic;

        return (
          <Stack key={name} tokens={{ childrenGap: 8 }}>
            <Stack horizontal tokens={{ childrenGap: 8 }}>
              <Fieldset frame>
                <Stack horizontal={!wrapValues} tokens={{ childrenGap: 8 }}>
                  <Stack horizontal tokens={{ childrenGap: 8 }}>
                    <FieldSpy
                      name={`${name}.diastolic`}
                      subscription={{ value: true }}
                    >
                      {(_, value) => {
                        return (
                          <SpinNumberInputField
                            required={!!value}
                            label="Systolic"
                            name={`${name}.systolic`}
                            min={1}
                            max={999}
                            maxDigitsNumber={3}
                            styles={{ root: { width: 100 } }}
                            validateOnInitialize
                          />
                        );
                      }}
                    </FieldSpy>

                    <FieldSpy
                      name={`${name}.systolic`}
                      subscription={{ value: true }}
                    >
                      {(_, value) => {
                        return (
                          <SpinNumberInputField
                            required={!!value}
                            label="Diastolic"
                            name={`${name}.diastolic`}
                            min={1}
                            max={999}
                            maxDigitsNumber={3}
                            styles={{ root: { width: 100 } }}
                            validateOnInitialize
                          />
                        );
                      }}
                    </FieldSpy>
                  </Stack>
                  <Stack horizontal tokens={{ childrenGap: 8 }}>
                    <ButtonsGroupSingleChoiceField
                      label="Arm"
                      options={getSideOfBodyOptions()}
                      name={`${name}.arm`}
                    />
                    <ButtonsGroupSingleChoiceField
                      label="Position"
                      options={clinical.ref.bpPositions.keyTextValues}
                      name={`${name}.bPPosition`}
                    />
                  </Stack>
                </Stack>

                <Stack tokens={{ childrenGap: 8 }}>
                  {systolic && (
                    <GeneralHighLowSevereWarningMessageBar
                      min={100}
                      max={140}
                      minMessage="Low systolic BP"
                      maxMessage="High systolic BP"
                      value={systolic}
                    />
                  )}
                  {renderSystolicPositionWarning(currentBP)}
                </Stack>
              </Fieldset>

              <DeleteButton
                onClick={() => removeBPRow(index)}
                disabled={bloodPressures.length === 1}
              />
            </Stack>
          </Stack>
        );
      })}
      <ActionButton
        iconProps={{ iconName: "Add" }}
        title="Add more"
        onClick={addNewBloodPressure}
        styles={{ root: { alignSelf: "start" } }}
      >
        Add blood pressure
      </ActionButton>
    </Stack>
  );
};
export const BloodPressuresList = withFetch(
  x => [x.clinical.ref.sidesOfBody.load(), x.clinical.ref.bpPositions.load()],
  BloodPressuresListBase
);
