import { BpTableRow, ReportDefinition } from "bps-powerbi-helper";

import { DateTime } from "@bps/utils";

export const getFieldValueFunction: (
  fieldName: string
) => (report: ReportDefinition, row: BpTableRow) => any = fieldName => {
  return (definition, row) => {
    const fieldIndex = definition.availableViewColumns.findIndex(
      x => x.name === fieldName
    );
    if (fieldIndex > -1) {
      const result = row && row.data[fieldIndex];

      return result;
    }
    return "";
  };
};

export const getDateFieldValueFunction = (field: string) => {
  return (report: ReportDefinition, row: BpTableRow) => {
    const maybeDate = getFieldValueFunction(field)(report, row);
    if (maybeDate && typeof maybeDate.getMonth === "function") {
      return DateTime.jsDateToISODate(maybeDate);
    }
    return maybeDate;
  };
};
export interface CsvColumn {
  field: string | ((definition: ReportDefinition, row: BpTableRow) => string);
  header: string;
}

export interface CsvDefinition {
  name: string;
  description: string;
  fileDescription?: string;
  filename: (reportDefinition: ReportDefinition) => string;
  exportHeader?: boolean;
  matchesMetadata: string;
  columns: CsvColumn[];
}
const convertToCsvCompatibleField: (x: string) => string = x => {
  let result = x ?? "";
  let requiresQuotes = false;
  if (typeof result === "string" && result.includes('"')) {
    result = result.replace(/["]+/g, '""');
    requiresQuotes = true;
  }

  if (typeof result === "string" && result.includes(",")) {
    requiresQuotes = true;
  }
  return requiresQuotes ? `"${result}"` : result;
};

export const getCsvHeader: (
  csvDefinition: CsvDefinition
) => string = csvDefinition => {
  const result = csvDefinition.columns
    .map(x => convertToCsvCompatibleField(x.header))
    .join(",");
  return `${result}\n`;
};
export const getCsvString: (
  csvDefinition: CsvDefinition,
  reportDefinition: ReportDefinition
) => string = (csvDefinition, reportDefinition) => {
  const getCsvRow: (row: BpTableRow) => string = row => {
    return csvDefinition.columns
      .map(col => {
        const val =
          typeof col.field === "function"
            ? col.field(reportDefinition, row)
            : col.field;
        return convertToCsvCompatibleField(val);
      })
      .join(",");
  };

  const header = csvDefinition.exportHeader ? getCsvHeader(csvDefinition) : "";
  const data = reportDefinition.rows.map(row => getCsvRow(row)).join("\n");
  return `${header}${data}`;
};

export const downloadCsv: (
  csvDefinition: CsvDefinition,
  reportDefinition: ReportDefinition
) => void = async (csvDefinition, reportDefinition) => {
  const buf = getCsvString(csvDefinition, reportDefinition);
  const fs = await import("file-saver");
  return fs.saveAs(
    new Blob([buf]),
    `${csvDefinition.filename(reportDefinition)}`
  );
};
