import { observer } from "mobx-react-lite";
import React, { useContext, useRef } from "react";
import { Field, useForm } from "react-final-form";

import {
  confirm,
  DefaultButton,
  Dialog,
  Heading,
  PrimaryButton,
  Stack
} from "@bps/fluent-ui";
import {
  AttendeeTypeEnum,
  CalendarEventAttendeeDto
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { appointmentFormNameOf } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/AppointmentForm.types.ts";
import { AppointmentFormContext } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/AppointmentFormContext.ts";
import { AppointmentFormValues } from "@shared-types/booking/appointment-form-values.types.ts";
import { WaitingListItemModel } from "@stores/booking/models/WaitingListModel.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";
import { Selection } from "@ui-components/ShimmeredDetailsList/Selection.ts";

import { AppointmentInfoCard } from "../../../booking-calendar/components/appointment-dialog/components/AppointmentInfoCard.tsx";
import {
  useWaitingListScreenContext,
  WaitingListScreenContext
} from "../../context/WaitingListScreenContext.tsx";
import { WaitingListScreenHelper } from "../../context/WaitingListScreenHelper.ts";
import { WaitingListDetailFilter } from "../WaitingListDetailFilter.tsx";
import { WaitingListDetailsTableWithContext } from "./WaitingListDetailsTableWithContext.tsx";

export const WaitingListDetailsDialogComponent: React.FC = observer(() => {
  const { setShowWaitingListDialog } = useContext(AppointmentFormContext);
  const { booking, notification } = useStores();
  const form = useForm<AppointmentFormValues>();
  const { waitingListResults, items } = useWaitingListScreenContext();

  const {
    providerId,
    startDate,
    startTime,
    duration,
    waitingListIds,
    groupAttendees
  } = form.getState().values;

  const { isGroupAppointment } = useContext(AppointmentFormContext);
  const { fields } = useFieldArray<CalendarEventAttendeeDto>(
    appointmentFormNameOf("groupAttendees"),
    {
      subscription: { value: true }
    }
  );

  const handleDismiss = () => setShowWaitingListDialog(false);

  const isEmptyList = waitingListResults.value?.length === 0;

  const selection = useRef(
    new Selection({
      onSelectionChanged: () => {
        const count = selection.current.getSelection().length;
        form.change(appointmentFormNameOf("selectedCount"), count);
      }
    })
  );

  const confirmWLItem = () => {
    const index = selection.current.getSelectedIndices();
    const itemKey = index[0];

    if (items && typeof index[0] !== "undefined") {
      const wlItem = items[itemKey];

      const patient = wlItem.contact?.model;
      if (patient) {
        form.batch(() => {
          form.change(appointmentFormNameOf("waitingFieldToggle"), false);

          if (isGroupAppointment) {
            if (!groupAttendees?.find(a => a.attendeeId === patient.id)) {
              fields.push({
                attendeeId: patient.id,
                type: AttendeeTypeEnum.contact
              });
              form.mutators.push("groupAttendeeIds", patient.id);
            }

            if (!waitingListIds?.includes(wlItem.id)) {
              form.change(appointmentFormNameOf("waitingListIds"), [
                ...(waitingListIds ?? []),
                wlItem.id
              ]);
            }
          } else {
            form.change(appointmentFormNameOf("patientId"), patient.id);
            form.change(appointmentFormNameOf("waitingListIds"), [wlItem.id]);
            form.change(
              appointmentFormNameOf("appointmentTypeId"),
              wlItem.appointmentTypeId
            ); // This will also cause the duration to change - see handleAppointmentTypeChange
          }
        });
      }

      handleDismiss();
    }
  };

  const deleteWLItem = async () => {
    const selectedIndex = selection.current.getSelectedIndices();
    const itemKey = selectedIndex[0];
    const items = selection.current.getItems();
    const wlItem = items[itemKey] as WaitingListItemModel;
    const patientFullName = wlItem?.contact?.model?.fullName;

    if (
      await confirm({
        dialogContentProps: {
          title: "Remove from waiting list",
          subText: `Are you sure you want to remove ${patientFullName} from the waiting list?`
        },
        confirmButtonProps: {
          text: "Confirm"
        }
      })
    ) {
      try {
        if (itemKey >= 0 && selection.current.isIndexSelected(itemKey)) {
          if (wlItem?.id) {
            await booking.deleteWaitingListRecord(wlItem.id);
          }
        }
      } catch (error) {
        notification.error(error.message);
      }
    }
  };

  return (
    <Stack>
      <Dialog
        styles={{ root: { overflowY: "none" } }}
        hidden={false}
        onDismiss={handleDismiss}
        dialogContentProps={{
          title: (
            <Heading variant="modal-heading">
              Add patient from waiting list
            </Heading>
          ),
          showCloseButton: true,
          styles: {
            content: {
              display: "flex",
              flexDirection: "column"
            }
          }
        }}
        minWidth={950}
      >
        <AppointmentInfoCard
          providerId={providerId}
          startDate={startDate}
          startTime={startTime}
          duration={duration}
        />

        <WaitingListDetailFilter>
          {() => <WaitingListDetailsTableWithContext selection={selection} />}
        </WaitingListDetailFilter>

        <Stack
          horizontal
          horizontalAlign="end"
          styles={{ root: { paddingTop: 16 } }}
          tokens={{ childrenGap: 8 }}
        >
          <Field
            name={appointmentFormNameOf("selectedCount")}
            subscription={{ value: true }}
          >
            {selectedCount => (
              <PrimaryButton
                id="addWlItemToAppt"
                onClick={confirmWLItem}
                text="Save"
                disabled={isEmptyList || selectedCount.input.value === 0}
              />
            )}
          </Field>

          <Field
            name={appointmentFormNameOf("selectedCount")}
            subscription={{ value: true }}
          >
            {selectedCount => (
              <DefaultButton
                id="deleteWLItem"
                onClick={deleteWLItem}
                text="Remove from list"
                disabled={isEmptyList || selectedCount.input.value === 0}
              />
            )}
          </Field>

          <DefaultButton
            id="cancelAddPtWLDialog"
            onClick={handleDismiss}
            text="Cancel"
          />
        </Stack>
      </Dialog>
    </Stack>
  );
});

export const WaitingListDetailsDialog: React.FunctionComponent = observer(
  () => {
    const { showWaitingListDialog } = useContext(AppointmentFormContext);
    const { booking } = useStores();
    const helper = useRef<WaitingListScreenHelper>(
      new WaitingListScreenHelper(booking)
    );

    if (!showWaitingListDialog) return null;
    return (
      <WaitingListScreenContext.Provider value={helper.current}>
        <WaitingListDetailsDialogComponent />
      </WaitingListScreenContext.Provider>
    );
  }
);
