import { FC } from "react";

import { MessageBar, MessageBarType } from "@bps/fluent-ui";
import { ClaimStatuses } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { ConditionHelper } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/ConditionHelper.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { ErrorBlock } from "@ui-components/ErrorBlock.tsx";

type OverviewCardGetStatusErrorProps = {
  status: string | undefined;
  errorMessage: string | undefined;
  errorDetails: string | undefined;
  conditionHelper: ConditionHelper;
};

export const OverviewCardGetStatusError: FC<
  OverviewCardGetStatusErrorProps
> = ({ conditionHelper, errorDetails, errorMessage, status }) => {
  const { acc } = useStores();

  const messageBarIconStyles = { iconContainer: { alignItems: "center" } };

  if (status === ClaimStatuses.GetStatusError && errorMessage) {
    if (
      errorMessage.includes("duplicate") &&
      errorDetails &&
      conditionHelper.condition &&
      conditionHelper.condition.claim
    ) {
      const claim = conditionHelper.condition.claim;
      if (!claim.dto || !claim.id) {
        return null;
      }

      return (
        <ErrorBlock
          errorText={errorMessage}
          linkText="Update claim & requery ACC"
          onClick={async () => {
            await acc.patchClaim({
              ...claim.dto,
              claimNumber: errorDetails
            });
            await acc.queueClaimStatus(claim.id);
          }}
        />
      );
    } else {
      return (
        <MessageBar
          messageBarIconProps={{ iconName: "Blocked2" }}
          messageBarType={MessageBarType.error}
          styles={messageBarIconStyles}
        >
          {errorMessage}
        </MessageBar>
      );
    }
  }
  return null;
};
