import { observer } from "mobx-react-lite";
import { FunctionComponent, Suspense, useEffect } from "react";

import {
  CenteredLargeSpinner,
  Separator,
  Spinner,
  Stack,
  Tile
} from "@bps/fluent-ui";
import {
  EncounterType,
  PermanentClinicalTab
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { PatientNoticesContextProvider } from "@modules/practice/screens/shared-components/patient-notices/PatientNoticesContext.tsx";
import { PatientNoticesModel } from "@modules/practice/screens/shared-components/patient-notices/PatientNoticesModel.ts";
import { DischargeStatus } from "@shared-types/clinical/discharge-status.enum.ts";
import { TreeViewSize } from "@shared-types/clinical/tree-view.enum.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { UserSetting } from "@stores/user-experience/models/UserSetting.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { When } from "@ui-components/withPerm.tsx";

import { useNewClinicalTheme } from "../../../hooks/useNewClinicalTheme.tsx";
import {
  MEDIUM_TREE_VIEW_WIDTH,
  SMALL_TREE_VIEW_WIDTH
} from "../types/tree-widths.constants.ts";
import { EncounterDeletedElsewhereDialog } from "./clinical-form/EncounterDeletedElsewhereDialog.tsx";
import { EncounterDiscardDialog } from "./clinical-form/EncounterDiscardDialog.tsx";
import { EncounterExistsPrompt } from "./clinical-form/EncounterExistsPrompt.tsx";
import { EncounterFinalisedElsewhereDialog } from "./clinical-form/EncounterFinalisedElsewhereDialog.tsx";
import { ClinicalRecordNotifications } from "./ClinicalRecordNotifications.tsx";
import { ConditionDischargeWarning } from "./ConditionDischargeWarning.tsx";
import { EncounterDetails } from "./EncounterDetails.tsx";
import { EncounterDock } from "./EncounterDock.tsx";
import { EncounterTimer } from "./EncounterTimer.tsx";
import { PatientSummaryContextProvider } from "./patient-summary/helpers/PatientSummaryContext.tsx";
import { HorizontalPersonaDetails } from "./patient-summary/HorizontalPersonaDetails.tsx";
import { PatientActionsSidePanel } from "./PatientActionsSidePanel.tsx";
import { PatientDemographicsHeader } from "./PatientDemographicsHeader.tsx";
import { PatientRecordContent } from "./PatientRecordContent.tsx";
import { ClinicalSidePanel, EncounterForm } from "./PatientRecordLazy.tsx";
import { QuickAccess } from "./quickAccess/QuickAccess.tsx";

const MAIN_WIDTH = "calc(100% - 466px)";
const LARGE_WIDTH = "calc(50% - 8px)";
const MAX_WIDTH = "calc(100% - 48px)";

const getLeftPanelWidth = (sidePanelSize: TreeViewSize | undefined) => {
  if (sidePanelSize === TreeViewSize.Expanded) return LARGE_WIDTH;
  if (sidePanelSize === TreeViewSize.Default) return MAIN_WIDTH;
  return MAX_WIDTH;
};

const getRightPanelWidth = (sidePanelSize: TreeViewSize | undefined) => {
  if (sidePanelSize === TreeViewSize.Expanded) return LARGE_WIDTH;
  if (sidePanelSize === TreeViewSize.Default) return MEDIUM_TREE_VIEW_WIDTH;
  return SMALL_TREE_VIEW_WIDTH;
};

export const PatientRecordDetails: FunctionComponent = observer(() => {
  const { clinical, core, practice } = useStores();

  const { tabs } = clinical.ui;
  const theme = useNewClinicalTheme();

  const { isViewOnly, isViewOnlyOrDischarged, clinicalRecord, patientId } =
    usePatientRecordScreenContext();

  useEffect(() => {
    // Clear patientClinicalTasksPromise to set again
    // when switch btw clinical record tabs.
    return () => {
      clinicalRecord?.patientClinicalTasksPromise.clear();
    };
  }, [clinicalRecord?.patientClinicalTasksPromise]);

  if (!clinicalRecord) return null;

  const isRecordUpdate =
    clinicalRecord?.openEncounter?.type === EncounterType.RecordUpdate;

  const useNewClinicalLayout = core.hasPermissions(
    Permission.UIRedesignAllowed
  );

  let content: JSX.Element | null = null;

  const dischargeItems = clinicalRecord.clinicalData?.discharge?.dataItems;

  const hasDischarges = dischargeItems?.some(
    x => x.dischargeStatus === DischargeStatus.Completed
  );

  //check the incomplete discharge for the current logged in business role
  const businessRole = clinicalRecord?.openEncounter?.businessRole;

  const { getLatestActiveDischargeClinicalDataItem } =
    usePatientRecordScreenContext();

  const latestDischargeItem = getLatestActiveDischargeClinicalDataItem(
    businessRole,
    dischargeItems
  );

  const doesNotHaveExistingDischarge =
    latestDischargeItem &&
    latestDischargeItem.dischargeStatus !== DischargeStatus.Completed;

  if (
    tabs.currentPatientRecordTab &&
    tabs.currentPatientRecordTab.activeTabType !==
      PermanentClinicalTab.TodaysNotes
  ) {
    content = <PatientRecordContent clinicalRecord={clinicalRecord} />;
  } else if (
    isViewOnlyOrDischarged ||
    (!isViewOnly &&
      !isRecordUpdate &&
      doesNotHaveExistingDischarge &&
      !latestDischargeItem.id &&
      dischargeItems)
  ) {
    const encounter = clinicalRecord?.openEncounter;

    if (encounter) {
      content = <EncounterDetails clinicalRecord={clinicalRecord} />;
    }
  } else {
    content = (
      <Suspense fallback={<CenteredLargeSpinner />}>
        <EncounterForm />
      </Suspense>
    );
  }

  const sidePanelSize =
    clinical.ui.tabs.currentPatientRecordTab?.state.sidePanelSize;

  const notesArea = () => {
    return (
      <Stack
        styles={{
          root: {
            overflowY: "hidden",
            height: "100%"
          }
        }}
        grow
      >
        <Tile
          styles={{
            root: { flexGrow: 1, overflowY: "hidden", padding: 16 },
            content: {
              padding: 0,
              flexGrow: 1,
              display: "flex",
              flexDirection: "column"
            }
          }}
        >
          {content}
        </Tile>

        {!isViewOnly && !isRecordUpdate && hasDischarges && (
          <ConditionDischargeWarning clinicalRecord={clinicalRecord} />
        )}
      </Stack>
    );
  };

  return (
    <PatientNoticesContextProvider
      value={
        new PatientNoticesModel(practice, {
          patientId,
          withSystemNotices: false
        })
      }
    >
      <Stack verticalFill tokens={{ childrenGap: theme.spacing.s1 }}>
        <ClinicalRecordNotifications />

        <Stack
          grow
          horizontal
          tokens={{ childrenGap: theme.spacing.s1 }}
          styles={{ root: { width: "100%", height: "90%" } }}
        >
          <Stack.Item
            styles={{
              root: {
                minWidth: getLeftPanelWidth(sidePanelSize)
              }
            }}
            grow
          >
            <Stack
              tokens={{ childrenGap: theme.spacing.s1 }}
              styles={{ root: { height: "100%" } }}
              horizontal
            >
              {!useNewClinicalLayout && (
                <Stack>
                  <When permission={Permission.QuickAccessAllowed}>
                    {!isViewOnly && (
                      <Stack>
                        <QuickAccess />
                      </Stack>
                    )}
                  </When>

                  <PatientDemographicsHeader
                    topPanel={() => (
                      <When permission={Permission.EncounterTimerAllowed}>
                        {!isViewOnly && (
                          <>
                            <EncounterTimer />
                            <Separator styles={{ root: { padding: 0 } }} />
                          </>
                        )}
                      </When>
                    )}
                  />
                </Stack>
              )}

              {useNewClinicalLayout && (
                <>
                  <Stack>
                    <When permission={Permission.QuickAccessAllowed}>
                      {!isViewOnly && (
                        <Stack>
                          <QuickAccess />
                        </Stack>
                      )}
                    </When>
                    <PatientSummaryContextProvider>
                      <PatientActionsSidePanel />
                    </PatientSummaryContextProvider>
                  </Stack>
                  <Stack
                    tokens={{ childrenGap: theme.spacing.s1 }}
                    styles={{ root: { maxHeight: "100%", minHeight: "100%" } }}
                    grow
                  >
                    <Stack>
                      <Tile
                        styles={{
                          root: {
                            minHeight: "64px"
                          }
                        }}
                      >
                        <HorizontalPersonaDetails />
                      </Tile>
                    </Stack>

                    <Stack
                      styles={{
                        root: {
                          height: "100%",
                          overflowY: "auto"
                        }
                      }}
                      horizontal
                      tokens={{ childrenGap: theme.spacing.s1 }}
                    >
                      {notesArea()}
                      <Stack.Item
                        styles={{
                          root: {
                            width: getRightPanelWidth(sidePanelSize),
                            minWidth: getRightPanelWidth(sidePanelSize)
                          }
                        }}
                      >
                        <Tile
                          styles={{
                            root: {
                              padding: 0,
                              height: "100%"
                            },
                            content: {
                              flexGrow: 1,
                              flexShrink: 0,
                              display: "flex",
                              flexDirection: "column",
                              overflowY: "hidden",
                              overflowX: "auto"
                            }
                          }}
                        >
                          <DataFetcher<UserSetting | undefined>
                            key={core.userId}
                            fetch={({ userExperience }) =>
                              userExperience.getCurrentUserSetting()
                            }
                            fallback={<Spinner />}
                          >
                            {() => {
                              return (
                                <Suspense fallback={<CenteredLargeSpinner />}>
                                  <ClinicalSidePanel
                                    clinicalRecord={clinicalRecord}
                                  />
                                </Suspense>
                              );
                            }}
                          </DataFetcher>
                        </Tile>
                      </Stack.Item>
                    </Stack>
                    <EncounterDock />
                  </Stack>
                </>
              )}
              {!useNewClinicalLayout && <>{notesArea()}</>}
              <EncounterExistsPrompt />
            </Stack>
          </Stack.Item>

          {!useNewClinicalLayout && (
            <Stack.Item
              styles={{
                root: {
                  height: "100%",
                  width: getRightPanelWidth(sidePanelSize),
                  minWidth: getRightPanelWidth(sidePanelSize)
                }
              }}
            >
              <Tile
                styles={{
                  root: {
                    padding: 0,
                    height: "100%"
                  },
                  content: {
                    flexGrow: 1,
                    flexShrink: 0,
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "hidden",
                    overflowX: "auto"
                  }
                }}
              >
                <DataFetcher<UserSetting | undefined>
                  key={core.userId}
                  fetch={({ userExperience }) =>
                    userExperience.getCurrentUserSetting()
                  }
                  fallback={<Spinner />}
                >
                  {() => {
                    return (
                      <Suspense fallback={<CenteredLargeSpinner />}>
                        <ClinicalSidePanel clinicalRecord={clinicalRecord} />
                      </Suspense>
                    );
                  }}
                </DataFetcher>
              </Tile>
            </Stack.Item>
          )}
        </Stack>
        {!useNewClinicalLayout && (
          <Stack
            horizontal
            tokens={{ childrenGap: 16 }}
            styles={{ root: { width: "100%", display: "flex" } }}
          >
            <EncounterDock />
          </Stack>
        )}

        <EncounterDeletedElsewhereDialog />
        <EncounterDiscardDialog />
        <EncounterFinalisedElsewhereDialog />
      </Stack>
    </PatientNoticesContextProvider>
  );
});
