import { useContext } from "react";
import { FileRejection } from "react-dropzone";

import {
  AnonymousCredential,
  BlockBlobClient,
  newPipeline
} from "@azure/storage-blob";
import { newGuid } from "@bps/utils";
import { CreateInboxDocumentDto } from "@libs/gateways/inbox/InboxGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DropzoneSection } from "@ui-components/drop-zone-section/DropzoneSection.tsx";

import { InboxScreenContext } from "../context/InboxScreenContext.ts";

interface DropzoneSectionAzureBlobBaseProps {
  isButton?: boolean;
  onDropRejected: (errors: FileRejection[]) => void;
  onDropStarted?: () => void;
  onDropFinished?: () => void;
  hasError?: boolean;
  fileTooLargeText?: string;
}

export const DropzoneSectionAzureBlob: React.FC<
  DropzoneSectionAzureBlobBaseProps
> = ({
  isButton,
  onDropRejected,
  onDropStarted,
  onDropFinished,
  hasError,
  fileTooLargeText
}) => {
  const { notification, inbox } = useStores();

  const { uploadFileError } = useContext(InboxScreenContext);

  const getNewBlobUri = (
    inboxDocumentId: string,
    uri: string,
    token: string
  ): string => {
    return `${uri}/${inboxDocumentId}${token}`;
  };

  const handleDropAccepted = async (files: File[]) => {
    onDropStarted && onDropStarted();

    try {
      const stagingConfig = await inbox.getSaSUri();

      const uploadedDocuments = await Promise.all(
        files.map(async (file, index) => {
          const fileNameSplit = files[index].name.split(".");
          let filenameExtension = "";
          let filename = "";
          if (fileNameSplit.length > 1) {
            filenameExtension = fileNameSplit.pop() ?? "";
            filename = fileNameSplit.join(".");
          } else {
            filename = files[index].name;
          }

          const createInboxDocument: CreateInboxDocumentDto = {
            id: newGuid(),
            name: filename,
            extension: filenameExtension
          };

          const aborter = new AbortController();
          const metadata = {
            docname: createInboxDocument.name,
            extension: createInboxDocument.extension,
            filestagingid: stagingConfig.fileStagingId,
            inboxdocumentid: createInboxDocument.id
          };

          const blockBlobClient = new BlockBlobClient(
            getNewBlobUri(
              createInboxDocument.id,
              stagingConfig.uri,
              stagingConfig.token
            ),
            newPipeline(new AnonymousCredential())
          );

          await blockBlobClient.uploadBrowserData(file, {
            abortSignal: aborter.signal,
            blobHTTPHeaders: {
              blobContentType: file.type
            },
            metadata
          });
          return createInboxDocument;
        })
      );

      await inbox.upload({
        fileStagingId: stagingConfig.fileStagingId,
        inboxDocuments: uploadedDocuments
      });
    } catch (error) {
      notification.error("An error occurred while uploading files.");
    } finally {
      onDropFinished && onDropFinished();
    }
  };

  return (
    <DropzoneSection
      styles={{
        root: {
          height: "fit-content",
          justifyContent: "center",
          display: "flex"
        },
        dropzone: {
          height: "100%",
          width: "50%"
        }
      }}
      showSpinner={true}
      isMultiple
      hasError={hasError}
      accept={{
        "application/pdf": [".pdf"],
        "application/msword": [".doc"],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          [".docx"],
        "application/rtf": [".rtf"],
        "application/vnd.oasis.opendocument.text": [".odt"],
        "text/plain": [".txt", ".hl7", ".ref", ".oru", ".rsd"],
        "image/jpeg": [],
        "image/png": []
      }}
      customFileRejections={uploadFileError}
      fileTooLargeText={fileTooLargeText}
      incorrectFileTypeText="is not an acceptable file type"
      isButton={isButton}
      onDropAccepted={handleDropAccepted}
      onDropRejected={onDropRejected}
      maxSize={7000000}
    />
  );
};
