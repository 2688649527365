import { observer } from "mobx-react-lite";
import { FC, useContext, useEffect } from "react";

import {
  GroupedList,
  IGroup,
  mergeStyleSets,
  Spinner,
  Stack,
  Tile,
  useTheme
} from "@bps/fluent-ui";
import { CorrespondenceType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { InboxDocument } from "@stores/inbox/models/InboxDocument.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { InboxScreenContext } from "../context/InboxScreenContext.ts";
import { InboxScreenLabels } from "../inbox/Inbox.types.ts";
import {
  getInboxScreenStylesSet,
  sidePanelWidth
} from "../inbox/InboxScreen.styles.ts";
import { HL7HeaderDataPreview } from "../shared-components/HL7HeaderDataPreview.tsx";
import { UploadDocForm } from "../shared-components/upload-doc-form/UploadDocForm.tsx";
import { UploadDocFormLabels } from "../shared-components/upload-doc-form/UploadDocForm.types.ts";
import { IncomingFilter } from "./components/IncomingFilter.tsx";
import { IncomingList } from "./components/IncomingList.tsx";

export const IncomingScreen: FC = observer(() => {
  const { notification } = useStores();

  const {
    onRemovingInboxDocFromList,
    selectedInboxDocKey,
    setSelectedInboxDocKey,
    setSelectedInboxDocument,
    clearOnLeave,
    selectedInboxDocument
  } = useContext(InboxScreenContext);

  const theme = useTheme();
  const { groupedListHeaderStyles } = getInboxScreenStylesSet(theme);

  const handleOnClose = () => {
    setSelectedInboxDocKey();
    setSelectedInboxDocument();
  };

  useEffect(() => {
    return () => {
      clearOnLeave();
    };
  }, [clearOnLeave]);

  const isDismissOnly =
    selectedInboxDocument?.correspondenceType ===
    CorrespondenceType.FormResponse;

  const handleOnListRemove = (id: string) => {
    if (!isDismissOnly) {
      notification.success(UploadDocFormLabels.DeleteFileFeedback);
    }
    onRemovingInboxDocFromList(id);
    handleOnClose();
  };

  const groups = (): IGroup[] => {
    if (
      selectedInboxDocument?.correspondenceType ===
      CorrespondenceType.FormResponse
    ) {
      return [
        {
          key: "formResponse",
          name: "Form response",
          startIndex: 0,
          count: 1,
          isCollapsed: false
        }
      ];
    }

    if (
      selectedInboxDocument?.headerDocumentDetailId &&
      selectedInboxDocument?.headerEntityId
    ) {
      return [
        {
          key: "messageHeader",
          name: "Message header",
          startIndex: 0,
          count: 1,
          isCollapsed: false
        },
        {
          key: "details",
          name: "Report details",
          startIndex: 1,
          count: 1,
          isCollapsed: false
        }
      ];
    }

    return [
      {
        key: "details",
        name: "Report details",
        startIndex: 0,
        count: 1,
        isCollapsed: false
      }
    ];
  };

  const getItems = (inboxDocument: InboxDocument) => {
    const items = [
      <UploadDocForm
        key="upload-doc-form"
        document={inboxDocument}
        isDisabled={false}
        onRemove={handleOnListRemove}
        onClose={handleOnClose}
        isDismissOnly={isDismissOnly}
      />
    ];
    if (
      inboxDocument?.headerDocumentDetailId &&
      inboxDocument?.headerEntityId
    ) {
      items.unshift(<HL7HeaderDataPreview key="messageHeader" />);
    }
    return items;
  };
  return (
    <Stack
      tokens={{ childrenGap: 24 }}
      styles={{
        root: { height: "100%" }
      }}
    >
      <Stack
        styles={{ root: { height: "100%" } }}
        horizontal
        tokens={{ childrenGap: 9 }}
      >
        <Tile
          styles={{
            root: {
              flexGrow: 1
            }
          }}
        >
          <Stack styles={{ root: { height: "100%", overflowX: "auto" } }}>
            <IncomingFilter>{() => <IncomingList />}</IncomingFilter>
          </Stack>
        </Tile>
        <Tile
          styles={mergeStyleSets(sidePanelWidth, {
            root: {
              padding: "16px 24px"
            }
          })}
        >
          {selectedInboxDocKey ? (
            <DataFetcher<InboxDocument | undefined>
              key={selectedInboxDocKey.inboxDocumentId}
              fetch={(x: IRootStore) =>
                x.inbox.getInboxDocument({
                  id: selectedInboxDocKey.inboxDocumentId,
                  documentDetailId: selectedInboxDocKey.documentDetailId
                })
              }
              fallback={
                <Stack grow verticalAlign="center" horizontalAlign="center">
                  <Spinner />
                </Stack>
              }
              children={inboxDocument =>
                inboxDocument && (
                  <GroupedList
                    groups={groups()}
                    items={getItems(inboxDocument)}
                    styles={{
                      root: {
                        selectors: groupedListHeaderStyles
                      }
                    }}
                    groupProps={{
                      onRenderHeader: (props, defaultRender) => {
                        if (!props || !defaultRender) {
                          return null;
                        }
                        return defaultRender!({
                          ...props,
                          styles: { headerCount: { display: "none" } },
                          onGroupHeaderClick: () =>
                            props.onToggleCollapse!(props.group!)
                        });
                      }
                    }}
                    onRenderCell={(depth, item: React.ReactNode) => item}
                  />
                )
              }
            />
          ) : (
            <Stack
              horizontalAlign="center"
              verticalAlign="center"
              styles={(_props, { palette }) => ({
                root: {
                  height: "100%",
                  fontStyle: "italic",
                  color: palette.neutralTertiary
                }
              })}
            >
              {InboxScreenLabels.SelectReport}
            </Stack>
          )}
        </Tile>
      </Stack>
    </Stack>
  );
});

// ⚠ It should be exported as default since it is used for React.lazy
// eslint-disable-next-line import/no-default-export
export default IncomingScreen;
