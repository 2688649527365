import { FunctionComponent, useRef } from "react";
import { useForm } from "react-final-form";

import {
  AnimatedList,
  DefaultButton,
  Heading,
  Link,
  mergeStyleSets,
  Stack,
  useAnimationKeys,
  useTheme
} from "@bps/fluent-ui";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { FieldArray } from "@ui-components/form/FieldArray.tsx";
import { StaticPickerField } from "@ui-components/form/StaticPickerField.tsx";
import { useFieldArray } from "@ui-components/form/submission-form/hooks/useFieldArray.ts";

import { ContactPickerFieldWithAdd } from "../../../../shared-components/contact-picker/ContactPickerFieldWithAdd.tsx";
import { getPeopleScreenStylesSet } from "../../../../shared-components/PeopleScreen.styles.ts";
import { Labels } from "../../../../shared-components/types/labels.enums.types.ts";
import { PatientCardIds } from "../../../../shared-components/types/patient-card-ids.enum.ts";
import {
  EmployerFieldValue,
  PatientEditFormValues,
  patientFormNameOf
} from "../PatientEditFormValues.tsx";
import { EditEmployersViewModel } from "./EditEmployersViewModel.ts";
import { EmployerMetadata } from "./EmployerMetadata.tsx";
import { EmployerNoData } from "./EmployerNoData.tsx";
import {
  borderStyle,
  contactPickerWrapperStyle,
  dropdownStyle,
  headerStyles,
  wrapperStyle
} from "./Employers.styles.ts";

interface EditEmployersBaseProps {
  contact?: Contact;
}

const EditEmployersBase: FunctionComponent<EditEmployersBaseProps> = ({
  contact
}) => {
  const { practice } = useStores();
  const employerFields = useFieldArray("employers").fields;
  const { mutators } = useForm<PatientEditFormValues>();
  const model = useRef(new EditEmployersViewModel(practice, mutators));

  const theme = useTheme();
  const { removeLinkStyle } = getPeopleScreenStylesSet(theme);

  const { keys, handleKeyRemove } = useAnimationKeys(
    employerFields.value,
    "animationId"
  );
  return (
    <>
      <Stack horizontal horizontalAlign="space-between" styles={headerStyles}>
        <Heading variant="section-heading-light">{Labels.employers}</Heading>
        <DefaultButton
          iconProps={{ iconName: "Add" }}
          text="Add another"
          onClick={() => model.current.addEmployer()}
        />
      </Stack>
      <Stack tokens={{ childrenGap: 16 }}>
        {contact?.isPatient && (
          <StaticPickerField
            name={patientFormNameOf("occupation")}
            label={Labels.occupation}
            resolveDelay={300}
            fetchDataSource={() => model.current.allOccupations}
            onEmptySearchItems={() => []}
            onFilterItems={filter => model.current.searchOccupation(filter)}
          />
        )}
        {keys.length === 0 && <EmployerNoData model={model.current} />}
        <FieldArray<EmployerFieldValue> name={patientFormNameOf("employers")}>
          {({ fields }) => {
            return (
              <AnimatedList
                animationKeys={keys}
                onAnimationKeyRemoved={animationKey => {
                  const index = fields.value.findIndex(
                    v => v.animationId === animationKey
                  );

                  fields.remove(index);
                }}
              >
                {animationKey => {
                  const { value, index } = model.current.findValue(
                    employerFields.value,
                    animationKey
                  );
                  if (value && index >= 0) {
                    return (
                      <Stack key={animationKey} styles={borderStyle}>
                        <Stack
                          horizontalAlign="space-between"
                          horizontal
                          verticalAlign="start"
                          tokens={{ childrenGap: 8 }}
                        >
                          <ContactPickerFieldWithAdd
                            actionButton={
                              !employerFields.value[index].relatedContactId
                                ? {
                                    linkProps: {
                                      onClick: () =>
                                        handleKeyRemove(animationKey),
                                      children: Labels.remove
                                    }
                                  }
                                : undefined
                            }
                            label="Employer"
                            name={`${patientFormNameOf(
                              "employers"
                            )}[${index}].relatedContactId`}
                            filter={{
                              types: [ContactType.Organisation]
                            }}
                            placeholder="Search"
                            wrapperStyles={contactPickerWrapperStyle}
                            quickAddProps={{
                              addButtonLabel: "New employer",
                              addModalTitle: "New employer",
                              showAddByDropdown: false,
                              typeToAdd: ContactType.Organisation
                            }}
                          />
                          {employerFields.value[index].relatedContactId && (
                            <DropdownField
                              onRenderLabel={(props, defaultRender) => {
                                if (props && defaultRender) {
                                  return (
                                    <Stack
                                      horizontal
                                      horizontalAlign="space-between"
                                    >
                                      {defaultRender({
                                        ...props,
                                        label: "Employment type"
                                      })}
                                      <Link
                                        styles={mergeStyleSets(
                                          removeLinkStyle,
                                          {
                                            root: {
                                              padding: "5px 0"
                                            }
                                          }
                                        )}
                                        onClick={() =>
                                          handleKeyRemove(animationKey)
                                        }
                                      >
                                        {Labels.remove}
                                      </Link>
                                    </Stack>
                                  );
                                } else {
                                  return null;
                                }
                              }}
                              options={model.current.allEmployeeTypes}
                              styles={dropdownStyle}
                              name={`${patientFormNameOf(
                                "employers"
                              )}[${index}].metadata.employmentType`}
                            />
                          )}
                        </Stack>
                        <EmployerMetadata
                          model={model.current}
                          employers={employerFields.value}
                          index={index}
                        />
                      </Stack>
                    );
                  } else {
                    return null;
                  }
                }}
              </AnimatedList>
            );
          }}
        </FieldArray>
      </Stack>
    </>
  );
};

const EditEmployersWithFetch = withFetch(
  x => [x.practice.ref.employeeTypes.load(), x.practice.ref.occupations.load()],
  EditEmployersBase
);

export const EditEmployers: React.FC<EditEmployersBaseProps> = ({
  ...props
}) => (
  <div id={`${PatientCardIds.employers}-edit`} style={wrapperStyle}>
    <EditEmployersWithFetch {...props} />
  </div>
);
