import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { Pivot, PivotItem, Stack } from "@bps/fluent-ui";
import {
  BusinessRoleClasses,
  Permission
} from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { InboxScreenContext } from "../../context/InboxScreenContext.ts";
import { ActivityClinicalButtons } from "../../tasks/components/ActivityClinicalButtons.tsx";
import { TaskClinicalButtons } from "../../tasks/components/TaskClinicalButtons.tsx";
import { TaskInboxButtons } from "../../tasks/components/TaskInboxButtons.tsx";
import { IncomingReportsButtons } from "./IncomingReportsButtons.tsx";
import { RemindersListButtons } from "./RemindersListButtons.tsx";
import { UploadButtons } from "./UploadButtons.tsx";

export enum ToDoScreenTab {
  Inbox = "Inbox",
  FollowUpTasks = "Follow-up tasks",
  Incoming = "Incoming",
  Uploads = "Uploads",
  ClinicalTasks = "Clinical tasks",
  ClinicalReminders = "Clinical reminders",
  FollowUpReminders = "Follow-up reminders"
}

export const ToDoHeader = observer(() => {
  const {
    routing,
    inbox: { ui },
    core: { hasPermissions, hasBusinessRoleClasses }
  } = useStores();

  const { setSelectedClinicalActivities } = useContext(InboxScreenContext);

  const hasTaskFollowupPermission = hasPermissions(Permission.FollowUpTasks);
  let currentTab: ToDoScreenTab;
  let currentExtraButtons: React.ReactNode | undefined;

  if (routes.userInbox.basePath.match(routing.location.pathname)) {
    currentTab = ToDoScreenTab.Inbox;
  } else if (
    hasTaskFollowupPermission &&
    routes.tasks.inbox.match(routing.location.pathname)
  ) {
    currentTab = ToDoScreenTab.FollowUpTasks;
    currentExtraButtons = <TaskInboxButtons />;
  } else if (routes.inbox.incoming.match(routing.location.pathname)) {
    currentTab = ToDoScreenTab.Incoming;
    currentExtraButtons = <IncomingReportsButtons />;
  } else if (routes.inbox.upload.match(routing.location.pathname)) {
    currentTab = ToDoScreenTab.Uploads;
    currentExtraButtons = <UploadButtons />;
  } else if (routes.tasks.clinical.match(routing.location.pathname)) {
    currentTab = ToDoScreenTab.ClinicalTasks;
    currentExtraButtons = hasPermissions([Permission.ClinActivityAllowed]) ? (
      <ActivityClinicalButtons />
    ) : (
      <TaskClinicalButtons />
    );
  } else if (routes.tasks.reminders.match(routing.location.pathname)) {
    currentTab = ToDoScreenTab.ClinicalReminders;
    currentExtraButtons = <RemindersListButtons />;
  } else if (routes.tasks.followUpReminders.match(routing.location.pathname)) {
    currentTab = ToDoScreenTab.FollowUpReminders;
    currentExtraButtons = <RemindersListButtons isFollowUpScreen />;
  } else {
    return null;
  }

  const handleLinkClicked = (item: PivotItem) => {
    const { itemKey } = item.props;

    switch (itemKey) {
      case ToDoScreenTab.Inbox:
        routing.push(routes.userInbox.basePath.pattern);
        break;
      case ToDoScreenTab.FollowUpTasks:
        routing.push(routes.tasks.inbox.pattern);
        break;
      case ToDoScreenTab.Incoming:
        routing.push(routes.inbox.incoming.pattern);
        break;
      case ToDoScreenTab.Uploads:
        routing.push(routes.inbox.upload.pattern);
        break;
      case ToDoScreenTab.ClinicalTasks:
        setSelectedClinicalActivities([]);
        routing.push(routes.tasks.clinical.pattern);
        break;
      case ToDoScreenTab.ClinicalReminders:
        setSelectedClinicalActivities([]);
        routing.push(routes.tasks.reminders.pattern);
        break;
      case ToDoScreenTab.FollowUpReminders:
        setSelectedClinicalActivities([]);
        routing.push(routes.tasks.followUpReminders.pattern);
        break;
    }
  };

  return (
    <Stack horizontal horizontalAlign="space-between">
      <Pivot
        onLinkClick={handleLinkClicked}
        selectedKey={currentTab}
        styles={{
          link: { backgroundColor: "inherit" }
        }}
      >
        {hasBusinessRoleClasses(BusinessRoleClasses.Provider) && (
          <PivotItem
            headerText={
              ui.documentsCount
                ? `${ToDoScreenTab.Inbox} (${ui.documentsCount})`
                : ToDoScreenTab.Inbox
            }
            itemKey={ToDoScreenTab.Inbox}
          />
        )}

        {hasTaskFollowupPermission && (
          <PivotItem
            headerText={ToDoScreenTab.FollowUpTasks}
            itemKey={ToDoScreenTab.FollowUpTasks}
          />
        )}

        {hasPermissions(Permission.IncomingReportsAllowed) && (
          <PivotItem
            headerText={ToDoScreenTab.Incoming}
            itemKey={ToDoScreenTab.Incoming}
          />
        )}
        {hasPermissions(Permission.UploadDocsAllowed) && (
          <PivotItem
            headerText={ToDoScreenTab.Uploads}
            itemKey={ToDoScreenTab.Uploads}
          />
        )}
        {hasPermissions(Permission.ClinTaskRead) && (
          <PivotItem
            headerText={ToDoScreenTab.ClinicalTasks}
            itemKey={ToDoScreenTab.ClinicalTasks}
          />
        )}
        {hasPermissions(
          [
            Permission.ClinicalReminderFeatureToggle,
            Permission.ClinicalReminderRead
          ],
          "and"
        ) && (
          <PivotItem
            headerText={ToDoScreenTab.ClinicalReminders}
            itemKey={ToDoScreenTab.ClinicalReminders}
          />
        )}
        {hasPermissions(
          [
            Permission.ClinicalReminderFeatureToggle,
            Permission.ClinicalReminderRead,
            Permission.ClinicalReminderAllowed
          ],
          "and"
        ) && (
          <PivotItem
            headerText={ToDoScreenTab.FollowUpReminders}
            itemKey={ToDoScreenTab.FollowUpReminders}
          />
        )}
      </Pivot>
      <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
        {currentExtraButtons && currentExtraButtons}
      </Stack>
    </Stack>
  );
});
