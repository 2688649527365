import { useState } from "react";
import { useField, useForm } from "react-final-form";

import {
  ConfirmDialog,
  Heading,
  IconButton,
  Link,
  Stack,
  Text
} from "@bps/fluent-ui";
import {
  EncounterClinicalDataDto,
  MedicalHistoryClinicalDataItemDto
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { MedicalHistoryFormDialog } from "@modules/clinical/screens/patient-record/components/medical-history/MedicalHistoryFormDialog.tsx";
import { MedicalHistoryFormValues } from "@modules/clinical/screens/patient-record/components/medical-history/MedicalHistoryFormValues.ts";
import { getSideOfBodyKey } from "@modules/clinical/screens/patient-record/components/medical-history/utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import {
  conditionModalFormNameOf,
  ConditionModalFormValues,
  DefaultDiagnosisValues
} from "./Condition.types.ts";

interface IConditionAddDiagnosisProps {
  selectedDiagnosis: MedicalHistoryClinicalDataItemDto | undefined;
  setSelectedDiagnosis: (
    diagnosis: MedicalHistoryClinicalDataItemDto | undefined
  ) => void;
}

export const ConditionAddDiagnosis: React.FunctionComponent<
  IConditionAddDiagnosisProps
> = ({ selectedDiagnosis, setSelectedDiagnosis }) => {
  const { clinicalRecord } = usePatientRecordScreenContext();
  const form = useForm<ConditionModalFormValues>();
  const { clinical } = useStores();
  const [isMedicalHistoryDialogVisible, setIsMedicalHistoryDialogVisible] =
    useState(false);

  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);

  const [defaultDiagnosisValues, setDefaultDiagnosisValues] = useState<
    DefaultDiagnosisValues | undefined
  >();

  const {
    input: { value: diagnosis }
  } = useField("diagnosis", {
    subscription: { value: true }
  });

  const {
    input: { value: diagnosisWithSide }
  } = useField("diagnosisWithSide", {
    subscription: { value: true }
  });

  const openMedicalHistoryDialog = () => {
    const medicalHistories =
      clinicalRecord?.clinicalData?.medicalHistory?.medicalHistories ?? [];

    const medicalHistory = medicalHistories.find(
      x => x.diagnosisCode?.originalText === diagnosis
    );

    setSelectedDiagnosis(medicalHistory);
    setIsMedicalHistoryDialogVisible(true);
    setDefaultDiagnosisValues({
      linkToCondition: true,
      disableLinkToCondition: true
    });
  };

  const closeMedicalHistoryDialog = () => {
    const diagnosis = form.getState().values.diagnosis;
    const medicalHistories =
      clinicalRecord?.clinicalData?.medicalHistory?.medicalHistories ?? [];

    const medicalHistory = medicalHistories.find(
      x => x.diagnosisCode?.originalText === diagnosis
    );

    setSelectedDiagnosis(medicalHistory);
    setIsMedicalHistoryDialogVisible(false);
  };

  const onDeleteDiagnosis = () => {
    setIsDeleteDialogVisible(true);
  };

  const handleConditionSubmit = (formValue: MedicalHistoryFormValues) => {
    let diagnosisText: string | undefined;
    if (formValue.diagnosisKey) {
      const diagnosisTerminology = clinical.getTerminologyFromMap(
        formValue.diagnosisKey
      );

      diagnosisText = diagnosisTerminology && diagnosisTerminology.text;
    }

    const sideOfTheBody = getSideOfBodyKey(formValue.diagnosisSideSelected);
    const diagnosisDescription = diagnosisText?.concat(
      getSideText(sideOfTheBody)
    );

    form.change(conditionModalFormNameOf("diagnosis"), diagnosisText);
    form.change(
      conditionModalFormNameOf("diagnosisWithSide"),
      diagnosisDescription
    );

    const medicalHistories =
      clinicalRecord.clinicalData?.medicalHistory?.medicalHistories ?? [];

    const medicalHistory = medicalHistories.find(
      x => x.diagnosisCode?.originalText === diagnosisText
    );

    setSelectedDiagnosis(medicalHistory);
  };

  const getSideText = (diagnosisSide: string | undefined) => {
    if (diagnosisSide) {
      const sideOfBody = clinical.ref.sidesOfBody.keyTextValues.find(
        x => x.key === diagnosisSide
      )?.text;

      if (sideOfBody) {
        return `(${sideOfBody})`;
      }
    }

    return "";
  };

  const onDeleteConfirm = async () => {
    const medicalHistories =
      clinicalRecord?.clinicalData?.medicalHistory?.medicalHistories ?? [];

    const medicalHistory = medicalHistories.find(
      x => x.diagnosisCode?.originalText === diagnosis
    );

    setSelectedDiagnosis(medicalHistory);

    if (medicalHistory?.id) {
      const clinicalData: EncounterClinicalDataDto = {};

      const selectedDiagnosisText = medicalHistory?.diagnosisCode?.originalText;
      //Delete Reason for consult that matches with the Add diagnosis link text in the condition modal
      const filteredRFV =
        clinicalRecord.clinicalData?.reasonForVisit?.reasonForVisits.filter(
          x => x.originalText !== selectedDiagnosisText
        );

      clinicalData.reasonForVisit = {
        eTag: clinicalRecord.clinicalData?.reasonForVisit?.eTag,
        reasonForVisits: filteredRFV || []
      };

      //Delete MDH that matches with the Add diagnosis link text in the condition modal
      const filteredMDH =
        clinicalRecord?.clinicalData?.medicalHistory?.medicalHistories?.filter(
          x => x.diagnosisCode?.originalText !== selectedDiagnosisText
        ) || [];

      clinicalData.medicalHistory = {
        eTag: clinicalRecord.clinicalData?.medicalHistory?.eTag,
        medicalHistories: filteredMDH,
        noSignificantHistory: filteredMDH?.[0]?.clinicallySignificant || false
      };

      //Delete MDH and RFC(if present)
      await clinicalRecord.saveClinicalData(clinicalData);

      form.change("diagnosis", "");
      form.change("diagnosisWithSide", "");
      setIsDeleteDialogVisible(false);
    }
  };

  const onDeleteCancel = () => {
    setIsDeleteDialogVisible(false);
  };

  return (
    <>
      {diagnosis ? (
        <Stack>
          <Heading labelPaddings>Diagnosis</Heading>
          <Stack horizontal verticalAlign="center">
            <Text
              styles={{
                root: {
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: 200
                }
              }}
            >
              {diagnosisWithSide}
            </Text>

            <Stack horizontal horizontalAlign="space-between">
              <IconButton
                iconProps={{ iconName: "Edit" }}
                title="Edit"
                onClick={openMedicalHistoryDialog}
              />
              <IconButton
                iconProps={{ iconName: "Delete" }}
                title="Delete"
                onClick={onDeleteDiagnosis}
              />
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Stack>
          <Link
            name={conditionModalFormNameOf("diagnosis")}
            styles={{ root: { marginTop: 36 } }}
            onClick={openMedicalHistoryDialog}
          >
            Add diagnosis
          </Link>
        </Stack>
      )}
      <MedicalHistoryFormDialog
        hidden={!isMedicalHistoryDialogVisible}
        defaultDiagnosisValues={defaultDiagnosisValues}
        selectedDiagnosis={selectedDiagnosis}
        onDismiss={closeMedicalHistoryDialog}
        medicalHistories={
          clinicalRecord?.clinicalData?.medicalHistory?.medicalHistories ?? []
        }
        onDataSubmit={formValue => {
          handleConditionSubmit(formValue);
        }}
        isCalledFromAddCondition={true}
      />

      <ConfirmDialog
        hidden={!isDeleteDialogVisible}
        minWidth={480}
        dialogContentProps={{
          title: "Delete diagnosis",
          subText: "Are you sure you want to delete this diagnosis?",
          showCloseButton: true,
          onDismiss: () => {
            setIsDeleteDialogVisible(false);
          }
        }}
        confirmButtonProps={{ text: "Delete" }}
        onConfirm={onDeleteConfirm}
        cancelButtonProps={{ text: "Cancel" }}
        onCancel={onDeleteCancel}
      />
    </>
  );
};
