import { observer } from "mobx-react-lite";
import { FC, useCallback } from "react";

import {
  IColumn,
  NoDataTile,
  ScrollablePane,
  Stack,
  Text
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { PagingOptions } from "@libs/api/dtos/index.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { RecentPatientDto } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { nameof } from "@libs/utils/name-of.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { InfiniteScrollList } from "@ui-components/InfiniteScrollList/InfiniteScrollList.tsx";
import { InfiniteScrollListProps } from "@ui-components/InfiniteScrollList/InfiniteScrollList.types.ts";
import { DEFAULT_PAGE_SIZE } from "@ui-components/InfiniteScrollList/useInfiniteScroll.ts";
import { UserFetcher } from "@ui-components/UserFetcher.tsx";

import { RecentPatientsAreaCell } from "./RecentPatientsAreaCell.tsx";
import { RecentPatientsDetailsCell } from "./RecentPatientsDetailsCell.tsx";
import {
  RecentPatientsFilter,
  RecentPatientsFilterState
} from "./RecentPatientsFilter.tsx";
import { recentPatientFilterOptions } from "./RecentPatientsFilter.types.ts";

interface RecentPatientDtoWithId extends RecentPatientDto {
  id: string;
}

const RecentPatientListInner: FC<{
  filter: RecentPatientsFilterState;
  reset: () => void;
}> = observer(({ filter, reset }) => {
  const { core, practice, userExperience } = useStores();

  const columns: IColumn[] = [
    {
      name: "When",
      fieldName: nameof<RecentPatientDtoWithId>("eventTime"),
      key: nameof<RecentPatientDtoWithId>("eventTime"),
      onRender: (item: RecentPatientDtoWithId) => (
        <Text>
          {DateTime.fromISOOrNow(item.eventTime).toFormat(
            "dd/LL/yyyy 'at' hh:mm a"
          )}
        </Text>
      ),
      minWidth: 180,
      maxWidth: 180
    },
    {
      name: "Patient",
      fieldName: nameof<RecentPatientDtoWithId>("patientId"),
      key: nameof<RecentPatientDtoWithId>("patientId"),
      onRender: (item: RecentPatientDtoWithId) => (
        <Text>{practice.contactsMap.get(item.patientId)?.reversedName}</Text>
      ),
      minWidth: 100,
      maxWidth: 160
    }
  ];

  if (core.hasPermissions(Permission.RecentPatientsAdminAllowed)) {
    columns.push({
      name: "User",
      fieldName: nameof<RecentPatientDtoWithId>("userId"),
      key: nameof<RecentPatientDtoWithId>("userId"),
      onRender: (item: RecentPatientDtoWithId) => (
        <UserFetcher userId={item.userId}>
          {user => <Text>{user.fullName}</Text>}
        </UserFetcher>
      ),
      minWidth: 200,
      maxWidth: 320
    });
  }

  columns.push(
    {
      name: "Event",
      fieldName: nameof<RecentPatientDtoWithId>("eventType"),
      key: nameof<RecentPatientDtoWithId>("eventType"),
      onRender: (item: RecentPatientDtoWithId) => (
        <RecentPatientsAreaCell recentPatient={item} />
      ),
      minWidth: 200,
      maxWidth: 320
    },
    {
      name: "Details",
      fieldName: nameof<RecentPatientDtoWithId>("eventVerb"),
      key: nameof<RecentPatientDtoWithId>("eventVerb"),
      minWidth: 200,
      onRender: (item: RecentPatientDtoWithId) => (
        <RecentPatientsDetailsCell recentPatient={item} />
      )
    }
  );

  const getItems: InfiniteScrollListProps<RecentPatientDtoWithId>["getItems"] =
    useCallback(
      async (query: PagingOptions) => {
        if (!filter.userIds || filter.userIds.length === 0) {
          return {
            skip: 0,
            take: DEFAULT_PAGE_SIZE,
            results: []
          };
        }

        const option = filter.eventCode
          ? recentPatientFilterOptions.find(
              option => option.key === filter.eventCode
            )
          : undefined;

        const result = await userExperience.getRecentPatients({
          ...query,
          patientIds: filter.patientIds,
          eventVerb: option?.data?.verb,
          eventType: option?.data?.type,
          userIds: filter.userIds
        });

        // preload contacts' names
        await practice.getContactsById(result.results.map(x => x.patientId));

        const results = result.results.map(x => ({
          ...x,
          id: x.recentPatientsId
        }));

        return {
          ...result,
          results
        };
      },
      [filter, userExperience, practice]
    );

  return (
    <Stack styles={{ root: { position: "relative", flex: 1 } }}>
      <ScrollablePane>
        <InfiniteScrollList
          stickyHeader
          columns={columns}
          getItems={getItems}
          onRenderNoResults={() => (
            <NoDataTile
              styles={{ root: { boxShadow: "none" } }}
              textProps={{
                text: "No record recent interactions with patient records"
              }}
              linkProps={
                filter.patientIds || filter.eventCode
                  ? {
                      text: "Clear filter",
                      onClick: () => reset()
                    }
                  : { hidden: true }
              }
            />
          )}
        />
      </ScrollablePane>
    </Stack>
  );
});

export const RecentPatientsList: FC = observer(() => (
  <Stack grow tokens={{ childrenGap: 16 }}>
    <RecentPatientsFilter>
      {({ values: filter }, { reset }) => (
        <RecentPatientListInner filter={filter} reset={reset} />
      )}
    </RecentPatientsFilter>
  </Stack>
));
