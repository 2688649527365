import { FC } from "react";

import { MessageBar, MessageBarType } from "@bps/fluent-ui";

type OverviewCardWarningProps = {
  claimWithClientError: boolean;
  lastRequestedDateTime: string | undefined;
  allAttemptsUsedUp: boolean | 0 | undefined;
  nextRequestDateTime: string | undefined;
  attemptNumber: number | undefined;
  maxAttempts: number | undefined;
};

export const OverviewCardWarning: FC<OverviewCardWarningProps> = ({
  allAttemptsUsedUp,
  attemptNumber,
  claimWithClientError,
  lastRequestedDateTime,
  maxAttempts,
  nextRequestDateTime
}) => {
  const messageBarIconStyles = { iconContainer: { alignItems: "center" } };

  if (claimWithClientError) {
    return (
      <MessageBar
        messageBarType={MessageBarType.warning}
        styles={messageBarIconStyles}
      >
        Status last requested {lastRequestedDateTime}. Please review errors
        before requesting status again
      </MessageBar>
    );
  } else if (allAttemptsUsedUp) {
    return (
      <MessageBar
        messageBarType={MessageBarType.warning}
        styles={messageBarIconStyles}
      >
        All status update retries attempted. Please review errors before
        requesting status again. Last status request was
        {lastRequestedDateTime}
      </MessageBar>
    );
  }
  return (
    <MessageBar
      messageBarType={MessageBarType.warning}
      styles={messageBarIconStyles}
    >
      Status update queued and will be sent {nextRequestDateTime}. Retry (
      {attemptNumber} / {maxAttempts}) Previous status request was{" "}
      {lastRequestedDateTime}
    </MessageBar>
  );
};
