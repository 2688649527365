import { getSlicerTextValues, ReportDefinition } from "bps-powerbi-helper";
import * as pbi from "powerbi-client";

import { CanvasMeasure } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";

/** Determined this value based upon experimentation */
const PIXELS_PER_EXCEL_WIDTH_UNIT = 7.5;

/**
 * The following types are a minimal implementation of the
 * types typically in exceljs.
 * we have emulated them here to allow for us to dynamically load the excelJs library.
 */
interface CellErrorValue {
  error:
    | "#N/A"
    | "#REF!"
    | "#NAME?"
    | "#DIV/0!"
    | "#NULL!"
    | "#VALUE!"
    | "#NUM!";
}

interface CellRichTextValue {
  richText: any;
}

interface CellHyperlinkValue {
  text: string;
  hyperlink: string;
}

interface CellFormulaValue {
  formula: string;
  result?: number | string | Date | { error: CellErrorValue };
  date1904: boolean;
}

interface CellSharedFormulaValue {
  sharedFormula: string;
  readonly formula?: string;
  result?: number | string | Date | { error: CellErrorValue };
  date1904: boolean;
}
type CellValue =
  | null
  | number
  | string
  | boolean
  | Date
  | undefined
  | CellErrorValue
  | CellRichTextValue
  | CellHyperlinkValue
  | CellFormulaValue
  | CellSharedFormulaValue;
interface Cell {
  value: CellValue;
}

interface Row {
  /**
   * Iterate over all cells that have values in a row
   */
  eachCell(callback: (cell: Cell, cellNumber: number) => void): void;
}
interface Column {
  name?: string;
  width?: number;
}

interface Worksheet {
  getColumn(index: number): Column;
  columnCount: number;
  /**
   * Iterate over all rows that have values in a worksheet
   */
  eachRow(callback: (row: Row, rowNumber: number) => void): void;
}

/***
 * This function automatically sizes all spreadsheet columns, based upon the text, and font size.
 * Basically, it loops through each column, and determines the widest string, and sets to that value.
 */
const autoSize = (sheet: Worksheet, fromRow: number = 0) => {
  const ruler = new CanvasMeasure();
  ruler.fontFamily = "Arial";

  const maxColumnLengths: Array<number> = [];
  sheet.eachRow((row: any, rowNum: number) => {
    if (rowNum < fromRow) {
      return;
    }

    row.eachCell &&
      row.eachCell((cell: any, num: number) => {
        if (typeof cell.value === "string") {
          if (maxColumnLengths[num] === undefined) {
            maxColumnLengths[num] = 0;
          }

          const fontPoints = cell.font && cell.font.size ? cell.font.size : 12;
          ruler.fontSize = `${fontPoints} pt`;
          const cellWidth = ruler.getTextWidth(cell.value);

          maxColumnLengths[num] = Math.max(maxColumnLengths[num], cellWidth);
        } else if (
          typeof cell.value === "object" ||
          typeof cell.value === "number"
        ) {
          // cell.value === "object" => likely a date - and if a number, give it some width.
          maxColumnLengths[num] = 90;
        }
      });
  });

  for (let i = 1; i <= sheet.columnCount; i++) {
    const col = sheet.getColumn(i);
    const headerWidth = ruler.getTextWidth(col.name ?? "");

    const width = Math.max(maxColumnLengths[i], headerWidth);
    if (width) {
      col.width = width / PIXELS_PER_EXCEL_WIDTH_UNIT + 1;
    }
  }
};

const SheetStrings = {
  Results: "Results",
  Settings: "Settings",
  SpreadsheetTopLeft: "A1",
  DateFrom: "Date from",
  DateTo: "Date to"
};
export const powerBiToLuxonReplacements = {
  T: "h:mm a",
  "%d/MM/yyyy": "dd/LL/yyyy",
  "%d/MM/yy": "dd/LL/yy",
  dddd: "EEEE",
  yyyy: "yyyy",
  "dd/MM/yyyy": "dd/LL/yyyy",
  "%dd/MM/yyyy": "dd/LL/yyyy",
  "MM\\-yyyy": "LL-yyyy",
  MMMM: "LLLL",
  G: "dd/LL/yyyy h:mm a",
  d: "dd/LL/yyyy"
};

/**
 * Call this function to download and Excel Spreadsheet, containing the data from data,
 * and settings from report.
 * @param data
 *
 * @param report
 */
export const downloadExcelUtils = async (
  data: ReportDefinition,
  report: pbi.Report
) => {
  import("exceljs").then(async ExcelJS => {
    const workbook = new ExcelJS.Workbook();
    const resultsSheet = workbook.addWorksheet(SheetStrings.Results);
    const settingsSheet = workbook.addWorksheet(SheetStrings.Settings);
    const cols = data.viewColumns.map(x => {
      const y = {
        name: x.customName ?? x.powerBiName,
        filterButton: true
      };
      return y;
    });

    enum NumberFormats {
      none = 1,
      currency = 2
    }

    const rows = data.rows.map(x => {
      return x.data.map((cell, i) => {
        const col = data.viewColumns[i];
        const format = col?.settings?.valueFormat;
        const excelFormat =
          (format && powerBiToLuxonReplacements[format]) ?? format;

        if (col.settings?.dataType === "date" && cell && excelFormat) {
          const date = DateTime.fromJSDate(cell);
          return date ? date.toFormat(excelFormat) : cell;
        }
        return cell;
      });
    });

    resultsSheet.addRows([cols.map(x => ({ name: x.name, width: 100 }))]);
    resultsSheet.addRows(rows);
    const table = resultsSheet.addTable({
      columns: cols,
      rows,
      name: SheetStrings.Results,
      ref: SheetStrings.SpreadsheetTopLeft
    });

    settingsSheet.addRow([SheetStrings.Settings]);
    settingsSheet.addRow([""]);
    data.reportDates &&
      settingsSheet.addRows([
        [SheetStrings.DateFrom, data.reportDates.startDate],
        [SheetStrings.DateTo, data.reportDates.endDate]
      ]);

    data.viewColumns.forEach((col, i) => {
      const powerBiToExcelNumberFormats = {
        "\\$#,0.00;(\\$#,0.00);\\$#,0.00": NumberFormats.currency,
        "0": NumberFormats.none
      };

      const valueFormat = col?.settings?.valueFormat;
      const customFormat =
        col.settings?.dataType === "number" &&
        valueFormat &&
        Object.keys(powerBiToExcelNumberFormats).find(x => x === valueFormat);
      if (customFormat && valueFormat) {
        const excelFormat: NumberFormats =
          powerBiToExcelNumberFormats[valueFormat];
        if (excelFormat === NumberFormats.currency) {
          const column = resultsSheet.getColumn(i + 1); // Columns are 1 indexed in Excel.
          column.eachCell((x, i) => {
            // Skip first (header) row. (one indexed again)
            if (i !== 1) {
              x.numFmt = "\\$#,0.00;(\\$#,0.00);\\$#,0.00";
            }
          });
        }
      }
    });

    const slicerDetails = await getSlicerTextValues(report);
    slicerDetails.forEach(x => settingsSheet.addRow([x.title, x.values]));

    table.commit();

    autoSize(settingsSheet);
    autoSize(resultsSheet);

    const buf = await workbook.xlsx.writeBuffer();
    // download the processed file

    import("file-saver").then(saveAsLib => {
      saveAsLib.saveAs(new Blob([buf]), `${data.name}.xlsx`);
    });
  });
};
