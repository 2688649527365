import { useContext } from "react";
import { useField, useForm, useFormState } from "react-final-form";

import {
  HideStack,
  Link,
  MessageBar,
  MessageBarType,
  Stack
} from "@bps/fluent-ui";
import {
  ClaimEpisodeOfCareDto,
  ClaimStatuses
} from "@libs/gateways/acc/AccGateway.dtos.ts";
import { MedicalHistoryClinicalDataItemDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { InsurerPickerField } from "@modules/acc/screens/claim-management/components/InsurerPickerField.tsx";
import { ConditionContext } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/ConditionContext.ts";
import { SendFormCheckbox } from "@modules/forms/components/SendFormCheckbox.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { DropdownField } from "@ui-components/form/DropdownField.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { FieldsSpy } from "@ui-components/form/FieldsSpy.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { ToggleField } from "@ui-components/form/Toggle/ToggleField.tsx";
import { When } from "@ui-components/withPerm.tsx";

import {
  ConditionModalFormLabels,
  conditionModalFormNameOf,
  ConditionModalFormValues
} from "./Condition.types.ts";
import { ConditionAddDiagnosis } from "./ConditionAddDiagnosis.tsx";

interface ConditionModalFormBaseProps {
  defaultNzPublicInsurerId?: string;
  claimEpisodesOfCare?: ClaimEpisodeOfCareDto[] | undefined;
  disabled: boolean | undefined;
  selectedDiagnosis: MedicalHistoryClinicalDataItemDto | undefined;
  setSelectedDiagnosis: (
    diagnosis: MedicalHistoryClinicalDataItemDto | undefined
  ) => void;
}

export const ConditionModalFormFields: React.FC<
  ConditionModalFormBaseProps
> = ({
  defaultNzPublicInsurerId,
  claimEpisodesOfCare,
  disabled,
  selectedDiagnosis,
  setSelectedDiagnosis
}) => {
  const {
    patientCommunications,
    initialValues,
    claimHasInvalidTransitionStatus,
    claimHasBeenBilled,
    patientId,
    condition,
    initialCondition
  } = useContext(ConditionContext);

  const { acc, routing, core } = useStores();

  const getStatusOptions = () => {
    if (
      initialValues.claimStatusCode === ClaimStatuses.Accepted ||
      initialValues.claimStatusCode === ClaimStatuses.Held ||
      initialValues.claimStatusCode === ClaimStatuses.NotAvailable ||
      initialValues.claimStatusCode === ClaimStatuses.GetStatusError ||
      initialValues.claimStatusCode === ClaimStatuses.NotVerified
    ) {
      return acc.ref.claimStatuses.keyTextValues.filter(
        x =>
          x.key === ClaimStatuses.Accepted ||
          x.key === ClaimStatuses.Accredited ||
          x.key === ClaimStatuses.Declined ||
          x.key === initialValues.claimStatusCode
      );
    }

    if (values.referralIn) {
      return acc.ref.claimStatuses.keyTextValues.filter(
        x =>
          x.key === ClaimStatuses.Accepted || x.key === ClaimStatuses.Accredited
      );
    }
    return claimStatusCode !== ClaimStatuses.Accredited
      ? acc.ref.claimStatuses.keyTextValues
      : acc.ref.claimStatuses.keyTextValues.filter(
          x =>
            x.key === ClaimStatuses.Accepted ||
            x.key === ClaimStatuses.Accredited ||
            x.key === ClaimStatuses.Declined ||
            x.key === ClaimStatuses.Discharged
        );
  };

  const getStatusText = (claimStatusCode?: string) =>
    claimStatusCode ? acc.ref.claimStatuses.get(claimStatusCode)?.text : "";

  const { values } = useFormState<ConditionModalFormValues>({
    subscription: { values: true }
  });

  const isShowing =
    (!!values.insurerContactId &&
      values.insurerContactId !== defaultNzPublicInsurerId) ||
    values.claimStatusCode === ClaimStatuses.Accredited;

  const form = useForm<ConditionModalFormValues>();

  const showSendAcc45Form =
    !initialValues.private &&
    !initialValues.claimStatusCode &&
    !values.referralIn &&
    !values.private;

  const disablePrivate = claimHasInvalidTransitionStatus || claimHasBeenBilled;

  const displayCannotBeChangedBackToClaim =
    initialCondition &&
    condition?.isPrivate &&
    claimEpisodesOfCare &&
    claimEpisodesOfCare.length > 0;

  const cannotReverseChangeToPrivate =
    initialCondition &&
    values.private &&
    initialValues.private !== values.private;

  const {
    input: { value: claimStatusCode }
  } = useField("claimStatusCode", {
    subscription: { value: true }
  });

  const isInsurerUnEditable =
    claimStatusCode === ClaimStatuses.Queued ||
    claimStatusCode === ClaimStatuses.Pending ||
    claimStatusCode === ClaimStatuses.Discharged;

  return (
    <Stack
      tokens={{ childrenGap: 8 }}
      styles={{
        root: {
          overflow: "hidden"
        }
      }}
    >
      <Stack horizontal tokens={{ childrenGap: 8 }}>
        {(core.hasPermissions(Permission.ClaimRead) || !initialCondition) && (
          <>
            <ButtonsGroupSingleChoiceField
              disabled={
                disabled ||
                displayCannotBeChangedBackToClaim ||
                !core.hasPermissions(Permission.ClaimWrite)
              }
              label={ConditionModalFormLabels.conditionType}
              name={conditionModalFormNameOf("private")}
              required
              options={[
                {
                  key: false,
                  text: "Claim",
                  disabled: disabled || displayCannotBeChangedBackToClaim
                },
                {
                  key: true,
                  text: "Private",
                  disabled:
                    disablePrivate ||
                    disabled ||
                    displayCannotBeChangedBackToClaim
                }
              ]}
            />
            <ConditionAddDiagnosis
              selectedDiagnosis={selectedDiagnosis}
              setSelectedDiagnosis={setSelectedDiagnosis}
            />
            <FieldSpy
              name={conditionModalFormNameOf("private")}
              onChange={(selectedKey: boolean | undefined) => {
                // Selected the private condition?
                if (selectedKey) {
                  form.change(
                    conditionModalFormNameOf("insurerContactId"),
                    undefined
                  );
                  return;
                }
                // Restore the insurerContactId when initial insurerContactId exsit
                if (initialValues.insurerContactId) {
                  form.change(
                    conditionModalFormNameOf("insurerContactId"),
                    initialValues.insurerContactId
                  );
                  return;
                }

                // Selected the claim condition
                if (defaultNzPublicInsurerId) {
                  form.change(
                    conditionModalFormNameOf("insurerContactId"),
                    defaultNzPublicInsurerId
                  );
                }
              }}
            />
            {displayCannotBeChangedBackToClaim && (
              <MessageBar>
                {ConditionModalFormLabels.cannotBeChangedBackToClaim}
              </MessageBar>
            )}
            {cannotReverseChangeToPrivate && (
              <MessageBar messageBarType={MessageBarType.warning}>
                {ConditionModalFormLabels.changeToPrivateCannotBeReversed}
              </MessageBar>
            )}
            {disablePrivate && (
              <MessageBar>
                <Stack tokens={{ childrenGap: 4 }}>
                  <div>
                    {claimHasInvalidTransitionStatus
                      ? `'${getStatusText(
                          initialCondition?.claim?.claimStatus
                        )}' ${
                          ConditionModalFormLabels.claimTransitionDisabledMessage
                        }`
                      : `${ConditionModalFormLabels.claimHasInvoicesMessage} `}
                  </div>
                  {claimHasBeenBilled && !claimHasInvalidTransitionStatus && (
                    <Link
                      onClick={(event: React.MouseEvent) => {
                        event.preventDefault();
                        routing.pushWithFromQuery({
                          pathname: routes.accounts.account.path({
                            id: patientId
                          })
                        });
                      }}
                    >
                      Go to accounts
                    </Link>
                  )}
                </Stack>
              </MessageBar>
            )}
          </>
        )}
      </Stack>
      <div>
        <ToggleField
          disabled={disabled}
          label={ConditionModalFormLabels.referral}
          name={conditionModalFormNameOf("referralIn")}
          onText="Yes"
          offText="No"
          styles={{
            root: {
              marginBottom:
                values.referralIn ||
                initialCondition?.claim?.canUpdateClaimNumber
                  ? 8
                  : 0
            }
          }}
        />
        {(values.referralIn ||
          initialCondition?.claim?.canUpdateClaimNumber) && (
          <Fieldset frame>
            <Stack horizontal tokens={{ childrenGap: 8 }}>
              <DatePickerField
                disabled={disabled}
                name={conditionModalFormNameOf("referralDate")}
                label={ConditionModalFormLabels.referralDate}
              />
              <TextInputField
                disabled={
                  disabled && !initialCondition?.claim?.canUpdateClaimNumber
                }
                label={
                  values.private === false &&
                  core.hasPermissions(Permission.ClaimRead)
                    ? ConditionModalFormLabels.claim
                    : ConditionModalFormLabels.reference // reference if private or undefined (condition type not yet selected)
                }
                name={conditionModalFormNameOf("referralNumber")}
                styles={{ root: { width: 182 } }}
                required={values.private === false}
              />
            </Stack>
            <TextInputField
              disabled={disabled}
              label={ConditionModalFormLabels.referralProvider}
              name={conditionModalFormNameOf("referralProvider")}
            />
          </Fieldset>
        )}
      </div>

      <HideStack when={values.private !== false}>
        <Stack
          horizontal
          tokens={{ childrenGap: 8 }}
          styles={{
            root: {
              overflow: "hidden"
            }
          }}
        >
          <When permission={Permission.ClaimRead}>
            <InsurerPickerField
              disabled={
                isInsurerUnEditable ||
                (values.private ?? true) ||
                !core.hasPermissions(Permission.ClaimWrite)
              }
              styles={{
                root: {
                  width: isShowing ? 300 : 432,
                  transition: "width .5s"
                }
              }}
              name={conditionModalFormNameOf("insurerContactId")}
              label={ConditionModalFormLabels.insurer}
              required
              placeholder={ConditionModalFormLabels.accPlaceholder}
            />
            <DropdownField
              styles={{
                root: {
                  minWidth: 124
                }
              }}
              name={conditionModalFormNameOf("claimStatusCode")}
              options={getStatusOptions()}
              label={ConditionModalFormLabels.claimStatus}
              disabled={
                (!!values.insurerContactId &&
                  values.insurerContactId === defaultNzPublicInsurerId) ||
                !core.hasPermissions(Permission.ClaimWrite)
              }
            />
          </When>
        </Stack>
        {showSendAcc45Form && (
          <SendFormCheckbox
            communication={patientCommunications(patientId)}
            heading={ConditionModalFormLabels.sendAccFormHeading}
            fieldName={conditionModalFormNameOf("sendAccForm")}
            checkBoxLabel={ConditionModalFormLabels.sendAccForm}
          />
        )}
      </HideStack>

      <FieldsSpy<ConditionModalFormValues>
        onChange={() => {
          if (!showSendAcc45Form) {
            form.change(conditionModalFormNameOf("sendAccForm"), false);
          }
        }}
        fieldNames={[
          conditionModalFormNameOf("referralIn"),
          conditionModalFormNameOf("private")
        ]}
      />
    </Stack>
  );
};
