import { observer } from "mobx-react-lite";
import React from "react";

import {
  FontIcon,
  LabeledText,
  Link,
  Stack,
  Text,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import {
  DidNotArriveItem,
  DidNotArriveLabel
} from "@modules/booking/screens/did-not-arrive/DidNotArriveListScreen.types.ts";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import { User } from "@stores/core/models/User.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

export const DidNotArriveRescheduledLabel: React.FC<{
  item: DidNotArriveItem;
}> = observer(({ item }) => {
  const { booking } = useStores();
  const theme = useTheme();

  const { calendarEvent } = item;

  const getTooltipContent = (
    calendarEvent: CalendarEvent,
    user: User,
    rescheduledCalendarEvent?: CalendarEvent
  ) => {
    if (calendarEvent.manuallyRescheduledBy) {
      const baseMessage = `Manually rescheduled by ${user?.fullName}`;
      const comment = calendarEvent.manuallyRescheduledComment;

      return comment ? (
        <Stack tokens={{ childrenGap: 8 }}>
          <Text variant="small">{baseMessage}</Text>
          <LabeledText noSemicolon label="Comment">
            {comment}
          </LabeledText>
        </Stack>
      ) : (
        baseMessage
      );
    }
    return `Rescheduled to ${rescheduledCalendarEvent?.startDateTime.toDayDefaultFormat()} with ${rescheduledCalendarEvent
      ?.user?.fullName} by ${user.fullName}`;
  };

  if (!calendarEvent.rescheduledAppointmentId) {
    return (
      <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }}>
        <FontIcon
          iconName="AddEvent"
          styles={{
            root: {
              color: theme.semanticColors.link
            }
          }}
        />
        <Link
          onClick={() =>
            booking.ui.onRebookOrRescheduleButtonClick(item.calendarEvent, 0, {
              isRescheduledFromIncompleteList: true
            })
          }
        >
          <span>{DidNotArriveLabel.Reschedule}</span>
        </Link>
      </Stack>
    );
  }

  return (
    <DataFetcher
      fetch={async ({ core, booking }) => {
        let userId = calendarEvent.manuallyRescheduledBy;
        let rescheduledCalendarEvent: CalendarEvent | undefined;
        if (!userId && calendarEvent.rescheduledAppointmentId) {
          rescheduledCalendarEvent = await booking.getCalendarEvent(
            calendarEvent.rescheduledAppointmentId
          );
          userId = rescheduledCalendarEvent.bookedBy;
        }

        const user = userId ? await core.getUser(userId) : undefined;

        return { user, rescheduledCalendarEvent };
      }}
    >
      {({ user, rescheduledCalendarEvent }) => {
        if (!user) {
          return null;
        }

        return (
          <TooltipHost
            content={getTooltipContent(
              calendarEvent,
              user,
              rescheduledCalendarEvent
            )}
          >
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: 8 }}
            >
              <FontIcon
                iconName={
                  calendarEvent.manuallyRescheduledBy
                    ? "EventToDoLogo"
                    : "Completed"
                }
                styles={{
                  root: {
                    color: theme.semanticColors.successIcon
                  }
                }}
              />
              <Link>{DidNotArriveLabel.Rescheduled}</Link>
            </Stack>
          </TooltipHost>
        );
      }}
    </DataFetcher>
  );
});
