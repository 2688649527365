import { useContext } from "react";

import {
  ButtonType,
  FontSizes,
  FontWeights,
  IconButton,
  IContextualMenuItem,
  Stack,
  ToolTipButton
} from "@bps/fluent-ui";
import { EncounterStatus } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ConditionHelper } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/ConditionHelper.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { Encounter } from "@stores/clinical/models/Encounter.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { ClaimAppointmentContext } from "./ClaimAppointmentContext.tsx";
import { ConditionConsultsWrapper } from "./ConditionConsultsWrapper.tsx";
import { ConditionContextMenuOptions } from "./ConditionDetails.types.ts";
import { DeleteTooltips } from "./ConditionDetailsContextMenu.enum.ts";
import { ConditionDetailsModel } from "./ConditionsSidePanelHelper.ts";

interface ConditionDetailsContextMenuProps {
  model: ConditionDetailsModel;
  isNoLinkedConsults?: boolean;
  onDelete?: () => void;
  linkedEncounters?: Encounter[];
}

export const ConditionDetailsContextMenu: React.FC<
  ConditionDetailsContextMenuProps
> = ({ model, isNoLinkedConsults, onDelete, linkedEncounters }) => {
  const root = useStores();
  const { core, clinical } = root;
  const { clinicalRecord, isViewOnly } = usePatientRecordScreenContext();
  const { isLinkedCondition } = useContext(ClaimAppointmentContext);

  const {
    setConditionConsultsExportDialogVisible,
    setIsUnlinkConditionConfirmationDialogVisible
  } = clinical.ui;

  const condition = model.condition;
  const linkedCalendarEventIds = condition?.calendarEventIds;
  const patientName = clinicalRecord.patient?.nameWithTitle ?? "";
  const diagnosis = model.diagnosis ?? "";
  const claimBadgeText = ConditionHelper.getClaimBadgeText(condition);
  const patientId = clinicalRecord.patient?.id ?? "";
  const currentEpisodeOfCareId = condition?.episodeOfCareId ?? "";
  const claimId = condition?.claim?.id;
  const claimNumber = condition?.claim?.claimNumber;

  const appointmentVisits = model.appointmentVisits ?? [];

  const isBilled = appointmentVisits
    ? !!appointmentVisits.find(x => x.billed)
    : false;

  const isLinked = isLinkedCondition(currentEpisodeOfCareId);

  const hasLinkedEncounters = linkedEncounters
    ? linkedEncounters.length > 0
    : false;

  const otherActiveEncounters = linkedEncounters?.filter(
    x => x.id !== clinicalRecord.currentEncounterId && !x.isClosed && x.user
  );

  const otherActiveEncounterProvider =
    otherActiveEncounters &&
    otherActiveEncounters.length > 0 &&
    otherActiveEncounters[0].user
      ? otherActiveEncounters[0].user.fullName
      : "";

  const isRemovable =
    condition &&
    !isLinked &&
    isNoLinkedConsults &&
    !claimNumber &&
    !isBilled &&
    (linkedCalendarEventIds?.length === 0 || !linkedCalendarEventIds) &&
    core.hasPermissions(Permission.EoCWrite) &&
    !hasLinkedEncounters;

  const getTooltipText = () => {
    if (isRemovable || !core.hasPermissions(Permission.EoCWrite)) {
      return undefined;
    }
    if (isBilled) {
      return DeleteTooltips.LinkedToABilledVisit;
    }
    if (isLinked) {
      return DeleteTooltips.LinkedToTheOpenEncounter;
    }
    if (otherActiveEncounters && otherActiveEncounters?.length > 0) {
      return DeleteTooltips.OpenEncounter.replace(
        "{ProviderFullName}",
        otherActiveEncounterProvider
      );
    }
    if (!isNoLinkedConsults) {
      return DeleteTooltips.RelatedConsults;
    }
    if (linkedCalendarEventIds && linkedCalendarEventIds.length > 0) {
      return DeleteTooltips.RelatedToCalendarEvents;
    }
    if (claimNumber) {
      return DeleteTooltips.HasAClaimNumber;
    }
    return DeleteTooltips.OtherReason;
  };

  const items: IContextualMenuItem[] = [
    {
      key: ConditionContextMenuOptions.Export,
      disabled: isNoLinkedConsults || isViewOnly,
      text: "Export",
      onClick: () => {
        setConditionConsultsExportDialogVisible(true);
      }
    },
    {
      key: ConditionContextMenuOptions.Unlink,
      text: "Unlink",
      disabled:
        !isLinked ||
        clinicalRecord.openEncounter?.status !== EncounterStatus.Open,
      onClick: () => setIsUnlinkConditionConfirmationDialogVisible(true)
    },
    {
      key: ConditionContextMenuOptions.Delete,
      onRender: () => {
        return (
          <ToolTipButton
            buttonProps={{
              disabled: !isRemovable,
              onClick: async () => {
                await clinical.deleteEpisodeOfCare(currentEpisodeOfCareId);
                if (onDelete) onDelete();
              },
              buttonType: ButtonType.normal,
              styles: {
                root: {
                  border: 0,
                  paddingLeft: 4,
                  placeContent: "flex-start",
                  width: "100%"
                },
                textContainer: {
                  display: "flex",
                  alignItems: "flex-start"
                },
                rootDisabled: {
                  backgroundColor: "transparent"
                },
                label: {
                  fontWeight: FontWeights.regular,
                  fontSize: FontSizes.size14
                }
              },
              text: "Delete"
            }}
            toolTipContent={getTooltipText()}
          />
        );
      }
    }
  ].filter(
    x =>
      x.key !== ConditionContextMenuOptions.Unlink ||
      core.hasPermissions(Permission.UnlinkConditionAllowed)
  );

  return (
    <Stack>
      <IconButton
        menuIconProps={{ iconName: "More" }}
        menuProps={{
          items
        }}
      />
      <ConditionConsultsWrapper
        patientName={patientName}
        diagnosis={diagnosis}
        claimBadgeText={claimBadgeText}
        patientId={patientId}
        episodeOfCareId={currentEpisodeOfCareId}
        claimId={claimId}
        claimNumber={claimNumber}
      />
    </Stack>
  );
};
