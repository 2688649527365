import { StandardRangesOptionsKeys } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";
import { FilterBarProps } from "@ui-components/filter-bar/FilterBar.types.ts";

import {
  RecentPatientFilterKeys,
  recentPatientFilterOptions
} from "./RecentPatientsFilter.types.ts";

type RecentPatientsFilterProps = Pick<
  FilterBarProps<RecentPatientsFilterState>,
  "children"
>;

export interface RecentPatientsFilterState {
  userIds?: string[];
  patientIds?: string[];
  eventCode?: string;
  eventTimeFrom?: Date;
  eventTimeTo?: Date;
  incompleteNotesOnly?: true;
}

export const RecentPatientsFilter: React.FC<RecentPatientsFilterProps> = ({
  children
}) => {
  const { core, practice, routing } = useStores();
  const location = routing.location;
  const recentPatientsFilter = location?.state?.filter as
    | RecentPatientsFilterState
    | undefined;

  const defaultPatientName = recentPatientsFilter?.patientIds?.[0]
    ? practice.contactsMap.get(recentPatientsFilter.patientIds[0])?.reversedName
    : undefined;

  const items: FilterBarProps<RecentPatientsFilterState>["items"] = [
    {
      type: "contactPicker",
      name: "patientIds",
      stickItem: true,
      props: {
        inputProps: {
          id: "recent-patients-search",
          placeholder: "Search for patient",
          defaultVisibleValue: defaultPatientName
        },
        styles: { root: { maxWidth: 250, minWidth: 250 } }
      }
    },
    {
      type: "optionsSelect",
      name: "eventCode",
      props: {
        id: "recent-patients-events",
        hideSearchOption: true,
        options: recentPatientFilterOptions,
        placeholder: "Event",
        calloutWidth: 200
      }
    }
  ];

  if (core.hasPermissions(Permission.RecentPatientsAdminAllowed)) {
    items.push({
      type: "usersSelect",
      name: "userIds",
      props: {
        id: "recent-patients-users",
        styles: { root: { width: 200 } },
        placeholder: "Users",
        multiSelect: true
      }
    });
  }

  items.push({
    type: "datesRangePicker",
    name: "datesRangePicker",
    props: {
      placeholder: "Dates",
      startDateProps: {
        minDate: DateTime.today().minus({ days: 7 }).toJSDate(),
        maxDate: DateTime.jsDateNow()
      },
      endDateProps: {
        minDate: DateTime.today().minus({ days: 7 }).toJSDate(),
        maxDate: DateTime.jsDateNow()
      },
      startDateName: "eventTimeFrom",
      endDateName: "eventTimeTo",
      standardRanges: [
        StandardRangesOptionsKeys.today,
        StandardRangesOptionsKeys.yesterday
      ]
    }
  });

  return (
    <FilterBar<RecentPatientsFilterState>
      items={items}
      initialValues={{
        ...recentPatientsFilter,
        userIds: [core.userId]
      }}
      presets={[
        {
          text: "Incomplete notes",
          name: "incompleteNotesOnly",
          id: "recent-patients-incomplete",
          iconName: "BpDocEdit",
          tooltip: "Only show Incomplete notes",
          tooltipPresses: "Only showing Incomplete notes",
          valuesToBeSetOnToggleOn: {
            eventCode: RecentPatientFilterKeys.ConsultIncomplete
          },
          valuesToBeSetOnToggleOff: {
            eventCode: undefined
          }
        }
      ]}
    >
      {children}
    </FilterBar>
  );
};
