import { observer } from "mobx-react-lite";

import { CenteredBox, NoDataTile } from "@bps/fluent-ui";
import { useFormContext } from "@bps/utils";

import { BhbProvidersOnlineFilter } from "./BhbProvidersCard.types.ts";
import { BhbAvailability } from "./BhbProvidersFilter.tsx";

export const BhbProvidersNoResults = observer(() => {
  const {
    state: { dirty },
    actions: { setValue }
  } = useFormContext<BhbProvidersOnlineFilter>();

  return (
    <CenteredBox>
      <NoDataTile
        textProps={{
          text: dirty
            ? "No records found"
            : "No providers set as showing for online bookings"
        }}
        linkProps={{
          text: "Remove filters to view all providers",
          onClick: () => {
            setValue("displayedOnlinePreset", false);
            setValue("onlineAvailability", [
              BhbAvailability.ShowAndBook,
              BhbAvailability.ShowAndCall,
              BhbAvailability.DontShow
            ]);
          },
          hidden: dirty
        }}
        showBoxShadow={false}
      />
    </CenteredBox>
  );
});
