import { observer } from "mobx-react-lite";
import { stringify } from "query-string";
import React, { FC } from "react";

import { IconButton, IContextualMenuItem, Stack } from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { openNewInvoiceFromCalendar } from "@modules/booking/utils/booking.utils.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { invoiceStatusIcon } from "../../booking-calendar/components/booking-calendar-event/BookingCalendarEvent.types.ts";
import {
  DidNotArriveItem,
  DidNotArriveLabel
} from "../DidNotArriveListScreen.types.ts";

export const DidNotArriveActionsButton: FC<{
  item: DidNotArriveItem;
  onMarkAsRescheduled: (item: DidNotArriveItem) => void;
}> = observer(({ item, onMarkAsRescheduled }) => {
  const root = useStores();
  const getDidNotArriveActions = () => {
    const { booking, core, routing } = root;

    const getActionItems = (item: DidNotArriveItem): IContextualMenuItem[] => {
      const { patientId, calendarEvent } = item;
      const { invoiceId, invoiceStatus } = calendarEvent;
      const items: IContextualMenuItem[] = [];

      if (core.hasPermissions(Permission.InvoiceCreate)) {
        items.push({
          key: DidNotArriveLabel.NewInvoice,
          text: invoiceId
            ? DidNotArriveLabel.Invoice
            : DidNotArriveLabel.NewInvoice,
          iconProps: invoiceStatus
            ? { iconName: invoiceStatusIcon[invoiceStatus] }
            : undefined,
          onClick: () => {
            if (!invoiceId) {
              openNewInvoiceFromCalendar(root, calendarEvent.id, patientId);
            } else {
              routing.push(
                routes.accounts.invoices.invoice.path({ id: invoiceId }),
                routing.getStateWithFromQuery()
              );
            }
          }
        });
      }

      if (core.hasPermissions(Permission.PaymentCreate)) {
        items.push({
          key: DidNotArriveLabel.NewPayment,
          text: DidNotArriveLabel.NewPayment,
          onClick: () => {
            routing.pushWithFromQuery({
              pathname: routes.accounts.allocations.new.pattern,
              search: stringify({ accountId: patientId })
            });
          }
        });
      }

      items.push(
        {
          key: DidNotArriveLabel.Reschedule,
          text: item.calendarEvent.rescheduledAppointmentId
            ? DidNotArriveLabel.Rescheduled
            : DidNotArriveLabel.Reschedule,
          disabled:
            !!item.calendarEvent.rescheduledAppointmentId ||
            !!item.calendarEvent.manuallyRescheduledBy,
          onClick: () =>
            booking.ui.onRebookOrRescheduleButtonClick(item.calendarEvent, 0, {
              isRescheduledFromIncompleteList: true
            })
        },
        {
          key: "markAsRescheduled",
          text: item.calendarEvent.manuallyRescheduledBy
            ? DidNotArriveLabel.markedAsRescheduled
            : DidNotArriveLabel.markAsRescheduled,
          disabled:
            !!item.calendarEvent.rescheduledAppointmentId &&
            !item.calendarEvent.manuallyRescheduledBy,
          onClick: () => onMarkAsRescheduled(item)
        }
      );

      return items;
    };

    return { getActionItems };
  };

  const { getActionItems } = getDidNotArriveActions();

  return (
    <Stack horizontalAlign="center" verticalAlign="center">
      <IconButton
        iconProps={{ iconName: "More" }}
        onRenderMenuIcon={() => null}
        menuProps={{ items: getActionItems(item) }}
        styles={{
          root: { width: "32px", height: "36px", padding: 0 },
          flexContainer: { width: "32px", height: "36px" }
        }}
      />
    </Stack>
  );
});
