import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";

import {
  ActionButton,
  FontIcon,
  FontSizes,
  GroupedList,
  Heading,
  IGroupHeaderProps,
  IRenderFunction,
  NoDataTile,
  Spinner,
  Stack,
  TooltipHost,
  useTheme
} from "@bps/fluent-ui";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  ConditionDetailLabels,
  ConditionModalFormValues
} from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/condition-modal/Condition.types.ts";
import { ConditionModal } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/condition-modal/ConditionModal.tsx";
import { ConditionContext } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/ConditionContext.ts";
import { ConditionHelper } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/ConditionHelper.ts";
import { usePatientRecordScreenContext } from "@modules/clinical/screens/context/PatientRecordScreenContext.ts";
import { ClinicalRecord } from "@stores/clinical/models/ClinicalRecord.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import {
  DataFetcher,
  withFetch
} from "@ui-components/data-fetcher/DataFetcher.tsx";

import { SideNavContent } from "../SideNavContent.tsx";
import { ClaimAppointmentContext } from "./ClaimAppointmentContext.tsx";
import { ConditionConsultsExpandedView } from "./ConditionConsultsExpandedView.tsx";
import { ConditionDetails } from "./ConditionDetails.tsx";
import { ConditionListItem } from "./ConditionListItem.tsx";
import { getConditionsSidePanelStyles } from "./ConditionsSidePanel.styles.ts";
import {
  ConditionDetailsModel,
  ConditionsSidePanelHelper
} from "./ConditionsSidePanelHelper.ts";

export interface ConditionsSidePanelProps {
  clinicalRecord: ClinicalRecord;
}

export const ConditionsSidePanelBase: React.FunctionComponent<ConditionsSidePanelProps> =
  observer(({ clinicalRecord }) => {
    const theme = useTheme();
    const root = useStores();
    const patientRecordScreenHelper = usePatientRecordScreenContext();

    const setTabs = async () => {
      await patientRecordScreenHelper.setInjuryTab();
    };

    const helper = useRef(
      new ConditionsSidePanelHelper(clinicalRecord, root, setTabs)
    );

    const styles = getConditionsSidePanelStyles();

    const [selectedCondition, setSelectedCondition] = useState<
      ConditionDetailsModel | undefined
    >();

    const [showViewAllConsults, setShowViewAllConsults] =
      useState<boolean>(false);

    useEffect(() => {
      const condition = clinicalRecord.getConditionByEocId(
        root.clinical.ui.tabs.currentPatientRecordTab?.sidePanelTabId
      );

      if (condition) {
        setSelectedCondition(helper.current.toConditionDetailsModel(condition));
      }
    }, [
      root.clinical.ui.tabs.currentPatientRecordTab?.sidePanelTabId,
      root.clinical.ui.tabs.currentPatientRecordTab?.sidePanelTab,
      clinicalRecord.getConditionByEocId,
      clinicalRecord
    ]);

    const disableNewCondition = helper.current.hasLinkedCondition;

    const conditionHelper = useRef<ConditionHelper>(
      new ConditionHelper(root, clinicalRecord.id, {
        onSubmit: async (
          values: ConditionModalFormValues,
          helper: ConditionHelper
        ) => {
          await helper.createCondition(
            values,
            root.core.user?.id,
            clinicalRecord?.medicalHistories
          );
        },
        onSubmitSucceeded: () => {
          clinicalRecord.loadConditions();
        },
        linkConditionToEncounter: disableNewCondition
          ? undefined
          : helper.current.linkConditionToEncounter
      })
    );

    const handleSelectedCondition = (
      condition: ConditionDetailsModel | undefined
    ) => {
      setSelectedCondition(condition);
    };

    const renderConditionItem = (
      depth: number | undefined,
      condition: ConditionDetailsModel,
      index: number | undefined
    ): JSX.Element => {
      if (condition.emptyMessage) {
        return (
          <Stack>
            <NoDataTile
              styles={{
                root: { backgroundColor: theme.palette.neutralLighterAlt }
              }}
              textProps={{ text: condition.emptyMessage }}
              linkProps={
                index === 0 && !disableNewCondition
                  ? {
                      text: "New condition",
                      onClick: () =>
                        conditionHelper.current.setHiddenConditionModal(false)
                    }
                  : { hidden: true }
              }
              showBoxShadow={false}
            />
          </Stack>
        );
      }

      return (
        <ConditionListItem
          key={condition.condition?.episodeOfCareId}
          model={condition}
          onSelectedCondition={handleSelectedCondition}
          isViewOnly={patientRecordScreenHelper.isViewOnlyOrDischarged}
        />
      );
    };

    const renderHeader: IRenderFunction<IGroupHeaderProps> = (
      props,
      defaultRender
    ) => {
      if (!props || !defaultRender) {
        return null;
      }
      return defaultRender!({
        ...props,
        styles: styles.headingRender,
        onGroupHeaderClick: () => props.onToggleCollapse!(props.group!)
      });
    };

    const onConsultViewAllClicked = () => {
      setShowViewAllConsults(true);
    };

    const onConsultViewAllClosed = () => {
      setShowViewAllConsults(false);
    };

    const dischargeStatus = clinicalRecord.getDischargeStatus(
      clinicalRecord.openEncounter?.businessRole
    );

    const { isViewOnlyOrDischarged } = usePatientRecordScreenContext();

    return (
      <ClaimAppointmentContext.Provider value={helper.current}>
        {selectedCondition && showViewAllConsults && (
          <ConditionConsultsExpandedView
            onClosed={() => onConsultViewAllClosed()}
            model={selectedCondition}
          />
        )}

        {selectedCondition && !showViewAllConsults && (
          <ConditionDetails
            model={selectedCondition}
            handleSelectedCondition={handleSelectedCondition}
            onEditConditionSucceeded={async condition => {
              await clinicalRecord.loadConditions();
              const updatedCondition = clinicalRecord.getConditionByEocId(
                condition.episodeOfCareId
              );

              if (updatedCondition) {
                setSelectedCondition(
                  helper.current.toConditionDetailsModel(updatedCondition)
                );
              }
            }}
            onClose={() => {
              if (root.clinical.ui.tabs.currentPatientRecordTab)
                root.clinical.ui.tabs.currentPatientRecordTab.sidePanelTabId =
                  undefined;
            }}
            onConsultViewAllClicked={() => onConsultViewAllClicked()}
          />
        )}

        {!selectedCondition && (
          <>
            <Stack horizontal horizontalAlign="space-between">
              <Heading variant="section-heading-light" styles={styles.heading}>
                Conditions
              </Heading>
              <div>
                {!isViewOnlyOrDischarged && (
                  <ActionButton
                    disabled={disableNewCondition}
                    text={ConditionDetailLabels.New}
                    iconProps={{
                      iconName: "Add"
                    }}
                    styles={{
                      root: {
                        paddingRight: 0
                      }
                    }}
                    onClick={() => {
                      conditionHelper.current.setHiddenConditionModal(false);
                    }}
                  />
                )}
                {disableNewCondition && (
                  <TooltipHost
                    content={
                      helper.current.hasLinkedCondition
                        ? "You must unlink the current condition to do this"
                        : "You must be in an appointment to do this"
                    }
                  >
                    <FontIcon
                      iconName="Info"
                      styles={{
                        root: {
                          fontSize: FontSizes.size12,
                          cursor: "pointer"
                        }
                      }}
                    />
                  </TooltipHost>
                )}
              </div>
            </Stack>
            <SideNavContent>
              <DataFetcher
                fetch={async () => {
                  await clinicalRecord.loadConditions();
                }}
                fallback={<Spinner />}
                refetchId={`${clinicalRecord.clinicalData?.reasonForVisit?.eTag} 
                - ${conditionHelper.current.condition?.episodeOfCareId}`}
              >
                {() => {
                  return (
                    <GroupedList
                      styles={styles.groupList}
                      compact={true}
                      {...helper.current.getList(
                        clinicalRecord.conditions,
                        root.core.hasPermissions([
                          Permission.ClaimRead,
                          Permission.OnHoldClaimAllowed
                        ]),
                        dischargeStatus
                      )}
                      onRenderCell={renderConditionItem}
                      groupProps={{
                        onRenderHeader: renderHeader
                      }}
                    />
                  );
                }}
              </DataFetcher>
            </SideNavContent>
            <ConditionContext.Provider value={conditionHelper.current}>
              <ConditionModal />
            </ConditionContext.Provider>
          </>
        )}
      </ClaimAppointmentContext.Provider>
    );
  });

export const ConditionsSidePanel = withFetch(
  x => [
    x.acc.ref.claimStatuses.load(),
    x.practice.ref.accProviderContractTypes.load(),
    x.userExperience.ref.businessRoleAbbreviations.load()
  ],
  ConditionsSidePanelBase
);
