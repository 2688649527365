import React, { ReactNode } from "react";
import { Item as UiCompItem } from "ui-components/filter-bar/FilterBar.types.ts";

import { getTheme } from "@bps/fluent-ui";
import { ActivityDescriptionDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import {
  BusinessRoleClasses,
  UserStatus
} from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ClinicalActivityStatus } from "@shared-types/clinical/clinical-activity-status.type.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { withFetch } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { FilterBar } from "@ui-components/filter-bar/FilterBar.tsx";

import { ClinicalActivityListFilter } from "../clinical-activity/ClinicalActivityList.tsx";
import { ClinicalActivityType } from "../clinical-activity/types/clinical-activity.type.ts";

export interface ClinicalReminderManagementFilterProps {
  children: ReactNode;
  hidden?: boolean;
  isFollowUp?: boolean;
  isShort?: boolean;
}

export interface ClinicalReminderManagementFilterValues
  extends ClinicalActivityListFilter {
  providerIds?: string[];
  statuses?: string[];
  reminderCommStatuses?: string[];
  reasons?: string[];
  reasonSearch?: string;
  remainingVisits?: string;
  startDateTime?: Date;
  endDateTime?: Date;
  patientIds?: string[];
  name?: string;
  clinicallySignificant?: boolean;
  urgent?: boolean;
  activePatients?: boolean;
  sentStartDateTime?: Date;
  sentEndDateTime?: Date;
}

export const ClinicalReminderManagementFilterBase: React.FC<
  ClinicalReminderManagementFilterProps
> = ({ children, hidden, isFollowUp, isShort }) => {
  const { clinical } = useStores();

  const getReminderReasons = () => {
    const values = clinical.activityDescriptionMapValues
      .filter(x => x.activityType === ClinicalActivityType.Reminder)
      .sort((a: ActivityDescriptionDto, b: ActivityDescriptionDto) => {
        if (!a.isInactive && b.isInactive) {
          return -1;
        } else if (a.isInactive && !b.isInactive) {
          return 1;
        } else {
          return 0;
        }
      })
      .map(({ id, reasonText }) => ({ key: id, text: reasonText }));
    return values;
  };

  const theme = getTheme();

  const items: UiCompItem<ClinicalReminderManagementFilterValues>[] = [
    {
      type: "optionsSelect",
      name: "reasons",
      stickItem: true,
      props: {
        id: "event-reminder-list-filter-reasons",
        placeholder: "Select reason(s)",
        options: getReminderReasons(),
        multiSelect: true,
        calloutWidth: 300,
        styles: { root: { width: "100%" } }
      }
    }
  ];

  const providerIds: UiCompItem<ClinicalReminderManagementFilterValues> = {
    type: "bookableUsersSelect",
    name: "providerIds",
    props: {
      id: "event-reminder-list-filter-providers",
      multiSelect: true,
      usersFilter: {
        showOnCalendar: true,
        statusCodes: [UserStatus.Active],
        businessRoleClasses: [BusinessRoleClasses.Provider]
      },
      placeholder: "Providers",
      calloutWidth: 250
    }
  };

  // Rearrange if it is a followup.

  if (isFollowUp) {
    items.push({
      type: "optionsSelect",
      name: "reminderCommStatuses",
      props: {
        id: "status-dropdown",
        placeholder: "Status",
        options: clinical.ref.reminderStatuses.keyTextValues.filter(
          x => x.key !== "NEW" && x.key !== "FAILED"
        ),
        multiSelect: true,
        hideSearchOption: true,
        calloutWidth: 300,
        styles: { root: { width: "100%" } }
      }
    });
    items.push(providerIds);

    items.push({
      type: "datesRangePicker",
      name: "sentStartDateTime",
      props: {
        id: "event-reminder-list-filter-sent",
        startDateName: "sentStartDateTime",
        endDateName: "sentEndDateTime",
        placeholder: "Sent"
      }
    });

    items.push({
      type: "dateTimeFramePicker",
      name: "dueDateStartDate",
      props: {
        id: "event-reminder-list-filter-due",
        startDateName: "dueDateStartDate",
        endDateName: "dueDateEndDate",
        placeholder: "Due",
        incrementName: "dueInVisits",
        incrementProps: {
          incrementTitle: "Consult count",
          suffix: "consults",
          tooltipText: "From the start date",
          label: "In a span of"
        }
      }
    });
  }

  if (!isFollowUp) {
    items.push(providerIds);

    items.push({
      type: "dateTimeFramePicker",
      name: "dueDateStartDate",
      props: {
        id: "event-reminder-list-filter-due",
        startDateName: "dueDateStartDate",
        endDateName: "dueDateEndDate",
        placeholder: "Due",
        incrementName: "dueInVisits",
        incrementProps: {
          incrementTitle: "Consult count",
          suffix: "consults",
          tooltipText: "From the start date",
          label: "In a span of"
        }
      }
    });
  }

  items.push({
    type: "patientsSelect",
    name: "patientIds",
    props: {
      id: "event-reminder-list-filter-patients",
      calloutWidth: 230,
      placeholder: "Patients",
      multiSelect: true
    }
  });

  return (
    <FilterBar<ClinicalReminderManagementFilterValues>
      initialValues={{
        reminderCommStatuses: clinical.ref.reminderStatuses.keyTextValues
          .filter(x => x.key !== ClinicalActivityStatus.Completed)
          .map(x => x.key),
        activePatients: isFollowUp
      }}
      hidden={hidden}
      items={items}
      shrinkVersion={isShort}
      presets={[
        isFollowUp
          ? {
              text: "Active patients",
              name: "activePatients",
              iconName: "CheckMark",
              iconColor: theme.palette.themePrimary,
              tooltipPresses:
                "Showing active patients only. Unpress to show all."
            }
          : {
              text: "Urgent",
              name: "urgent",
              iconName: "BPApptFailed16",
              iconColor: theme.palette.yellowDark,
              tooltip: "Reminders due in less than 2 weeks / 1 consult"
            },
        {
          text: "Clinically significant",
          name: "clinicallySignificant",
          iconName: "UserOptional",
          tooltip: "Clinically significant reminders only",
          tooltipPresses: "Show clinically significant entries only"
        }
      ]}
    >
      {() => children}
    </FilterBar>
  );
};

export const ClinicalReminderManagementFilter = withFetch(
  x => [x.clinical.ref.reminderStatuses.load()],
  ClinicalReminderManagementFilterBase
);
