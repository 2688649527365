import { FormApi } from "final-form";
import debounce from "lodash.debounce";
import { action, observable, runInAction } from "mobx";
import { FULFILLED, PromiseState, REJECTED } from "mobx-utils";
import { BhacStore } from "stores/bhac/BhacStore.ts";

import { confirm, Link, Stack, Text } from "@bps/fluent-ui";
import {
  compareDatesPredicate,
  DateSettings,
  DateTime,
  groupBy,
  TIME_FORMATS
} from "@bps/utils";
import { ClaimLinkedToAppointment } from "@libs/analytics/app-insights/app-insights.enums.ts";
import {
  AddRecurrenceDto,
  AppointmentStatusCode,
  AttendeeTypeEnum,
  CalendarEventAttendeeDto,
  CalendarEventPriority,
  CalendarEventStatus,
  CalendarEventType,
  Frequency,
  RecurrenceLinkingActionType
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import {
  OutboundCommChannel,
  OutboundCommType
} from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { FormTemplateTypeCode } from "@libs/gateways/forms/FormsGateway.dtos.ts";
import { CommunicationType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { toMonthYearRecurrence } from "@libs/utils/calendar/calendar.utils.ts";
import { ContextMenuItemsEnum } from "@modules/booking/screens/booking-calendar/components/booking-calendar-event/contextual-menu/ContextMenuItemsEnum.ts";
import { CancelAppointmentFormValues } from "@modules/booking/screens/booking-calendar/components/cancel-calendar-event-dialog/components/CancelAppointmentForm.types.ts";
import { KNOWLEDGEBASE_INFO_LINK } from "@modules/practice/screens/address-book/components/patient-merge/PatientMergeFieldValues.ts";
import { PatientCardIds } from "@modules/practice/screens/shared-components/types/patient-card-ids.enum.ts";
import { AppointmentFormValues } from "@shared-types/booking/appointment-form-values.types.ts";
import { EndScheduleType } from "@shared-types/booking/end-schedule.constant.ts";
import { SecondColumnContent } from "@shared-types/booking/second-column-content.enum.ts";
import { UsersTimeRanges } from "@shared-types/booking/users-time-ranges.type.ts";
import { ICondition } from "@shared-types/clinical/condition.interface.ts";
import { SystemNotice } from "@shared-types/practice/system-notice.interface.ts";
import type { IRootStore } from "@shared-types/root/root-store.interface.ts";
import { AccStore } from "@stores/acc/AccStore.ts";
import { BookingStore } from "@stores/booking/BookingStore.ts";
import { AppointmentType } from "@stores/booking/models/AppointmentType.ts";
import { AvailabilitySlots } from "@stores/booking/models/AvailabilitySlots.ts";
import {
  CalendarEvent,
  toAttendees
} from "@stores/booking/models/CalendarEvent.ts";
import { RecurrenceModel } from "@stores/booking/models/RecurrenceModel.ts";
import { TimeRange } from "@stores/booking/models/TimeRanges.ts";
import { WaitingListItemModel } from "@stores/booking/models/WaitingListModel.ts";
import type { CommsStore } from "@stores/comms/CommsStore.ts";
import { CoreStore } from "@stores/core/CoreStore.ts";
import { FormsStore } from "@stores/forms/FormsStore.ts";
import { PatientNotice } from "@stores/practice/models/PatientNotice.ts";
import { PracticeStore } from "@stores/practice/PracticeStore.ts";
import { findCommValue } from "@stores/practice/utils/practice.utils.ts";
import { UserExperienceStore } from "@stores/user-experience/UserExperienceStore.ts";

import {
  getCount,
  getSeriesOccurrences,
  isAppointmentFormEditable
} from "../../../../utils.tsx";
import {
  appointmentFormNameOf,
  GroupApptSlot,
  GroupApptSlots
} from "../AppointmentForm.types.ts";
import { ConditionAppointmentFormHelper } from "../condition-modal/ConditionAppointmentFormHelper.ts";
import { confirmMulti } from "../dialog/confirmMulti.tsx";
import {
  formatTimeSpanWithDate,
  getLinkedOccurrenceCount,
  getTimeSpan,
  recurrenceRuleDto
} from "../utils.ts";

interface ProviderTimeSlotsData {
  providerId: string;
  status: PromiseState;
  slots?: AvailabilitySlots;
  // for sorting and ordering only
  dateTimeSlots?: string[];
  date?: string;
}

export type SettledProvidersResult = PromiseSettledResult<AvailabilitySlots> & {
  providerId: string;
};

const sortByAvailableTime = (
  a: ProviderTimeSlotsData,
  b: ProviderTimeSlotsData
) => {
  if (
    a.dateTimeSlots &&
    b.dateTimeSlots &&
    a.dateTimeSlots[0] &&
    b.dateTimeSlots[0]
  ) {
    if (a.dateTimeSlots[0] < b.dateTimeSlots[0]) return -1;
    if (a.dateTimeSlots[0] > b.dateTimeSlots[0]) return 1;
    return 0;
  }
  return 0;
};

const sortByAvailableDateAndTime = (
  a: ProviderTimeSlotsData,
  b: ProviderTimeSlotsData
) => {
  if (a.date && b.date) {
    if (a.date < b.date) {
      return -1;
    } else if (a.date > b.date) {
      return 1;
    } else {
      return sortByAvailableTime(a, b);
    }
  }
  return 0;
};

export class AppointmentFormHelper {
  constructor(
    private root: IRootStore,
    private _appointmentType: AppointmentType,
    _condition?: ICondition | undefined
  ) {
    this.conditionAppointmentFormHelper = new ConditionAppointmentFormHelper(
      root,
      _condition
    );

    if (this.booking.ui.currentAppointment?.initialValues?.providerId) {
      runInAction(() => {
        this.selectedProviders.push(
          this.booking.ui.currentAppointment?.initialValues?.providerId!
        );
      });
    }

    this.initialValues = this.getInitialValues();
  }

  public conditionAppointmentFormHelper: ConditionAppointmentFormHelper;

  get booking(): BookingStore {
    return this.root.booking;
  }

  private get routing() {
    return this.root.routing;
  }

  get practice(): PracticeStore {
    return this.root.practice;
  }

  get core(): CoreStore {
    return this.booking.core;
  }

  get bhac(): BhacStore {
    return this.root.bhac;
  }

  get userExperience(): UserExperienceStore {
    return this.root.userExperience;
  }

  private get forms(): FormsStore {
    return this.root.forms;
  }

  get acc(): AccStore {
    return this.root.acc;
  }

  get patientLabel(): string {
    return this.userExperience.localisedConfig("patientDisplay");
  }

  private get comms(): CommsStore {
    return this.root.comms;
  }

  private get appointmentType() {
    return this._appointmentType;
  }

  private get calendarEventId(): string | undefined {
    return this.booking.ui.currentAppointment?.id;
  }

  public get condition(): ICondition | undefined {
    return this.conditionAppointmentFormHelper?.condition;
  }

  public get isAppointmentValidForEdit(): boolean {
    const isRecurringAppointment =
      this.calendarEvent?.calendarEventRecurrenceId !== undefined;

    return isRecurringAppointment || this.calendarEvent
      ? isAppointmentFormEditable(
          this.calendarEvent?.startDateTime,
          this.calendarEvent?.appointmentStatus
        )
      : false;
  }

  recurrenceOccurred: number | undefined = undefined;

  public initialValues: Partial<AppointmentFormValues> | undefined;

  isMatched: boolean | undefined =
    this.calendarEvent?.externalPatient &&
    this.calendarEvent.externalPatient.matchedId ===
      this.calendarEvent?.patientId;

  @observable
  isAppointmentWithinUserWorkTime: boolean = true;

  @observable
  selectedProviders: string[] = [];

  @observable
  selectedGroupAttendeeId: string;

  @observable
  isAllProvidersSelected: boolean = false;

  createdConditions: ICondition[] = [];

  @observable
  isGroupAppointment: boolean = this.calendarEvent?.isGroupAppointment ?? false;

  @observable
  linkedAppointmentsCount: number = 0;

  @observable
  attendeeCancellationDialog: boolean = false;

  @observable
  showWaitingListDialog: boolean = false;

  @observable
  conditionButtonClicked: boolean = false;

  @action
  setSelectedGroupAttendeeId = (id: string) => {
    this.selectedGroupAttendeeId = id;
  };

  @action
  setShowWaitingListDialog = (value: boolean) => {
    this.showWaitingListDialog = value;
  };

  @action
  setAttendeeCancellationDialog = (value: boolean) => {
    this.attendeeCancellationDialog = value;
  };

  @action
  setLinkedAppointmentsNumber = (value: number = 0) => {
    this.linkedAppointmentsCount = value;
  };

  @action
  setIsGroupAppointment = (value: boolean) => {
    this.isGroupAppointment = value;
  };

  @action
  setConditionButtonClicked = (value: boolean) => {
    this.conditionButtonClicked = value;
  };

  setCreatedConditions = (newCondition: ICondition) => {
    this.createdConditions.push(newCondition);
  };

  clearCreatedConditions = () => {
    this.createdConditions = [];
  };

  setEpisodeOfCareId = (episodeOfCareId: string | undefined) => {
    this.conditionAppointmentFormHelper?.setEpisodeOfCareId(episodeOfCareId);
  };

  @action
  setProviders = (values: string[]) => {
    this.selectedProviders = values.map(item => {
      const provider = this.selectedProviders.find(i => i === item);
      if (provider) {
        return provider;
      }
      return item;
    });
  };

  @action
  setPatientNoticesColumn = (
    patientNotices: PatientNotice[],
    systemNotices: SystemNotice[]
  ) => {
    const adminNotices = patientNotices
      ? patientNotices.filter(n => n.isAdmin)
      : [];

    const noticeCount = systemNotices.length + adminNotices.length;
    this.booking.ui.setSecondColumnContent(
      noticeCount > 0 ? SecondColumnContent.patientNotices : undefined
    );
  };

  @action
  showPatientNoticesColumn = async (patientId: string) => {
    const [patientNotices, systemNotices] = await Promise.all([
      this.practice.getPatientNotices(patientId),
      this.practice.loadSystemNotices([patientId])
    ]);

    const systemNoticesForPatient = systemNotices.filter(
      sn => sn.patientId === patientId
    );
    this.setPatientNoticesColumn(patientNotices, systemNoticesForPatient);
  };

  public sortSelectedProviders = (
    selectedProviders: SettledProvidersResult[],
    startDate: Date | undefined
  ): SettledProvidersResult[] => {
    if (!startDate) return [];

    /*
    When we sort the selected providers there are few scenarios we need to address.
      1. Providers who have available times on the selected date. These providers will be sorted by available time in ascending time.
      2. Providers who don't have any available time on the date but there are available times in future dates. These providers need to be sorted
        first by the date and then time in ascending order
      3. Providers who don't have any available times in the future. These providers need to be at the end of the list.
    */
    const providersOnSelectedData: ProviderTimeSlotsData[] = [];
    const providersOnFutureData: ProviderTimeSlotsData[] = [];
    const providersWithNoAvailability: ProviderTimeSlotsData[] = [];
    const erroredSlots: ProviderTimeSlotsData[] = [];

    selectedProviders.forEach((item: SettledProvidersResult) => {
      if (item.status === REJECTED) {
        return erroredSlots.push({
          status: item.status,
          providerId: item.providerId
        });
      }

      // prepare list of providers with available times for the selected date
      const selectedDateSlots = item.value.dto.filter(
        i =>
          DateTime.fromISO(i.date).toMillis() ===
          DateTime.fromJSDate(startDate).startOf("day").toMillis()
      )[0]?.availableTimes;

      if (selectedDateSlots) {
        return providersOnSelectedData.push({
          providerId: item.providerId,
          dateTimeSlots: selectedDateSlots,
          status: FULFILLED,
          slots: item.value
        });
      } else {
        // prepare list of providers with available times in future date
        const nextAvailableDate = item.value.dto.filter(
          i =>
            DateTime.fromISO(i.date) >
            DateTime.fromJSDate(startDate).startOf("day")
        )[0]?.date;

        if (nextAvailableDate) {
          const timeSlots = item.value.dto.filter(
            i =>
              DateTime.fromISO(i.date).toMillis() ===
              DateTime.fromISO(nextAvailableDate).toMillis()
          )[0]?.availableTimes;

          return providersOnFutureData.push({
            providerId: item.providerId,
            date: nextAvailableDate,
            dateTimeSlots: timeSlots,
            status: FULFILLED,
            slots: item.value
          });
        } else {
          // prepare list of providers with no available times
          return providersWithNoAvailability.push({
            providerId: item.providerId,
            dateTimeSlots: [],
            status: FULFILLED,
            slots: item.value
          });
        }
      }
    });
    // merge the sorted lists of providers in order
    providersOnSelectedData.sort(sortByAvailableTime);
    providersOnFutureData
      .sort(sortByAvailableDateAndTime)
      .push(...providersWithNoAvailability);
    providersOnSelectedData.push(...providersOnFutureData);
    providersOnSelectedData.push(...erroredSlots);

    return this.orderSortedProviders(
      providersOnSelectedData,
      selectedProviders
    );
  };

  private orderSortedProviders(
    providersSelectedData: ProviderTimeSlotsData[],
    selectedProviders: SettledProvidersResult[]
  ): SettledProvidersResult[] {
    const sortedProviderList: SettledProvidersResult[] = [];
    providersSelectedData.forEach(item => {
      const providerInfo = selectedProviders.find(
        i => i.providerId === item.providerId
      );
      if (providerInfo) {
        sortedProviderList.push(providerInfo);
      }
    });
    return sortedProviderList;
  }

  public fetchAvailableSlots = async (
    startDate: Date,
    duration: number,
    orgUnitIds: string[]
  ) => {
    const res = await Promise.allSettled(
      this.selectedProviders.map(userId =>
        this.booking.getUserAvailabilitySlot({
          userId,
          orgUnitIds,
          startDate: DateTime.fromJSDate(startDate).startOf("day"),
          endDate: DateTime.fromJSDate(startDate)
            .startOf("day")
            .plus({ months: 3 })
            .endOf("month"),
          duration: getTimeSpan(Number(duration))
        })
      )
    );

    const result = res.map((item, index) => ({
      ...item,
      providerId: this.selectedProviders[index]
    }));
    return result;
  };

  @action
  setAllProvidersSelected = (value: boolean) => {
    this.isAllProvidersSelected = value;
  };

  get calendarEvent(): CalendarEvent | undefined {
    if (!this.calendarEventId) return undefined;
    return this.booking.calendarEventsMap.get(this.calendarEventId);
  }

  get calendarEvents(): CalendarEvent[] | undefined {
    if (!this.calendarEventRecurrence) return undefined;
    return Array.from(this.booking.calendarEventsMap.values()).filter(
      x => x.calendarEventRecurrenceId === this.calendarEventRecurrence!.id
    );
  }

  get calendarEventRecurrence(): RecurrenceModel | undefined {
    return (
      this.calendarEvent &&
      this.booking.recurrenceMap.get(
        this.calendarEvent.calendarEventRecurrenceId!
      )
    );
  }

  private get totalRecurrencesCount(): number | undefined {
    return (
      this.calendarEvent &&
      this.recurrenceSeries &&
      this.calendarEventRecurrence &&
      getSeriesOccurrences({
        recurrenceSeries: this.recurrenceSeries
      })?.length
    );
  }

  private get recurrenceSeries(): RecurrenceModel[] | undefined {
    return this.calendarEventRecurrence && this.calendarEventRecurrence.seriesId
      ? this.booking.getRecurrencesForSeries(
          this.calendarEventRecurrence.seriesId
        )
      : undefined;
  }

  private get recurrenceOccurredCount(): number | undefined {
    const occurredOccurrences = getSeriesOccurrences({
      recurrenceSeries: this.recurrenceSeries ? this.recurrenceSeries : [],
      defaultEndDate: this.calendarEvent && this.calendarEvent.startDateTime
    })?.length;

    return (
      this.calendarEvent &&
      this.recurrenceSeries &&
      this.calendarEventRecurrence &&
      occurredOccurrences
    );
  }

  private getEditAppointmentInitialValues(
    calendarEvent: CalendarEvent
  ): AppointmentFormValues {
    const lastRecurrenceSeries =
      this.recurrenceSeries &&
      this.recurrenceSeries.reduce((a, b) => {
        return a.createdDate &&
          b.createdDate &&
          DateTime.fromISO(a.createdDate) > DateTime.fromISO(b.createdDate)
          ? a
          : b;
      });

    const startDate = calendarEvent.startDateTime.startOf("day").toJSDate();

    const startTime = calendarEvent.startDateTime.toTimeInputFormat();

    const providerId =
      calendarEvent.userId ??
      this.booking.ui.currentAppointment?.initialValues?.providerId;

    // set provider for next available fields
    this.setProviders([providerId]);

    const appointmentFormValues: AppointmentFormValues = {
      episodeOfCareId: calendarEvent.reason?.episodeOfCareId ?? undefined,
      patientId: calendarEvent.contactId ?? "",
      appointmentTypeId: calendarEvent.appointmentTypeId,
      providerId,
      startDate,
      orgUnitId: calendarEvent.orgUnitId,
      startTime,
      comments: calendarEvent.content,
      duration: calendarEvent.duration.minutes,
      urgent: calendarEvent.priority === CalendarEventPriority.Urgent,
      bookedBy: calendarEvent.bookedBy,
      repeat: !!this.calendarEventRecurrence,
      priority: 3,
      anyProvider: false,
      anyLocation: false,
      expiryDate:
        calendarEvent.startDateTime.startOf("day").toJSDate() ??
        this.booking.ui.currentAppointment?.initialValues?.startDate,
      maxParticipants: calendarEvent.maxParticipants,
      groupDescription: calendarEvent.groupDescription,
      groupAttendees: calendarEvent.attendeesPatientAndContact.slice(),
      groupAttendeeIds: [],
      ...this.booking.ui.currentAppointment?.initialValues
    };

    if (calendarEvent.calendarEventRecurrenceId) {
      const calendarEventRecurrence = this.booking.recurrenceMap.get(
        calendarEvent.calendarEventRecurrenceId
      );

      if (this.condition) {
        const linked = getLinkedOccurrenceCount(this.calendarEvents);
        this.setLinkedAppointmentsNumber(linked);
        if (linked === this.totalRecurrencesCount) {
          appointmentFormValues.linkingAction =
            RecurrenceLinkingActionType.LinkAll;
        } else {
          appointmentFormValues.linkingAction =
            RecurrenceLinkingActionType.LinkFirst;
        }
      }

      if (calendarEventRecurrence && lastRecurrenceSeries) {
        let endScheduleType: EndScheduleType;
        if (lastRecurrenceSeries.endDate !== undefined) {
          endScheduleType = EndScheduleType.OnDate;
        } else if (lastRecurrenceSeries.count === undefined) {
          endScheduleType = EndScheduleType.Never;
        } else {
          endScheduleType = EndScheduleType.After;
        }

        if (endScheduleType === EndScheduleType.After) {
          this.recurrenceOccurred = this.recurrenceOccurredCount;
          appointmentFormValues.count = this.totalRecurrencesCount;
          appointmentFormValues.recurrenceOccurred = this.recurrenceOccurred;
        }

        appointmentFormValues.endScheduleType = endScheduleType;
        appointmentFormValues.frequency = calendarEventRecurrence.frequency;
        appointmentFormValues.interval = calendarEventRecurrence.interval;
        appointmentFormValues.until =
          endScheduleType === EndScheduleType.OnDate
            ? DateTime.fromISO(lastRecurrenceSeries.until)?.toJSDate()
            : undefined;

        appointmentFormValues.dayRecur =
          calendarEventRecurrence.frequency === Frequency.Week
            ? calendarEventRecurrence.dayRecur || []
            : undefined;
        appointmentFormValues.monthYearRecurrence =
          calendarEventRecurrence.frequency !== Frequency.Week
            ? toMonthYearRecurrence(
                calendarEventRecurrence.weekPosition,
                calendarEventRecurrence.monthDayRecur
              )
            : undefined;
      }
    }

    const waitingListFieldValues = Array.from(
      this.booking.waitingListsMap.values()
    ).find(entries => entries.calenderEventId === this.calendarEvent?.id);

    if (waitingListFieldValues) {
      const waitingListItems = waitingListFieldValues;
      const waitingListId = waitingListFieldValues.id;
      appointmentFormValues.waitingFieldToggle = true;
      appointmentFormValues.priority = waitingListItems?.priority;
      const anyProvider = !!waitingListItems?.anyProvider
        ? waitingListItems.anyProvider
        : false;

      appointmentFormValues.expiryDate = DateTime.jsDateFromISO(
        waitingListItems?.until
      );
      appointmentFormValues.anyProvider = anyProvider;
      appointmentFormValues.waitingListIds = [waitingListId];
    }

    return appointmentFormValues;
  }

  private getNewAppointmentInitialValues(): Partial<AppointmentFormValues> {
    const startDate = DateTime.today().toJSDate();

    let claimAppointmentInitialValues: AppointmentFormValues | undefined;
    if (this.conditionAppointmentFormHelper) {
      claimAppointmentInitialValues =
        this.conditionAppointmentFormHelper.getEpisodeOfCareInitialValues() as AppointmentFormValues;
    }

    return {
      ...claimAppointmentInitialValues,
      urgent: false,
      bookedBy: this.core.userId,
      duration: this.appointmentType && this.appointmentType.duration,
      appointmentTypeId: this.appointmentType && this.appointmentType.id,
      startDate,
      repeat: false,
      endScheduleType: EndScheduleType.After,
      frequency: Frequency.Week,
      interval: 1,
      anyProvider: false,
      orgUnitId: this.core.hasMultipleActiveLocations
        ? undefined
        : this.core.locationId,
      priority: 3,
      expiryDate: startDate,
      waitingFieldToggle: false,
      providerId:
        claimAppointmentInitialValues?.providerId ||
        this.booking.ui.currentAppointment?.initialValues?.providerId,
      selectedCount: 0,
      linkingAction: this.condition && RecurrenceLinkingActionType.LinkFirst,
      groupAttendees: [],
      groupAttendeeIds: [],
      ...this.booking.ui.currentAppointment?.initialValues
    };
  }

  private getInitialValues(): Partial<AppointmentFormValues> {
    if (this.calendarEvent) {
      return this.getEditAppointmentInitialValues(this.calendarEvent);
    }

    return this.getNewAppointmentInitialValues();
  }

  private getWaitingListRequest(
    values: AppointmentFormValues,
    bookingId: string
  ) {
    const startTime = DateTime.fromJSDateAndTime(
      values.startDate || DateTime.jsDateNow(),
      values.startTime
    );

    let duration: number;
    if (!values.duration) {
      duration = 0;
    } else if (typeof values.duration === "string") {
      duration = Number(values.duration);
    } else {
      duration = values.duration;
    }

    const endTime = startTime.plus({ minutes: duration });

    return {
      startTime: startTime.toISO(),
      endTime: endTime.toISO(),
      until: values.expiryDate
        ? DateTime.fromJSDate(values.expiryDate).toISO()
        : "",
      priority: values.priority!,
      anyProvider: !!values.anyProvider,
      anyLocation: !!values.anyLocation,
      bookedBy: values.bookedBy || this.core.userId,
      content: values.comments,
      attendees: values.patientId
        ? toAttendees({
            contactId: values.patientId,
            userId: values.providerId
          })
        : toAttendees({
            userId: values.providerId
          }),
      appointmentTypeId: values.appointmentTypeId,
      calenderEventId: bookingId,
      duration: values.duration
    };
  }

  async getBaseRequest(values: AppointmentFormValues) {
    const startTime = DateTime.fromJSDateAndTime(
      values.startDate ?? DateTime.jsDateNow(),
      values.startTime
    );

    const provider = await this.root.practice.getProvider(values.providerId);

    let duration: number;
    if (!values.duration) {
      duration = 0;
    } else if (typeof values.duration === "string") {
      duration = Number(values.duration);
    } else {
      duration = values.duration;
    }

    const endTime = startTime.plus({ minutes: duration });
    const reason = values.episodeOfCareId
      ? { episodeOfCareId: values.episodeOfCareId }
      : undefined;

    return {
      startTime: startTime.toISO(),
      endTime: endTime.toISO(),
      priority: values.urgent ? CalendarEventPriority.Urgent : undefined,
      bookedBy: values.bookedBy || this.core.userId,
      content: values.comments,
      attendees: this.getAttendees(values),
      appointmentTypeId: values.appointmentTypeId,
      orgUnitId: values.orgUnitId,
      reason,
      providerContractType: provider.contractTypes?.length
        ? provider.contractTypes[0]
        : undefined,
      maxParticipants: this.isGroupAppointment
        ? values.maxParticipants
        : undefined,
      groupDescription: this.isGroupAppointment
        ? values.groupDescription
        : undefined,
      linkingAction:
        this.initialValues?.linkingAction !== values.linkingAction
          ? values.linkingAction
          : undefined,
      fromRescheduledAppointmentId: values.fromRescheduledAppointmentId,
      externalPatient: this.calendarEvent?.externalPatient
        ? {
            ...this.calendarEvent.externalPatient,
            matchedId: this.isMatched
              ? values.patientId
              : this.calendarEvent.externalPatient.matchedId
          }
        : undefined
    };
  }

  private getAttendees = (values: AppointmentFormValues) => {
    if (this.isGroupAppointment) {
      const attendees: CalendarEventAttendeeDto[] = [
        {
          attendeeId: values.providerId,
          type: AttendeeTypeEnum.user
        }
      ];

      if (values.groupAttendees?.length) {
        attendees.push(
          ...(values.groupAttendees
            ?.filter(attendee => attendee && attendee.attendeeId)
            .map(attendee => ({
              ...attendee,
              status: attendee.status || CalendarEventStatus.Confirmed,
              type: attendee.type || AttendeeTypeEnum.contact,
              attendeeStatus:
                attendee.attendeeStatus || AppointmentStatusCode.Booked
            })) ?? [])
        );
      }

      return attendees;
    }

    return toAttendees({
      contactId: values.patientId,
      userId: values.providerId
    });
  };

  private updateWaitingList = async (
    values: AppointmentFormValues,
    eventId: string | undefined
  ) => {
    // If the add to waiting list toggle on
    //update  details to existing waiting list
    if (values.waitingFieldToggle) {
      if (values.waitingListIds?.length && this.calendarEvent) {
        const addWaitingListRequest = this.getWaitingListRequest(
          values,
          this.calendarEvent.id
        );
        try {
          await this.booking.updateWaitingListItem({
            id: values.waitingListIds[0],
            ...addWaitingListRequest,
            orgUnitId: values.orgUnitId
          });
          this.booking.notification.success("Waiting list item is updated");
        } catch (error) {
          this.booking.notification.error(error.message);
        }
      } else {
        //add patient to waiting list
        const addWaitingListRequest = this.getWaitingListRequest(
          values,
          eventId!
        );
        await this.booking.addWaitingListItem({
          ...addWaitingListRequest,
          orgUnitId: values.orgUnitId
        });
      }
    }
  };

  private getRecurrenceCalendarEvent = async (
    recurrenceCreated: RecurrenceModel
  ) => {
    const calendarEvents = await this.booking.getCalendarEvents({
      calendarEventRecurrenceId: recurrenceCreated.id,
      statuses: [],
      startTime: recurrenceCreated.startDate.toString()
    });

    return calendarEvents.results[0];
  };

  private getCalendarEventRecurrenceRequest = (
    values: AppointmentFormValues
  ): AddRecurrenceDto => {
    const remainderOccurrences =
      values.count &&
      this.recurrenceOccurred &&
      this.recurrenceSeries &&
      values.count -
        (this.recurrenceOccurred > 1 ? this.recurrenceOccurred - 1 : 0);

    const startDateTime = values.startDate
      ? DateTime.fromJSDateAndTime(values.startDate, values.startTime)
      : undefined;

    const endDateTime = values.until
      ? DateTime.fromJSDate(values.until).startOf("day")
      : undefined;

    return {
      bookedBy: this.core.userId,
      type: CalendarEventType.Appointment,
      appointmentTypeId: values.appointmentTypeId,
      attendees: this.getAttendees(values),
      startTime: startDateTime?.toFormat(TIME_FORMATS.TIME_FORMAT_24) ?? "",
      endTime:
        startDateTime
          ?.plus({ minutes: Number(values.duration) })
          ?.toFormat(TIME_FORMATS.TIME_FORMAT_24) ?? "",
      startDate: startDateTime?.startOf("day")?.toISODate() ?? "",
      endDate:
        values.endScheduleType === EndScheduleType.OnDate
          ? values.until && endDateTime?.toISODate()
          : undefined,
      recurrenceRule: recurrenceRuleDto(values, remainderOccurrences),
      purpose: values.comments,
      maxParticipants: this.isGroupAppointment
        ? values.maxParticipants
        : undefined,
      groupDescription: this.isGroupAppointment
        ? values.groupDescription
        : undefined,
      seriesId: this.calendarEventRecurrence
        ? this.calendarEventRecurrence.seriesId
        : undefined,
      orgUnitId: values.orgUnitId,
      linkingArgs: {
        episodeOfCareId: values.episodeOfCareId,
        action: this.condition
          ? values.linkingAction ?? RecurrenceLinkingActionType.LinkFirst
          : RecurrenceLinkingActionType.UnlinkAll
      }
    };
  };

  private createRepeatCalendarEvent = async (values: AppointmentFormValues) => {
    let recurrenceCreated: RecurrenceModel | undefined;
    if (this.calendarEvent) {
      recurrenceCreated = await this.booking.amendRecurrenceSeries(
        this.getCalendarEventRecurrenceRequest(values),
        this.calendarEvent.id
      );
    } else {
      recurrenceCreated = await this.booking.addRecurrence(
        this.getCalendarEventRecurrenceRequest(values)
      );
    }
    return recurrenceCreated;
  };

  private createCalendarEvent = async (values: AppointmentFormValues) => {
    const baseRequest = await this.getBaseRequest(values);
    const calendarEventCreated = await this.booking.addCalendarEvent(
      {
        ...baseRequest,
        reason: { episodeOfCareId: values.episodeOfCareId },
        type: CalendarEventType.Appointment
      },
      this.newEpisodeOfCareId ||
        !!this.isConditionExists(values.episodeOfCareId)
        ? {
            ClaimLinked: !!this.isConditionExists(values.episodeOfCareId)
              ? ClaimLinkedToAppointment.ExistingClaimLinkedToAppointment
              : ClaimLinkedToAppointment.NewClaimLinkedToAppointment
          }
        : undefined
    );
    //if the add to patient from WL has been added, delete the waiting list record
    if (values.waitingListIds?.length) {
      // Removing the original booking from the appointment book.
      if (values.originalWaitingListBookingId) {
        await this.booking.cancelCalendarEvent({
          id: values.originalWaitingListBookingId
        });
      }
    }
    return calendarEventCreated;
  };

  public onSubmit = async (
    form: FormApi<AppointmentFormValues>,
    trackFormEvent: (formName: string, rage: string) => void
  ) => {
    const values = form.getState().values;
    values.count = getCount(values.endScheduleType, values.count);
    values.until =
      values.endScheduleType &&
      values.endScheduleType === EndScheduleType.OnDate
        ? values.until
        : undefined;

    const waitingListRecords: WaitingListItemModel[] = Array.from(
      this.booking.waitingListsMap.values()
    ).filter(entries => {
      if (values.patientId) {
        return entries.attendees.some(x => x.attendeeId === values.patientId);
      } else if (values.groupAttendeeIds?.length) {
        return entries.attendees.some(
          x => values.groupAttendeeIds?.includes(x.attendeeId)
        );
      }
      return false;
    });

    const showPrompt =
      waitingListRecords.filter(
        x =>
          DateTime.fromISO(x.until).toISODate() >= DateTime.now().toISODate() &&
          this.calendarEvent?.id !== x.calenderEventId
      ).length > 0 &&
      !waitingListRecords.find(x => {
        return x.calenderEventId === this.calendarEvent?.id;
      });

    if (
      !values.waitingFieldToggle &&
      values.waitingListIds?.length &&
      waitingListRecords.length > 0
    ) {
      const patientFullName = waitingListRecords[0]?.contact?.model?.fullName;
      const isConfirmed = await confirm({
        confirmButtonProps: {
          text: "Confirm"
        },
        cancelButtonProps: {
          text: "Cancel"
        },
        dialogContentProps: {
          title: "Remove from waiting list",
          subText: `Are you sure you want to remove ${patientFullName} from the waiting list?`
        }
      });
      if (!isConfirmed) {
        form.batch(() => {
          form.change(appointmentFormNameOf("priority"), values.priority);
          form.change(
            appointmentFormNameOf("expiryDate"),
            (values.expiryDate
              ? DateTime.fromJSDate(values.expiryDate).startOf("day")
              : DateTime.now()
            ).toJSDate()
          );
          form.change(appointmentFormNameOf("anyProvider"), values.anyProvider);
          form.change(appointmentFormNameOf("waitingListIds"), []);
          form.change(appointmentFormNameOf("waitingFieldToggle"), false);
        });
      }
      if (isConfirmed) {
        await Promise.all(
          values.waitingListIds.map(id =>
            this.booking.deleteWaitingListRecord(id)
          )
        );
        this.logEventSubmitForm(form, values, trackFormEvent);
      }
    } else if (values.waitingFieldToggle && showPrompt) {
      const patientName = this.practice.contactsMap.get(values.patientId)?.name;
      const isConfirmed = await confirm({
        confirmButtonProps: {
          text: "Yes"
        },
        cancelButtonProps: {
          text: "No"
        },
        dialogContentProps: {
          title: "Already on waiting list",
          subText: `${patientName} is already on the waiting list. Are you sure you want to add them again?`
        }
      });
      if (isConfirmed) {
        this.logEventSubmitForm(form, values, trackFormEvent);
      }
    } else {
      this.logEventSubmitForm(form, values, trackFormEvent);
    }
  };

  confirmExternalPatientMatch = async (values: AppointmentFormValues) => {
    const {
      firstName = "",
      lastName = "",
      dateOfBirth,
      postcode,
      sourceId,
      matchedId
    } = this.calendarEvent?.externalPatient || {};

    const externalPatientFullName = `${firstName} ${lastName}`;
    const dob = dateOfBirth
      ? DateTime.fromISO(dateOfBirth).toDayDefaultFormat()
      : "";

    const { patientId } = values;
    const patient = this.practice.contactsMap.get(patientId)!;
    const patientDOB = patient.birthDate
      ? `, DOB ${patient.birthDate.toDayDefaultFormat()}`
      : "";

    const patientPostcode = patient.defaultAddress
      ? `, postcode ${patient.defaultAddress.postCode}`
      : "";

    const patientLabelLower = this.patientLabel.toLowerCase();

    const confirmText = (
      <Stack tokens={{ childrenGap: 12 }}>
        <Text block>
          {matchedId
            ? `The original ${patientLabelLower} for this appointment is linked to an online booking ${patientLabelLower} ${externalPatientFullName}, DOB ${dob}, postcode ${postcode}`
            : `This online booking appointment was booked by ${patientLabelLower} ${externalPatientFullName}, DOB ${dob}, postcode ${postcode}`}
        </Text>
        <Text>
          {matchedId
            ? `Would you like to update and match the online booking ${patientLabelLower} to `
            : `Would you like to match this ${patientLabelLower} with `}
          {patient.preferredFullName}
          {patientDOB}
          {patientPostcode}?
        </Text>
        <Text>
          Review the{" "}
          <Link href={KNOWLEDGEBASE_INFO_LINK} target="_blank">
            Knowledge Base
          </Link>{" "}
          for full {patientLabelLower} matching rules
        </Text>
      </Stack>
    );

    const result = await confirmMulti({
      dialogContentProps: {
        title: `${matchedId ? "Existing" : "Confirm"} ${
          this.patientLabel
        } match`
      },
      minWidth: 600,
      buttons: [
        { text: "Yes", primary: true },
        { text: "No" },
        { text: "Cancel" }
      ],
      children: confirmText
    });
    if (result === "Cancel") {
      return false;
    }
    if (result === "Yes" && sourceId && this.core.tenantDetails?.id) {
      // inform BHB of patient match
      await this.bhac.addOrUpdatePatient({
        accountId: sourceId,
        tenantId: this.core.tenantDetails?.id,
        patientId
      });
      if (this.calendarEvent?.externalPatient) {
        this.calendarEvent.externalPatient.matchedId = patientId;
      }
    }
    return true;
  };

  handleSubmit = async (
    values: AppointmentFormValues,
    api: FormApi<AppointmentFormValues>,
    complete: () => void
  ) => {
    const isExternalAppointment = this.calendarEvent?.isExternalAppointment;
    if (
      isExternalAppointment &&
      !(values.patientId === this.calendarEvent?.externalPatient?.matchedId)
    ) {
      const confirmResult = await this.confirmExternalPatientMatch(values);
      if (!confirmResult) {
        return;
      }
    }

    const baseRequest = await this.getBaseRequest(values);

    let recurrenceCreated: RecurrenceModel | undefined;
    let eventId: string | undefined;

    // update existing appointment
    if (
      this.calendarEvent &&
      (!this.calendarEventRecurrence || this.booking.ui.isEditSingleEvent)
    ) {
      await this.booking.updateCalendarEvent(
        {
          id: this.calendarEvent.id,
          ...baseRequest
        },
        {
          ClaimLinked: !!this.isConditionExists(values.episodeOfCareId)
            ? ClaimLinkedToAppointment.ExistingClaimLinkedToAppointment
            : ClaimLinkedToAppointment.NewClaimLinkedToAppointment
        }
      );
      eventId = this.calendarEvent.id;
    } else {
      // new appointment
      if (values.repeat) {
        recurrenceCreated = await this.createRepeatCalendarEvent(values);
      } else {
        this.newEpisodeOfCareId = values.episodeOfCareId;
        const calendarEventCreated = await this.createCalendarEvent(values);
        eventId = calendarEventCreated.id;
      }
    }

    if (recurrenceCreated) {
      await this.getRecurrenceCalendarEvent(recurrenceCreated);
    } else {
      await this.updateWaitingList(values, eventId);
    }

    await this.saveFormPreferences(values);
    const deployed = await this.deployForm(values, eventId);
    deployed?.hostingUrl &&
      (values.sendDemographicForm === ContextMenuItemsEnum.QRCodeKey ||
        (!values.sendDemographicForm &&
          values.sendAccForm === ContextMenuItemsEnum.QRCodeKey)) &&
      this.booking.ui.showFormQRCodeUrl(deployed?.hostingUrl!);
    complete();

    this.clearCreatedConditions();
    if (this.booking.ui.currentAppointment?.onSubmitted) {
      this.booking.ui.currentAppointment.onSubmitted(values);
    }
    this.booking.ui.hideCalendarEventDialog();
  };

  temporaryReservationDebounce = debounce(
    async (formValues: AppointmentFormValues) => {
      if (!formValues) return;
      if (
        formValues.providerId &&
        formValues.duration !== 0 &&
        formValues.duration &&
        !Number.isNaN(formValues.duration) &&
        formValues.startDate &&
        formValues.startTime
      ) {
        const baseRequest = await this.getBaseRequest(formValues);
        const orgUnitId = this.core.hasMultipleActiveLocations
          ? formValues.orgUnitId
          : this.core.locationId;

        await this.booking.addTemporaryReservation({
          ...baseRequest,
          type: CalendarEventType.TemporaryReservation,
          orgUnitId
        });
      }
      return;
    },
    500
  );

  // asyncCreateTemporaryReservation returns promise when debounce action is completed
  // it help to close sidePanel after temporary reservation is created
  asyncCreateTemporaryReservation = (formValues: AppointmentFormValues) => {
    return new Promise(resolve => {
      resolve(this.temporaryReservationDebounce(formValues));
    });
  };

  public formToDeploy(values: AppointmentFormValues) {
    if (values.sendDemographicForm && values.sendAccForm) {
      return {
        form: FormTemplateTypeCode.acc45Demographic,
        qrCode: values.sendDemographicForm === ContextMenuItemsEnum.QRCodeKey
      };
    } else if (values.sendAccForm) {
      return {
        form: FormTemplateTypeCode.acc,
        qrCode: values.sendAccForm === ContextMenuItemsEnum.QRCodeKey
      };
    } else if (values.sendDemographicForm) {
      return {
        form: FormTemplateTypeCode.patientDemographic,
        qrCode: values.sendDemographicForm === ContextMenuItemsEnum.QRCodeKey
      };
    }
    return null;
  }

  private async deployForm(
    values: AppointmentFormValues,
    calendarEventId?: string
  ) {
    // Get claimId from the selected episode of care
    const condition = this.conditionAppointmentFormHelper?.conditions.find(
      x => x.episodeOfCareId === values.episodeOfCareId
    );

    const claimId = condition?.claim?.id;

    if (values.sendAccForm && !claimId) {
      throw new Error(
        "Unable to send Acc form. ClaimId is required in the context."
      );
    }

    let formExpiry: DateTime | undefined;

    if (values.startDate && values.startTime) {
      formExpiry = DateTime.fromJSDateAndTime(
        values.startDate || DateTime.jsDateNow(),
        values.startTime
      );
    }

    const formToDeploy = this.formToDeploy(values);
    let formContext: Record<string, string> | undefined;
    let linkFormInstance = false;

    switch (formToDeploy?.form) {
      case FormTemplateTypeCode.acc:
      case FormTemplateTypeCode.acc45Demographic:
        formContext = {
          PatientId: values.patientId,
          ClaimId: claimId!,
          CalendarEventId: calendarEventId ?? ""
        };
        linkFormInstance = true;
        break;
      case FormTemplateTypeCode.patientDemographic:
        formContext = {
          PatientId: values.patientId,
          CalendarEventId: calendarEventId ?? ""
        };
        break;
    }

    if (formToDeploy && formContext) {
      const formDeployment = await this.forms.deployFormByCode({
        code: formToDeploy.form,
        context: formContext,
        expiry: formExpiry,
        qrCode: formToDeploy.qrCode
      });
      if (linkFormInstance) {
        if (calendarEventId && formDeployment) {
          await this.booking.addCalendarEventFormInstance({
            calendarEventId,
            formInstanceId: formDeployment.formInstance.id,
            formTemplateTypeCode:
              formDeployment.formInstance.formTemplateTypeCode
          });
        }

        if (claimId && formDeployment) {
          await this.acc.addClaimFormInstance({
            claimId,
            formInstanceId: formDeployment.formInstance.id,
            formTemplateTypeCode:
              formDeployment.formInstance.formTemplateTypeCode
          });
        }
      }
      return formDeployment;
    }
    return null;
  }

  getBrowserStartDateTime = (startDate: Date, startTime: string) => {
    return DateTime.fromJSDateAndTime(startDate, startTime).setZone(
      DateSettings.systemTimeZone
    );
  };

  getEndDateTime(startDateTime: DateTime, duration: number) {
    return startDateTime && startDateTime.plus({ minutes: Number(duration) });
  }

  isDateTimeAvailable(timeRanges: TimeRange[], dateTimeToCheck: DateTime) {
    return timeRanges.some(timeRange =>
      dateTimeToCheck.isBetweenInclusive(timeRange.from, timeRange.to)
    );
  }

  hasBookOutsideWorkingHoursPermissions = () => {
    return this.root.core.hasPermissions([Permission.BookingScheduleWrite]);
  };

  isSettingOutsideWorkHoursWithNoPermission = () => {
    return (
      !this.hasBookOutsideWorkingHoursPermissions() &&
      !this.isAppointmentWithinUserWorkTime
    );
  };

  checkAppointmentWithinUserWorkTime = async (
    values: Partial<AppointmentFormValues>
  ) => {
    const { startDate, startTime, duration, providerId, orgUnitId } = values;
    if (
      startDate &&
      duration &&
      !Number.isNaN(duration) &&
      Number(duration) > 0 &&
      providerId
    ) {
      const startDateTime = DateTime.fromJSDateAndTime(startDate, startTime);

      const endDateTime = this.getEndDateTime(startDateTime, Number(duration));

      const usersWorkingHours: UsersTimeRanges =
        await this.booking.getUsersWorkingHours({
          from: startDateTime.startOf("week"),
          to: startDateTime.plus({ weeks: 1 }),
          userIds: [providerId],
          isStandardHours: false,
          orgUnitId
        });

      if (usersWorkingHours) {
        const userWorkingHours = usersWorkingHours[providerId];

        const isStartTimeAvailable = this.isDateTimeAvailable(
          [...(userWorkingHours?.timeRanges || [])],
          startDateTime
        );

        const isEndTimeAvailable = this.isDateTimeAvailable(
          [...(userWorkingHours?.timeRanges || [])],
          endDateTime
        );

        runInAction(() => {
          this.isAppointmentWithinUserWorkTime =
            isStartTimeAvailable && isEndTimeAvailable;
        });
      }
    }
  };

  /**  The section below is used for analytics purposes*/

  isConditionExists = (episodeOfCareId: string | undefined) =>
    this.conditionAppointmentFormHelper &&
    this.conditionAppointmentFormHelper.conditions.filter(x => !x.discharged)
      ?.length > 0 &&
    this.initialConditions.find(x => x.episodeOfCareId === episodeOfCareId);

  newEpisodeOfCareId: string | undefined = undefined;

  initialConditions: ICondition[] = [];

  setInitialConditions = (conditions: ICondition[]) => {
    if (this.initialConditions.length === 0) {
      this.initialConditions = conditions;
    }
  };

  public showCancelEvent = () => {
    this.booking.ui.setCancelCalendarEventId(
      this.booking.ui.currentAppointment?.id
    );
  };

  private logEventSubmitForm = (
    form: FormApi<AppointmentFormValues>,
    values: AppointmentFormValues,
    trackFormEvent: (formName: string, page: string) => void
  ) => {
    const deployForm = this.formToDeploy(values);

    deployForm && trackFormEvent(deployForm.form, document.title);

    form.submit();
  };

  saveFormPreferences = async (values: AppointmentFormValues) => {
    if (
      (values.sendDemographicForm &&
        values.sendDemographicForm !== ContextMenuItemsEnum.QRCodeKey) ||
      (values.sendAccForm &&
        values.sendAccForm !== ContextMenuItemsEnum.QRCodeKey)
    ) {
      const contactPreferences = await this.comms.getContactPreference(
        values.patientId
      );

      if (!contactPreferences?.formNotifyPreferences) {
        const communications = (
          await this.practice.getContact(values.patientId)
        ).communications;

        const mobile = findCommValue(communications, CommunicationType.Mobile);
        const email = findCommValue(communications, CommunicationType.Email);
        const preferences = Array.from(
          contactPreferences?.commTypePreferences ?? []
        );

        if (mobile) {
          preferences.push({
            commTypeCode: OutboundCommType.FormNotify,
            preferredCommAddressValue: mobile,
            preferredCommChannelTypeCode: OutboundCommChannel.Sms
          });
        } else if (email) {
          preferences.push({
            commTypeCode: OutboundCommType.FormNotify,
            preferredCommAddressValue: email,
            preferredCommChannelTypeCode: OutboundCommChannel.Email
          });
        } else {
          // Send demographic/acc form buttons should be disabled if contact has no mobile/email so this case shouldn't be reached
          throw new Error("Unable to save form preferences.");
        }

        if (contactPreferences) {
          await this.comms.patchContactPreferences({
            id: values.patientId,
            eTag: contactPreferences.eTag,
            commTypePreferences: preferences
          });
        } else {
          await this.comms.addContactPreferences({
            id: values.patientId,
            commTypePreferences: preferences
          });
        }
      }
    }
  };

  handleAppointmentTypeChange = (
    appointmentTypeId: string,
    formOptions: {
      initialValues: Partial<AppointmentFormValues>;
      values: AppointmentFormValues;
      change: FormApi<AppointmentFormValues>["change"];
      batch: FormApi<AppointmentFormValues>["batch"];
    }
  ) => {
    const { initialValues, values, change, batch } = formOptions;
    const appointmentType =
      this.root.booking.appointmentTypesMap.get(appointmentTypeId);
    if (!appointmentType) return;

    // Allow for new appointments only
    batch(() => {
      if (!this.calendarEvent) {
        // Switching to a regular appointment
        if (
          this.isGroupAppointment &&
          !appointmentType.isGroupAppointmentType
        ) {
          change(appointmentFormNameOf("patientId"), undefined);
        }

        // Switching to a group appointment
        if (
          !this.isGroupAppointment &&
          appointmentType.isGroupAppointmentType
        ) {
          if (values.patientId) {
            change(appointmentFormNameOf("groupAttendeeIds"), [
              values.patientId
            ]);
            change(appointmentFormNameOf("groupAttendees"), [
              { attendeeId: values.patientId, type: AttendeeTypeEnum.contact }
            ]);
          } else {
            change(appointmentFormNameOf("groupAttendeeIds"), undefined);
            change(appointmentFormNameOf("groupAttendees"), undefined);
          }
          this.booking.ui.setSecondColumnContent(
            SecondColumnContent.groupAttendees
          );
          change(
            appointmentFormNameOf("maxParticipants"),
            appointmentType.maxParticipants
          );
        }

        this.setIsGroupAppointment(appointmentType.isGroupAppointmentType);
      }
    });

    // do not change duration if it has been prefilled with a different duration for this appointment type
    if (
      !appointmentTypeId ||
      (initialValues &&
        initialValues.duration &&
        initialValues.appointmentTypeId === appointmentTypeId &&
        initialValues.duration === values.duration)
    ) {
      return;
    }

    let newDuration: number | undefined = appointmentType.duration;

    // Use the duration of an associated waiting list entry (the duration for an appt type in the waiting list may differ from the default for that appt type)
    if (!this.isGroupAppointment && values.waitingListIds?.length) {
      const wlItemId = values.waitingListIds[0];
      const wlItem = this.root.booking.waitingListsMap.get(wlItemId);
      if (
        wlItem &&
        wlItem.appointmentTypeId === appointmentTypeId &&
        wlItem.duration
      ) {
        newDuration = Number(wlItem.duration);
      }
    }

    change(appointmentFormNameOf("duration"), newDuration);
  };

  handleAttendeeCancellation = async (
    inputValues: CancelAppointmentFormValues,
    formOptions: {
      values: AppointmentFormValues;
      change: FormApi<AppointmentFormValues>["change"];
    },
    attendeeId?: string
  ) => {
    const { values, change } = formOptions;
    const { groupAttendees } = values;
    const { cancellationReasonId, cancellationText } = inputValues;

    const prevAttendee = groupAttendees?.find(
      attendee => attendee.attendeeId === attendeeId
    );

    if (prevAttendee) {
      const newGroupAttendees = groupAttendees?.map(groupAttendee => {
        if (groupAttendee.attendeeId === prevAttendee.attendeeId) {
          const attendee: CalendarEventAttendeeDto = {
            ...prevAttendee,
            cancellationReasonId: cancellationReasonId || undefined,
            cancellationText,
            status: CalendarEventStatus.Cancelled,
            cancellationDateTime: DateTime.now().toISO()
          };

          return attendee;
        }
        return groupAttendee;
      });
      change(appointmentFormNameOf("groupAttendees"), newGroupAttendees);

      const waitingListRecords =
        await this.booking.getWaitingListRecordsFilteredValues({
          expiryDate: DateTime.jsDateToISODate(values.startDate),
          appointmentTypeId: values.appointmentTypeId
            ? [values.appointmentTypeId]
            : undefined,
          duration: values.duration ? [values.duration.toString()] : undefined
        });

      const filteredByProviderOrAny = waitingListRecords.filter(
        wl =>
          wl.attendees.some(a => a.attendeeId === values.providerId) ||
          wl.anyProvider
      );

      if (filteredByProviderOrAny.length) {
        this.setShowWaitingListDialog(true);
      }
    }
  };

  getFilteredPatientNotices = (attendeeId: string) => {
    return Array.from(this.practice.patientNoticesMap.values()).filter(
      x => x.patientId === attendeeId && x.isAdmin
    );
  };

  getSystemNotices = (attendeeId: string) => {
    return this.practice.systemNotices.filter(
      sn => sn.patientId === attendeeId
    );
  };

  addToGroupApptSubmit = async (values: AppointmentFormValues) => {
    if (values.selectedApptId) {
      const calendarEvent = await this.booking.getCalendarEvent(
        values.selectedApptId
      );

      const existingAttendees = calendarEvent.dto.attendees.slice();
      const removedAttendee = this.removeCancelledAttendee(
        values.patientId,
        existingAttendees
      );
      removedAttendee.push({
        attendeeId: values.patientId,
        type: AttendeeTypeEnum.contact,
        attendeeStatus: AppointmentStatusCode.Booked,
        status: CalendarEventStatus.Confirmed
      });

      await this.booking.updateCalendarEvent({
        id: calendarEvent.id,
        attendees: removedAttendee
      });
    }
  };

  removeCancelledAttendee = (
    attendeeId: string,
    groupAttendees: CalendarEventAttendeeDto[]
  ) => {
    return groupAttendees.filter(
      attendee =>
        !(
          attendee.attendeeId === attendeeId &&
          attendee.status === CalendarEventStatus.Cancelled
        )
    );
  };

  getUpcomingGroupAppointments = async (
    date: Date,
    providerIds: string[],
    appointmentTypeId: string
  ) => {
    if (appointmentTypeId) {
      const startTime = DateTime.fromJSDate(date).startOf("day").toISO();

      const endTime = DateTime.fromJSDate(date).endOf("day").toISO();

      const calendarEvents = await this.booking.getCalendarEvents({
        attendees: providerIds,
        appointmentTypes: [appointmentTypeId],
        startTime,
        endTime
      });

      const locationIds = new Set<string>();

      const groupApptSlots: GroupApptSlots[] = [];
      providerIds.forEach(provider => {
        const providerCalendarEvents = calendarEvents.results.filter(
          x => x.userId === provider
        );

        const appointmentSlots: GroupApptSlot[] = [];
        providerCalendarEvents.forEach(event => {
          if (event.maxParticipants) {
            const remainingSpaces =
              event.maxParticipants - event.activeAttendees.length;

            if (remainingSpaces > 0) {
              const startTime = formatTimeSpanWithDate(
                event.startDateTime.toISO(),
                {
                  date: event.startDateTime.toJSDate()
                }
              );

              locationIds.add(event.dto.orgUnitId);

              appointmentSlots.push({
                startTime: startTime ?? "",
                remainingSpaces,
                calendarEventId: event.id,
                startDateTime: event.startDateTime,
                attendees: event.dto.attendees.filter(
                  x => x.status !== CalendarEventStatus.Cancelled
                ),
                orgUnitId: event.dto.orgUnitId
              });
            }
          }
        });
        groupApptSlots.push({
          providerId: provider,
          appointmentTimes: appointmentSlots
        });
      });

      if (this.core.hasMultipleActiveLocations) {
        await Promise.all(
          Array.from(locationIds).map(id => this.practice.getOrgUnit(id))
        );
      }

      return groupApptSlots.sort((a, b) => {
        if (a.appointmentTimes.length < b.appointmentTimes.length) return 1;
        if (b.appointmentTimes.length < a.appointmentTimes.length) return -1;
        return 0;
      });
    }
    return [];
  };

  getGroupApptToggleText = (appointments: GroupApptSlot[]) => {
    const numberToDisplay = appointments && appointments.length - 3;
    return this.toggleText(!!appointments, numberToDisplay);
  };

  getNextAvailableDate = async (
    providerId: string,
    setDate: Date,
    appointmentTypeId?: string
  ) => {
    if (appointmentTypeId) {
      const startTime = DateTime.fromJSDate(setDate).startOf("day").toISO();

      const appointments = await this.booking.getCalendarEvents({
        appointmentTypes: [appointmentTypeId],
        attendees: [providerId],
        startTime
      });
      if (appointments.results && appointments.results.length > 0) {
        const filteredAppointments = Array.from(
          appointments.results.filter(
            x =>
              x.maxParticipants &&
              x.maxParticipants - x.activeAttendees.length > 0
          )
        );

        if (filteredAppointments && filteredAppointments.length > 0) {
          const sortedAppointments = filteredAppointments.sort((a, b) =>
            compareDatesPredicate(a.startDateTime, b.startDateTime)
          );
          return sortedAppointments[0].startDateTime;
        }
      }
    }
    return undefined;
  };

  groupSlotsByOrgUnitId(
    allSlots: AvailabilitySlots,
    date: DateTime,
    isExpanded: boolean
  ): {
    orgUnitId: string;
    nextAvailableDate: DateTime | undefined;
    availabilitySlots: AvailabilitySlots;
  }[] {
    // Group slots by orgUnitId
    const groupedSlots = groupBy(allSlots.dto, item => item.orgUnitId);

    //Grouped slots to AvailabilitySlots and orgUnitId
    const results = groupedSlots.map(([orgUnitId, slotGroup]) => {
      const availabilitySlots = new AvailabilitySlots(
        slotGroup,
        allSlots.providerId
      );

      const nextAvailable = availabilitySlots.getFirstSlotFromSelectedDate(
        date,
        orgUnitId
      );

      const nextAvailableDate = nextAvailable?.date
        ? DateTime.fromISODateAndTime(
            nextAvailable?.date,
            nextAvailable?.availableTimes[0]
          )
        : undefined;

      return { orgUnitId, nextAvailableDate, availabilitySlots };
    });

    results.sort((resultA, resultB) => {
      if (resultA.nextAvailableDate && resultB.nextAvailableDate) {
        return compareDatesPredicate(
          resultA.nextAvailableDate,
          resultB.nextAvailableDate
        );
      }

      if (resultA.nextAvailableDate) return -1;
      if (resultB.nextAvailableDate) return 1;

      return 0;
    });

    if (isExpanded) return results;

    const sliceIndex =
      results.findIndex(
        result => !result.nextAvailableDate?.hasSame(date, "day")
      ) || 1;

    return results.slice(0, sliceIndex === -1 ? results.length : sliceIndex);
  }

  onShowEdit = (cardId: PatientCardIds, id: string) => {
    const hasPatientWritePermission = this.core.hasPermissions(
      Permission.PatientWrite
    );

    if (hasPatientWritePermission) this.practice.ui.showEditContact(cardId, id);
    else this.routing.push(routes.contacts.contact.path({ id }));
  };

  public getToggleText = (slots: AvailabilitySlots, pickerStartDate: Date) => {
    // Convert string to date in the slots Object
    const convertedAvailability = slots.dto.map(x => ({
      date: DateTime.fromISO(x.date).toDayDefaultFormat(),
      availableTimes: x.availableTimes
    }));

    const availability = convertedAvailability.find(
      x => x.date === DateTime.fromJSDate(pickerStartDate).toDayDefaultFormat()
    );

    const numberToDisplay =
      availability && availability.availableTimes.length - 5;

    return this.toggleText(!!availability, numberToDisplay);
  };

  private toggleText = (
    isArray: boolean | undefined,
    numberToDisplay: number | undefined
  ) => {
    const MAX_TIME_SLOT_VALUE = 99;
    let textToDisplay: string = "";

    if (
      isArray &&
      numberToDisplay &&
      numberToDisplay > 0 &&
      numberToDisplay <= MAX_TIME_SLOT_VALUE
    )
      textToDisplay = `More (${numberToDisplay})`;
    else if (
      isArray &&
      numberToDisplay &&
      numberToDisplay > MAX_TIME_SLOT_VALUE
    )
      textToDisplay = `More (${MAX_TIME_SLOT_VALUE}+)`;
    else textToDisplay = "Change Date";
    return textToDisplay;
  };

  onApptDetailsClick = () => {
    this.booking.ui.setSecondColumnContent(
      this.booking.ui.currentAppointment?.secondColumnContent !==
        SecondColumnContent.nextAvailable
        ? SecondColumnContent.nextAvailable
        : undefined
    );
  };
}
