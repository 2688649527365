import { observer } from "mobx-react-lite";
import { Field, useForm, useFormState } from "react-final-form";

import { IPersonaProps, Stack } from "@bps/fluent-ui";
import {
  Permission,
  UserStatus
} from "@libs/gateways/core/CoreGateway.dtos.ts";
import { ContactType } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { nameOfFactory } from "@libs/utils/name-of.utils.ts";
import { capitalizeSentence } from "@libs/utils/utils.ts";
import { AppointmentTypePicker } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/group-attendees-details/appointment-type-picker/AppointmentTypePicker.tsx";
import { ContactPickerFieldWithAdd } from "@modules/practice/screens/shared-components/contact-picker/ContactPickerFieldWithAdd.tsx";
import { PatientActivation } from "@modules/practice/screens/shared-components/PatientActivation.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { ButtonsGroupSingleChoiceField } from "@ui-components/form/ButtonsGroupSingleChoiceField.tsx";
import { CheckboxField } from "@ui-components/form/CheckboxField.tsx";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { Fieldset } from "@ui-components/form/Fieldset.tsx";
import { FieldSpy } from "@ui-components/form/FieldSpy.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { TextInputField } from "@ui-components/form/TextInputField.tsx";
import { BookableUserPickerField } from "@ui-components/pickers/user-picker/BookableUserPickerField.tsx";

import { WaitingListItemFormValues } from "./WaitingListItemFormValues.ts";
import { WaitingListLocationSelectField } from "./WaitingListLocationSelectField.tsx";

export const waitingListItemFormNameOf =
  nameOfFactory<WaitingListItemFormValues>();

export const WaitingListItemFormFields: React.FunctionComponent = observer(
  () => {
    const { booking, userExperience, core } = useStores();
    const { initialValues } = useFormState({
      subscription: { initialValues: true }
    });

    const form = useForm();

    const getTextFromItem = (persona: IPersonaProps) => persona.text as string;

    const handleAppointmentTypeChange = (value: string) => {
      // do not change duration if it has been prefilled with a different duration for this appointment type
      if (
        !value ||
        (initialValues &&
          initialValues.duration &&
          initialValues.appointmentTypeId === value &&
          initialValues.duration === form.getFieldState("duration")?.value)
      ) {
        return;
      }

      const appointmentType = booking.appointmentTypesMap.get(value);
      if (!appointmentType) {
        return;
      }
      form.change(
        waitingListItemFormNameOf("duration"),
        appointmentType.duration
      );
    };

    const handleProviderChange = async (value: string) => {
      const providerAvailability = await booking.getUserAvailability(value);

      form.change(
        waitingListItemFormNameOf("orgUnitId"),
        providerAvailability.availableOrgUnitIds[0]
      );
    };

    const priorityOptions = [
      { key: 1, text: "1" },
      { key: 2, text: "2" },
      { key: 3, text: "3" }
    ];

    const patientLabel = userExperience.localisedConfig("patientDisplay");

    return (
      <Fieldset>
        <Stack horizontal horizontalAlign="space-between" verticalAlign="end">
          <ContactPickerFieldWithAdd
            wrapperStyles={{ root: { flexGrow: 1 } }}
            name={waitingListItemFormNameOf("patientId")}
            label={capitalizeSentence(patientLabel)}
            required
            includeInactive
            getTextFromItem={getTextFromItem}
            quickAddProps={{
              addButtonLabel: `New ${patientLabel}`,
              typeToAdd: ContactType.Patient,
              permission: [
                Permission.ContactRead,
                Permission.ContactWrite,
                Permission.PatientRead,
                Permission.PatientWrite
              ]
            }}
            filter={{ types: [ContactType.Patient] }}
            actionButton={{
              linkProps: {
                children: (
                  <Field<string | undefined>
                    name={waitingListItemFormNameOf("patientId")}
                  >
                    {props => {
                      if (!props.input.value) {
                        return null;
                      }
                      return (
                        <PatientActivation patientId={props.input.value} />
                      );
                    }}
                  </Field>
                )
              }
            }}
          />
        </Stack>
        <FieldCondition
          when={waitingListItemFormNameOf("patientId")}
          is={undefined}
        >
          {(props: any) => {
            if (!props.input.value) {
              return null;
            }
            return <PatientActivation patientId={props.input.value} />;
          }}
        </FieldCondition>
        <Fieldset
          horizontal
          horizontalAlign="space-between"
          tokens={{ childrenGap: 8 }}
        >
          <AppointmentTypePicker
            name={waitingListItemFormNameOf("appointmentTypeId")}
            onRemoveAppointmentType={() => {
              form.change("appointmentTypeId", undefined);
            }}
            disabled={false}
          />

          <SpinNumberInputField
            required
            label="Duration (mins)"
            name={waitingListItemFormNameOf("duration")}
            min={0}
            step={15}
            styles={{ input: { width: 0 } }}
            fieldItemStyles={{
              itemWrapper: { alignItems: "baseline" },
              item: { flexGrow: 0 }
            }}
            // SpinNumberInput component has a min width of 86
          />
        </Fieldset>
        <Stack
          horizontal
          horizontalAlign="space-between"
          tokens={{ childrenGap: 8 }}
        >
          <Stack styles={{ root: { flexGrow: 1, flexBasis: 0 } }}>
            <BookableUserPickerField
              name={waitingListItemFormNameOf("providerId")}
              currentUserOnTop
              label="Provider"
              filter={{
                statusCodes: [UserStatus.Active],
                showOnCalendar: true
              }}
              required
              getTextFromItem={getTextFromItem}
              iconName="Search"
            />
          </Stack>
          {core.hasMultipleActiveLocations && (
            <WaitingListLocationSelectField />
          )}
        </Stack>
        <Stack>
          <Fieldset horizontal>
            <ButtonsGroupSingleChoiceField
              label="Priority"
              name={waitingListItemFormNameOf("priority")}
              options={priorityOptions}
              required
            />
            <DatePickerField
              label="List expires"
              name={waitingListItemFormNameOf("expiryDate")}
              required
            />
            <CheckboxField
              label="Any provider"
              name={waitingListItemFormNameOf("anyProvider")}
              styles={{
                root: {
                  marginTop: 40
                }
              }}
            />
            {core.hasMultipleActiveLocations && (
              <CheckboxField
                label="Any location"
                name={waitingListItemFormNameOf("anyLocation")}
                styles={{
                  root: {
                    marginTop: 40
                  }
                }}
              />
            )}
          </Fieldset>
        </Stack>
        <TextInputField
          label="Comments"
          name={waitingListItemFormNameOf("comments")}
          multiline={true}
          rows={1}
          autoAdjustHeight
          styles={{ fieldGroup: { minHeight: 30 } }}
        />
        <FieldSpy
          name={waitingListItemFormNameOf("appointmentTypeId")}
          onChange={handleAppointmentTypeChange}
        />
        {core.hasMultipleActiveLocations && (
          <FieldSpy
            name={waitingListItemFormNameOf("providerId")}
            onChange={handleProviderChange}
          />
        )}
      </Fieldset>
    );
  }
);
