import { FontIcon, Stack, TooltipHost, useTheme } from "@bps/fluent-ui";
import { Contact } from "@stores/practice/models/Contact.ts";
import { ContactCategoryText } from "@ui-components/RefText.tsx";

import { getPeopleScreenStylesSet } from "../../../shared-components/PeopleScreen.styles.ts";
import { ContactGenderInformation } from "./ContactGenderInformation.tsx";

export const ContactPersonaSecondaryText: React.FC<{ contact: Contact }> = ({
  contact
}) => {
  const theme = useTheme();
  const { breakLongText } = getPeopleScreenStylesSet(theme);

  return (
    <Stack styles={{ root: breakLongText }} tokens={{ childrenGap: 8 }}>
      <Stack.Item>
        <ContactGenderInformation contact={contact} />
      </Stack.Item>
      {contact.isHealthProvider ? (
        <Stack tokens={{ childrenGap: 8 }}>
          <Stack.Item>
            <TooltipHost
              content={contact.categories.length ? "Health provider" : ""}
            >
              <FontIcon
                iconName="Hospital"
                styles={{
                  root: {
                    paddingRight: 8
                  }
                }}
              />
            </TooltipHost>
            {contact.categories.length ? (
              <ContactCategoryText code={contact.categories} />
            ) : (
              <span>Health provider</span>
            )}
          </Stack.Item>
        </Stack>
      ) : null}
    </Stack>
  );
};
