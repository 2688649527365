import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { useForm, useFormState } from "react-final-form";

import { Link, StateBlock } from "@bps/fluent-ui";
import { ErrorMessageDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import {
  ClaimManagementCardIds,
  ClaimManagementModalFormLabels
} from "@modules/acc/screens/claim/components/ClaimFormEnums.ts";
import { ClaimContext } from "@modules/acc/screens/claim/context/ClaimContext.ts";
import { ClaimConditionHelperDataFetcher } from "@modules/acc/screens/shared-components/ClaimConditionHelperDataFetcher.tsx";
import { ConditionHelper } from "@modules/booking/screens/booking-calendar/components/appointment-dialog/components/appointment-form/context/ConditionHelper.ts";
import { OverviewCard } from "@modules/clinical/screens/shared-components/overview-card/OverviewCard.tsx";
import { CalendarEvent } from "@stores/booking/models/CalendarEvent.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { defaultConfirm } from "@ui-components/form/submission-form-dialog/defaultConfirm.util.ts";

interface OverviewFormSectionProps {
  errorMessageList: ErrorMessageDto[];
}

interface OverviewFormSectionBaseProps extends OverviewFormSectionProps {
  conditionHelper: ConditionHelper;
}

const OverviewFormSectionBase: React.FC<OverviewFormSectionBaseProps> =
  observer(({ errorMessageList, conditionHelper }) => {
    const { core } = useStores();
    const { claim } = useContext(ClaimContext);

    const form = useForm();
    const { dirty } = useFormState();

    const onConfirm = () => {
      form.reset();
      conditionHelper.setHiddenConditionModal(false);
    };

    const onClean = () => {
      conditionHelper.setHiddenConditionModal(false);
    };

    const handleDialogOpened = async () => {
      defaultConfirm(dirty, onConfirm, onClean);
    };

    const insurerStateBlock = claim.dto.insurerContactId ? (
      <DataFetcher
        key={claim.dto.insurerContactId}
        fetch={({ practice }) =>
          practice.getContact(claim.dto.insurerContactId!)
        }
      >
        {(contact: Contact) => (
          <StateBlock
            styles={{
              root: { padding: "12px 16px", width: 0 },
              wrapper: { width: "100%" },
              description: {
                width: "100%",
                height: 19,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
              }
            }}
            labelText={ClaimManagementModalFormLabels.insurer}
            descText={contact.name}
          />
        )}
      </DataFetcher>
    ) : (
      <StateBlock
        styles={{
          root: {
            padding: "12px 16px",
            width: 0
          },
          description: { height: 19 }
        }}
        labelText={ClaimManagementModalFormLabels.insurer}
        descText={
          !claim.private ? (
            <Link onClick={() => conditionHelper.setHiddenConditionModal(true)}>
              {ClaimManagementModalFormLabels.addInsurer}
            </Link>
          ) : (
            ClaimManagementModalFormLabels.none
          )
        }
      />
    );

    return (
      <DataFetcher<{
        editDisabled: boolean;
        initialCalendarEvent: CalendarEvent | undefined;
      }>
        fetch={async () => {
          const noPermission = !core.hasPermissions(Permission.ClaimWrite);

          await claim.loadCalendarEvent();

          const editDisabled = noPermission;

          return {
            editDisabled,
            initialCalendarEvent: claim.initialCalendarEvent
          };
        }}
      >
        {({ editDisabled, initialCalendarEvent }) => (
          <OverviewCard
            cardId={ClaimManagementCardIds.overview}
            status={claim.claimStatus}
            referralIn={claim.referralIn}
            editDisabled={editDisabled}
            errorMessageList={errorMessageList}
            conditionHelper={conditionHelper}
            insurerStateBlock={insurerStateBlock}
            initialCalendarEvent={initialCalendarEvent}
            initialConsultDate={claim.initialConsultDate}
            isClaim={true}
            onDialogOpened={handleDialogOpened}
            isViewMode={claim.isViewMode}
            diagnoses={claim.currentDiagnoses.map(x => ({
              description: x.terminology?.text,
              side: x.diagnosisSide,
              readCode: x.terminology?.readCode
            }))}
          />
        )}
      </DataFetcher>
    );
  });

export const OverviewFormSection: React.FC<OverviewFormSectionProps> = ({
  errorMessageList
}) => {
  const claimContext = useContext(ClaimContext);
  const { claim } = claimContext;

  return (
    <ClaimConditionHelperDataFetcher claim={claim}>
      {helper => (
        <OverviewFormSectionBase
          errorMessageList={errorMessageList}
          conditionHelper={helper}
        />
      )}
    </ClaimConditionHelperDataFetcher>
  );
};
