import { FormApi } from "final-form";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { useField } from "react-final-form";

import { DateRangeType, Stack, useTheme } from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { StatementItemsContext } from "@modules/billing/screens/statement/context/StatementItemsContext.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DatePickerField } from "@ui-components/form/DatePickerField.tsx";
import { FieldCondition } from "@ui-components/form/FieldCondition.tsx";
import { SpinNumberInputField } from "@ui-components/form/SpinNumberInputField.tsx";
import { SubmissionFormDialogHeader } from "@ui-components/form/submission-form-dialog/SubmissionFormDialogHeader.tsx";
import { FormSubmitButtons } from "@ui-components/form/submission-form/FormSubmitButtons.tsx";
import { YesNoToggleField } from "@ui-components/form/Toggle/YesNoToggleField.tsx";

import { StatementExpanderButton } from "./StatementExpanderButton.tsx";
import { StatementGenerateFormValues } from "./StatementGenerateModal.type.ts";
import { StatementLastGeneratedMessageBar } from "./StatementLastGeneratedMessageBar.tsx";
import { StatementValueFilter } from "./StatementValueFilter.tsx";

interface StatementGenerateModalFieldsProps {
  disabled?: boolean;
}

export const StatementGenerateModalFields: React.FC<StatementGenerateModalFieldsProps> =
  observer(() => {
    const {
      statementGenerateFormNameOf,
      handleSubmitAction,
      selectedIds,
      setIsStatementNewModalVisible
    } = useContext(StatementItemsContext);

    const { billing } = useStores();
    const { spacing } = useTheme();

    const onHandleSubmit = async (
      form: FormApi<StatementGenerateFormValues>
    ) => {
      await handleSubmitAction(form.getState().values);
      setIsStatementNewModalVisible(false);
    };

    const {
      input: { value: endDate }
    } = useField<Date>(statementGenerateFormNameOf("endDate"), {
      subscription: { value: true }
    });

    const {
      input: { value: gapDays }
    } = useField<number>(statementGenerateFormNameOf("gapDays"), {
      subscription: { value: true }
    });

    return (
      <>
        <SubmissionFormDialogHeader
          onCancel={() => setIsStatementNewModalVisible(false)}
          title="Statement"
        />
        <Stack
          tokens={{ childrenGap: 8 }}
          styles={{
            root: {
              overflowY: "auto",
              padding: "0 24px"
            }
          }}
        >
          <StatementLastGeneratedMessageBar />
          <DatePickerField
            name={statementGenerateFormNameOf("endDate")}
            maxDate={DateTime.jsDateNow()}
            placeholder="Date(s)"
            required
            label="Accounts as at"
            dateRangeType={DateRangeType.Day}
            validateOnInitialize
            allowClear
          />
          <SpinNumberInputField
            label="Show transactions & activity"
            name={statementGenerateFormNameOf("gapDays")}
            max={120}
            min={1}
            styles={{ root: { width: 90 } }}
            fieldItemStyles={{
              root: { flexGrow: 2.8, flexBasis: 0 },
              item: { flexGrow: 0, width: 90 }
            }}
            parse={value => Number(value)}
            suffix="days"
          />
          {DateTime.fromJSDate(endDate)
            .minus({ days: gapDays })
            .toDayDefaultFormat()}{" "}
          - {DateTime.fromJSDate(endDate).toDayDefaultFormat()}
          <YesNoToggleField
            label="Filter by account value"
            name={statementGenerateFormNameOf("valueFilter")}
          />
          <FieldCondition
            when={statementGenerateFormNameOf("valueFilter")}
            is={(value: boolean) => !!value}
          >
            <StatementValueFilter />
          </FieldCondition>
          <StatementExpanderButton />
        </Stack>

        <FormSubmitButtons
          promptOnCancel
          hideButtonsSeparator
          onCancel={() => {
            setIsStatementNewModalVisible(false);
            billing.ui.setStatementSecondColumn(undefined);
          }}
          onSubmit={onHandleSubmit}
          submitButtonProps={{
            id: "submit-statement-form",
            text: "Generate",
            iconProps: undefined,
            disabled:
              selectedIds?.length === 0 || !endDate || !gapDays || gapDays > 120
          }}
          styles={{ root: { padding: spacing.l1 } }}
          cancelButtonProps={{ text: "Cancel" }}
        />
      </>
    );
  });
