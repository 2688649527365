import { useField } from "react-final-form";

import { LocationsSelectField } from "@modules/practice/screens/shared-components/location/LocationsSelectField.tsx";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";

import { waitingListItemFormNameOf } from "./WaitingListItemFormFields.tsx";
import { WaitingListItemFormValues } from "./WaitingListItemFormValues.ts";

export const WaitingListLocationSelectField: React.FC = () => {
  const { core, booking } = useStores();
  const fetchUserAvailability = async (userId: string) => {
    if (!userId) return undefined;

    const user = await core.getUser(userId);
    return await booking.getUserAvailability(user?.id);
  };

  const {
    input: { value: anyProvider }
  } = useField<WaitingListItemFormValues["anyProvider"]>(
    waitingListItemFormNameOf("anyProvider"),
    {
      subscription: { value: true }
    }
  );

  const {
    input: { value: providerId }
  } = useField<WaitingListItemFormValues["providerId"]>(
    waitingListItemFormNameOf("providerId"),
    {
      subscription: { value: true }
    }
  );

  return (
    <DataFetcher
      fetch={() => fetchUserAvailability(providerId)}
      refetchId={providerId}
    >
      {userAvailability => (
        <LocationsSelectField
          fieldItemStyle={{
            root: { flexGrow: 1, flexBasis: 0, minWidth: 0 }
          }}
          hideClearButton={true}
          name={waitingListItemFormNameOf("orgUnitId")}
          placeholder="Select location"
          label="Location"
          hideSearchOption
          showAllSelected={false}
          multiSelect={false}
          disabled={
            !userAvailability?.availableOrgUnitIds ||
            userAvailability?.availableOrgUnitIds.length <= 1
          }
          onFilter={options =>
            !providerId || anyProvider
              ? options
              : options.filter(
                  option =>
                    userAvailability?.availableOrgUnitIds.includes(option.key)
                )
          }
        />
      )}
    </DataFetcher>
  );
};
