import { observer } from "mobx-react-lite";
import { useForm, useFormState } from "react-final-form";

import {
  confirm,
  Grid,
  GridItem,
  Link,
  MessageBar,
  MessageBarType,
  Spinner,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { UserStorageKeys } from "@libs/gateways/user-experience/UserExperienceGateway.dtos.ts";
import { routes } from "@libs/routing/routes.ts";
import { RecentPatientFilterKeys } from "@modules/settings/screens/recent-patients/components/RecentPatientsFilter.types.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { usePatientLabel } from "@ui-components/hooks/usePatientLabel.ts";

import { PatientMergeConfirmationDialog } from "./PatientMergeConfirmationDialog.tsx";
import {
  PatientMergeFieldValues,
  patientMergeNameOf
} from "./PatientMergeFieldValues.ts";
import { PatientMergeInfo } from "./PatientMergeInfo.tsx";
import { PatientPickerWithInfo } from "./PatientPickerWithInfo.tsx";

export const PatientMergeFields: React.FC = observer(() => {
  const theme = useTheme();
  const {
    practice: {
      ui: { showPatMergeConfirmation }
    },
    routing
  } = useStores();

  const patientLabel = usePatientLabel(false);
  const { values } = useFormState<PatientMergeFieldValues>();
  const { masterId, duplicateId } = values;

  const form = useForm<PatientMergeFieldValues>();

  const openDialog = async () => {
    const isConfirmed = await confirm({
      confirmButtonProps: {
        text: "Continue"
      },
      cancelButtonProps: {
        text: "Back"
      },
      dialogContentProps: {
        title: `Discard ${patientLabel} selection`,
        subText: `Navigating to the incomplete notes will discard the ${patientLabel} selected for merging`
      }
    });

    if (!isConfirmed) return;

    form.reset();
    setTimeout(() => {
      routing.push({
        pathname: routes.recentPatients.basePath.pattern,
        state: {
          filter: {
            patientIds: [duplicateId],
            eventCode: RecentPatientFilterKeys.ConsultIncomplete
          }
        }
      });
    }, 10);
  };

  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <PatientMergeInfo />
      <Grid templateColumns="1fr 1fr" childrenTokens={{ columnGap: 28 }}>
        <GridItem styles={{ root: { gridColumn: "2" } }}>
          <DataFetcher<boolean>
            refetchId={duplicateId}
            fetch={async ({ userExperience }) => {
              if (!duplicateId) return false;

              const isEncounterOpen = await userExperience.checkEncounter(
                UserStorageKeys.OpenPatientTabs,
                duplicateId
              );
              return isEncounterOpen;
            }}
            fallback={<Spinner />}
          >
            {isEncounterOpen =>
              isEncounterOpen && (
                <MessageBar messageBarType={MessageBarType.warning}>
                  A consultation is open for the duplicate {patientLabel}. It is
                  highly recommended that you close any open consultations
                  before continuing with the merge.{" "}
                  <Link onClick={openDialog}>View incomplete notes</Link>
                </MessageBar>
              )
            }
          </DataFetcher>
        </GridItem>
        <Stack
          horizontal
          tokens={{ childrenGap: 28 }}
          styles={{ root: { gridRow: "2", gridColumn: "1/span 2" } }}
        >
          <PatientPickerWithInfo
            type={MessageBarType.success}
            label={`Master ${patientLabel}`}
            formNameId="masterId"
            formNamePatient="master"
            formNameStatus={patientMergeNameOf("masterStatusFilter")}
            borderColor={theme.palette.green}
            excludePatientId={duplicateId}
          />
          <PatientPickerWithInfo
            type={MessageBarType.error}
            label={`Duplicate ${patientLabel}`}
            formNameId="duplicateId"
            formNamePatient="duplicate"
            formNameStatus={patientMergeNameOf("duplicateStatusFilter")}
            borderColor={theme.palette.redDark}
            excludePatientId={masterId}
          />
        </Stack>
      </Grid>
      {showPatMergeConfirmation && masterId && duplicateId && (
        <DataFetcher<{ master?: Contact; duplicate?: Contact }>
          refetchId={masterId || duplicateId}
          fetch={async ({ practice }) => {
            const result = await practice.getContactsById([
              masterId,
              duplicateId
            ]);
            return {
              master: result.find(r => r.id === masterId),
              duplicate: result.find(r => r.id === duplicateId)
            };
          }}
          fallback={<Spinner />}
        >
          {({ master, duplicate }) =>
            master &&
            duplicate && (
              <PatientMergeConfirmationDialog
                master={master}
                duplicate={duplicate}
              />
            )
          }
        </DataFetcher>
      )}
    </Stack>
  );
});
