import {
  ObservationDto,
  QuestionnaireItem
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

import { Rand36ReponseTypes } from "./Rand36Form.types.ts";

const physicalFunctioningQuestions = [3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const physicalHealthLimitsQuestions = [13, 14, 15, 16];
const emotionalProblemsLimitsQuestions = [17, 18, 19];
const energyFatigueQuestions = [23, 27, 29, 31];
const emotionalWellBeingQuestions = [24, 25, 26, 28, 30];
const socialFunctioningQuestions = [20, 32];
const painQuestions = [21, 22];
const generalHealthQuestions = [1, 33, 34, 35, 36];

export const getColumnItems = (
  observations: ObservationDto,
  types: string[]
) => {
  const observationValues = observations.values;
  return observationValues
    .filter(m => types.includes(m.summary!))
    .sort(
      // The returned order must match types.
      (a, b) =>
        types.findIndex(x => x === a.summary) -
        types.findIndex(y => y === b.summary)
    );
};

const questionToFieldSet = [
  {
    text: Rand36ReponseTypes.PhysicalFunctioning,
    questions: physicalFunctioningQuestions
  },
  {
    text: Rand36ReponseTypes.LimitationsPhysicalHealth,
    questions: physicalHealthLimitsQuestions
  },
  {
    text: Rand36ReponseTypes.LimitationsEmotionalProblems,
    questions: emotionalProblemsLimitsQuestions
  },
  {
    text: Rand36ReponseTypes.EnergyFatigue,
    questions: energyFatigueQuestions
  },
  {
    text: Rand36ReponseTypes.EmotionalWellBeing,
    questions: emotionalWellBeingQuestions
  },
  {
    text: Rand36ReponseTypes.SocialFunctioning,
    questions: socialFunctioningQuestions
  },
  {
    text: Rand36ReponseTypes.Pain,
    questions: painQuestions
  },
  {
    text: Rand36ReponseTypes.GeneralHealth,
    questions: generalHealthQuestions
  }
];

export const findUnansweredQuestions = (
  answers: QuestionnaireItem[] | undefined,
  observationSummary?: string
) => {
  const observationSet = questionToFieldSet.find(
    x => x.text === observationSummary
  );

  if (observationSet) {
    const answeredQuestions = answers?.filter(
      x =>
        observationSet.questions.find(y => x.questionnaireItemId === y) &&
        x.answer
    );

    const unansweredQuestions = observationSet.questions.filter(
      x => !answeredQuestions?.find(y => y.questionnaireItemId === x)
    );

    if (answeredQuestions?.length === 0) {
      return observationSet.questions.join(", ");
    }

    return unansweredQuestions?.join(", ");
  }

  return undefined;
};
