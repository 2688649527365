export interface StatementGenerateFormValues {
  endDate: Date;
  owingOver?: number;
  creditOver?: number;
  valueFilter: boolean;
  accountContactIds?: string[];
  contactType?: string;
  gapDays: number;
}

export enum StatementBillToFilterType {
  All = "ALL",
  Patient = "PAT",
  Organisation = "ORG"
}
