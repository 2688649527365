import { observer } from "mobx-react-lite";
import { useState } from "react";

import { useTheme } from "@bps/fluent-ui";
import { ProviderOnlineStatus } from "@libs/gateways/bhb/bhbGateway.dtos.ts";
import { UserAvailabilityModel } from "@stores/booking/models/UserAvailabilityModel.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { SubmissionForm } from "@ui-components/form/submission-form/SubmissionForm.tsx";

import { getUserStylesSet } from "../../UserScreens.styles.tsx";
import { UserWorkingHourFormValues } from "./UserWorkingHourForm.types.ts";
import { UserWorkingHoursCard } from "./UserWorkingHoursCard.tsx";
import { UserWorkingHoursEmptyMessage } from "./UserWorkingHoursEmptyMessage.tsx";
import { UserWorkingHoursFormFieldList } from "./UserWorkingHoursFormFieldList.tsx";
import { UserWorkingHoursFormValidator } from "./UserWorkingHoursFormValidator.ts";
import { UserWorkingHoursWarningMessages } from "./UserWorkingHoursWarningMessages.tsx";
import { toBookingSchedules, toScheduleFormValues } from "./utils.ts";

type UserWorkingHoursFormProps = {
  userAvailability: UserAvailabilityModel;
};

export const UserWorkingHoursForm: React.FC<UserWorkingHoursFormProps> =
  observer(props => {
    const { userAvailability } = props;
    const { booking, core, notification, userExperience, bhb } = useStores();
    const theme = useTheme();
    const { formFooter } = getUserStylesSet(theme);

    const [isEdit] = useState(userAvailability.schedules.length !== 0);
    const [displayAppointmentWarning, setDisplayAppointmentWarning] =
      useState(false);

    const validator = new UserWorkingHoursFormValidator(
      core.hasMultipleActiveLocations
    );

    const { apptTypeBaseInterval } =
      userExperience.orgUnitSettingForLocation?.appointmentSetting || {};

    const initialValues = {
      userWorkingHours: toScheduleFormValues(
        userAvailability.schedules,
        core,
        apptTypeBaseInterval
      )
    };

    const onSubmit = async (values: UserWorkingHourFormValues) => {
      await booking.updateUserAvailability({
        userId: userAvailability.userId,
        bookingSchedules: toBookingSchedules(values.userWorkingHours, core)
      });

      const userWorkingHours = values.userWorkingHours;
      const bhbProviders = await bhb.getProvidersForUser(
        props.userAvailability.userId
      );

      const providersToUpdate = bhbProviders.filter(
        bhbProvider =>
          bhbProvider.providerOnlineStatus !== ProviderOnlineStatus.NO &&
          !userWorkingHours.find(
            workingHours => workingHours.location === bhbProvider.locationId
          )
      );

      await Promise.all(
        providersToUpdate.map(provider => {
          return bhb.updateProvider({
            ...provider.dto,
            providerOnlineStatus: ProviderOnlineStatus.NO
          });
        })
      );
    };

    return (
      <SubmissionForm<UserWorkingHourFormValues>
        formName="user-working-hours"
        readOnly={!core.hasUserSettingWritePermission}
        styles={{ main: { borderRadius: 4 } }}
        onSubmit={onSubmit}
        onSubmitSucceeded={() => {
          // After modifying existing hours, show a warning message about appointments until form is closed.
          // However don't show this message if the form was empty when it was originally opened
          if (isEdit) {
            setDisplayAppointmentWarning(true);
          }
          notification.success("Working hours have been updated.");
        }}
        validate={validator.validate}
        initialValues={initialValues}
        buttonsProps={{
          disableCancelOnPristine: true,
          onCancel: form => form.reset(),
          promptOnCancel: true,
          styles: {
            root: formFooter
          }
        }}
        LayoutWrapper={UserWorkingHoursCard}
      >
        {() => (
          <>
            <UserWorkingHoursWarningMessages
              displayAppointmentWarning={displayAppointmentWarning}
            />
            <UserWorkingHoursFormFieldList />
            <UserWorkingHoursEmptyMessage />
          </>
        )}
      </SubmissionForm>
    );
  });
