import { DateTime } from "@bps/utils";
import { ErrorMessageDto } from "@libs/gateways/acc/AccGateway.dtos.ts";
import {
  DocumentMetadataItem,
  SideOfBody
} from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

const ERROR_MESSAGE_DATE_TIME_FORMAT = "'at' hh:mm a 'on' dd/LL/yyyy";

export function getSideOfBodyText(value: string) {
  let text =
    Object.keys(SideOfBody)[
      Object.values(SideOfBody).indexOf(value as SideOfBody)
    ];

  if (text) {
    text = text.toLocaleLowerCase();
  }

  return text;
}

export const getNewMetadata = (
  metadata: DocumentMetadataItem[],
  items: Record<string, string | undefined>
) => {
  const newMetadata = [...metadata];

  for (const [property, value] of Object.entries(items)) {
    const index = metadata.findIndex(m => m.key === property);
    if (value) {
      if (index !== -1) {
        newMetadata[index].value = value;
      } else {
        newMetadata.push({
          key: property,
          value
        });
      }
    } else if (index !== -1) {
      newMetadata.splice(index, 1);
    }
  }

  return newMetadata;
};

export const getOverviewCardErrors = (
  errorMessageList: ErrorMessageDto[] | undefined
) => {
  const firstError = errorMessageList?.[0];

  return {
    lastRequestedDateTime: DateTime.fromISO(
      firstError?.lastRequestedDateTime
    )?.toFormat(ERROR_MESSAGE_DATE_TIME_FORMAT),
    nextRequestDateTime: DateTime.fromISO(
      firstError?.nextRequestDateTime
    )?.toFormat(ERROR_MESSAGE_DATE_TIME_FORMAT),
    maxAttempts: firstError?.maxAttempts,
    attemptNumber: firstError?.attemptNumber,
    errorMessage: firstError?.message,
    errorDetails: firstError?.details,
    attemptsUsedUp:
      firstError?.attemptNumber !== undefined &&
      firstError?.maxAttempts !== undefined &&
      firstError?.attemptNumber > firstError?.maxAttempts,
    claimWithClientError: firstError?.attemptNumber === undefined,
    firstError
  };
};
