import { observer } from "mobx-react-lite";
import { FC } from "react";

import {
  DirectionalHint,
  HoverCard,
  HoverCardType,
  IColumn,
  Spinner,
  Stack,
  Text,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize,
  useTheme
} from "@bps/fluent-ui";
import { compareDatesPredicate, DATE_FORMATS, DateTime } from "@bps/utils";
import { OutboundCommStatusesEnumText } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { ClinicalReminderCommContactRecordDto } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { OutboundCommContentDto } from "@libs/gateways/comms/CommsGateway.dtos.ts";
import { ClinicalActivityStatus } from "@shared-types/clinical/clinical-activity-status.type.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { DataFetcher } from "@ui-components/data-fetcher/DataFetcher.tsx";
import { ShimmeredDetailsList } from "@ui-components/ShimmeredDetailsList/ShimmeredDetailsList.tsx";

import { ClinicalActivityTableRow } from "./types/clinical-activity-table.type.ts";

type FollowupReminderCurrentStatusBadgeProps = {
  reminder: ClinicalActivityTableRow;
};
interface hoveredOutboundCommItems {
  commContactRecord: ClinicalReminderCommContactRecordDto;
  contactInfo?: string;
}
export const FollowupReminderCurrentStatusBadge: FC<FollowupReminderCurrentStatusBadgeProps> =
  observer(({ reminder }) => {
    const { comms } = useStores();
    const theme = useTheme();
    // TEMP: This layout is temporary and is to be expanded upon in future work.
    // This exists now to prove that the information was sent.
    if (reminder.clinicalReminderComm?.contactRecords) {
      const sortedContactRecords = Array.from(
        reminder.clinicalReminderComm.contactRecords
      ).sort((a, b) =>
        compareDatesPredicate(
          DateTime.fromISO(b.timeStamp),
          DateTime.fromISO(a.timeStamp)
        )
      );

      const latestReminder = sortedContactRecords[0];
      const clinicalActivityStatus = reminder.activity.dto.activityStatus;

      if (!latestReminder) {
        return null;
      }

      const greyStatuses = [
        ClinicalActivityStatus.Declined,
        ClinicalActivityStatus.NoLongerRequired,
        ClinicalActivityStatus.NoResponse
      ];

      const isGreyStatus = greyStatuses.find(x => x === clinicalActivityStatus);

      const getBadgeText = (
        item: ClinicalReminderCommContactRecordDto,
        index?: number
      ) => {
        let statusText: string | undefined = "";

        if (isGreyStatus) {
          statusText = reminder.activity.activityStatus;
        } else {
          statusText = `${OutboundCommStatusesEnumText[item.status]}`;
        }

        let badgeText = "";

        if (index) {
          badgeText = `${statusText} ${index}`;
        } else {
          badgeText = `${statusText} ${
            !isGreyStatus ? sortedContactRecords.length : ""
          }`;
        }

        return badgeText;
      };

      const outboundCommIds = sortedContactRecords.map(x => x.outboundCommId);

      const getBadgeColor = (reminder: ClinicalActivityTableRow) => {
        let color = TextBadgeColor.grey;
        const sentReminders =
          reminder.clinicalReminderComm?.contactRecords.length;
        if (isGreyStatus) {
          color = TextBadgeColor.grey;
        } else if (sentReminders) {
          color =
            sentReminders > 1 ? TextBadgeColor.red : TextBadgeColor.yellow;
        }

        return color;
      };

      const hoverCardColumns: IColumn[] = [
        {
          name: "",
          key: "date",
          minWidth: 150,
          maxWidth: 150,
          onRender: (item: hoveredOutboundCommItems) => (
            <Text>
              {DateTime.fromISO(item.commContactRecord.timeStamp).toFormat(
                DATE_FORMATS.LONG_DATE_TIME_FORMAT
              )}
            </Text>
          )
        },
        {
          name: "",
          key: "sentStatus",
          minWidth: 60,
          onRender: (item: hoveredOutboundCommItems, index: number) => {
            const sentOrder = sortedContactRecords.length - index;
            return (
              <TextBadge
                badgeSize={TextBadgeSize.small}
                badgeColor={
                  sentOrder > 1 ? TextBadgeColor.red : TextBadgeColor.yellow
                }
              >
                {getBadgeText(item.commContactRecord, sentOrder)}
              </TextBadge>
            );
          }
        },
        {
          name: "",
          key: "contactInfo",
          minWidth: 100,
          maxWidth: 100,
          onRender: (item: hoveredOutboundCommItems) => (
            <Text variant="small">
              {item.commContactRecord.contactType}: {item?.contactInfo}
            </Text>
          )
        }
      ];

      return (
        <Stack tokens={{ childrenGap: 4 }}>
          <Text>
            {DateTime.fromISO(latestReminder.timeStamp).toFormat(
              DATE_FORMATS.LONG_DATE_TIME_FORMAT
            )}
          </Text>
          <Stack
            horizontal
            tokens={{ childrenGap: 4 }}
            styles={{ root: { textAlign: "center", alignItems: "center" } }}
          >
            <TextBadge
              badgeColor={getBadgeColor(reminder)}
              badgeSize={TextBadgeSize.small}
            >
              <HoverCard
                type={HoverCardType.plain}
                plainCardProps={{
                  directionalHint: DirectionalHint.bottomCenter,
                  directionalHintFixed: true,
                  calloutProps: {
                    isBeakVisible: true,
                    calloutMinWidth: 430,
                    calloutMaxWidth: 430,
                    gapSpace: 5
                  },
                  onRenderPlainCard: () => (
                    <Stack
                      styles={{
                        root: {
                          padding: 16,
                          minWidth: 430,
                          maxWidth: 430
                        }
                      }}
                    >
                      <DataFetcher<OutboundCommContentDto[]>
                        fetch={async () => {
                          return await comms.getOutboundCommContents({
                            outboundCommIds
                          });
                        }}
                        fallback={<Spinner />}
                      >
                        {outboundCommContents => {
                          const items = sortedContactRecords.map(x => {
                            const item: hoveredOutboundCommItems = {
                              commContactRecord: x,
                              contactInfo: outboundCommContents.find(
                                content =>
                                  content.outboundCommId === x.outboundCommId
                              )?.channelProperties
                            };
                            return item;
                          });
                          return (
                            <Stack tokens={{ childrenGap: 4 }}>
                              <Text
                                styles={{
                                  root: {
                                    paddingLeft: 12,
                                    paddingBottom: 12,
                                    borderBottom: `1px solid ${theme.palette.neutralLight}`
                                  }
                                }}
                              >
                                Previous states
                              </Text>

                              <ShimmeredDetailsList
                                columns={hoverCardColumns}
                                items={items}
                                onRenderDetailsHeader={() => {
                                  return null;
                                }}
                              />
                            </Stack>
                          );
                        }}
                      </DataFetcher>
                    </Stack>
                  )
                }}
              >
                {getBadgeText(latestReminder)}
              </HoverCard>
            </TextBadge>
          </Stack>
        </Stack>
      );
    }

    return null;
  });
