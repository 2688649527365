import { FC, useContext } from "react";

import {
  dataAttribute,
  DataAttributes,
  IconButton,
  IContextualMenuItem,
  Stack,
  TooltipHost
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";
import { CalendarEventType } from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { ClinicalReminderCommCreateBatchArgs } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { InboxScreenContext } from "@modules/inbox/screens/context/InboxScreenContext.ts";
import { ClinicalActivityStatus } from "@shared-types/clinical/clinical-activity-status.type.ts";
import { useStores } from "@stores/hooks/useStores.ts";

import { ClinicalActivityTableRow } from "./types/clinical-activity-table.type.ts";

type FollowupReminderActionMenuProps = {
  item: ClinicalActivityTableRow;
};

export const FollowupReminderActionMenu: FC<
  FollowupReminderActionMenuProps
> = ({ item }) => {
  const { clinical, core, notification, booking } = useStores();
  const dto = {
    ...item.clinicalReminderComm
  };

  const inboxHelper = useContext(InboxScreenContext);

  const openBookingMenu = async () => {
    const activityDueDate = DateTime.jsDateFromISO(item.activity.dueDate);

    const startingDate: Date =
      activityDueDate && activityDueDate >= DateTime.jsDateNow()
        ? activityDueDate
        : DateTime.jsDateNow();

    if (!item.activity.userId) return;

    booking.ui.showCalendarEventDialog({
      type: CalendarEventType.Appointment,
      initialValues: {
        patientId: item.activity.patientId,
        providerId: item.activity.userId,
        startDate: startingDate,
        orgUnitId: core.locationId
      }
    });
  };

  const menuItems: IContextualMenuItem[] = [];
  const markAsSubItems = [
    {
      key: "markAsCompleted",
      className: "completed",
      text: "Completed",
      onClick: () => {
        if (item.clinicalReminderComm) {
          clinical.updateClinicalReminderComm({
            ...item.clinicalReminderComm,
            reminderStatus: ClinicalActivityStatus.Completed
          });

          inboxHelper.clearActivitySelection();
        }
      }
    },
    {
      key: "markAsNoResponse",
      className: "no_response",
      text: "No response",
      onClick: () => {
        if (dto) {
          if (item.clinicalReminderComm) {
            clinical.updateClinicalReminderComm({
              ...item.clinicalReminderComm,
              reminderStatus: ClinicalActivityStatus.NoResponse
            });

            inboxHelper.clearActivitySelection();
          }

          inboxHelper.clearActivitySelection();
        }
      }
    },
    {
      key: "markAsDeclined",
      className: "declined",
      text: "Declined",
      onClick: () => {
        if (dto) {
          if (item.clinicalReminderComm) {
            clinical.updateClinicalReminderComm({
              ...item.clinicalReminderComm,
              reminderStatus: ClinicalActivityStatus.Declined
            });

            inboxHelper.clearActivitySelection();
          }

          inboxHelper.clearActivitySelection();
        }
      }
    },
    {
      key: "markAsNoLongerRequired",
      className: "no_longer_required",
      text: "No longer required",
      onClick: () => {
        if (item.clinicalReminderComm) {
          clinical.updateClinicalReminderComm({
            ...item.clinicalReminderComm,
            reminderStatus: ClinicalActivityStatus.NoLongerRequired
          });

          inboxHelper.clearActivitySelection();
        }

        inboxHelper.clearActivitySelection();
      }
    }
  ];

  if (core.hasPermissions(Permission.ClinicalReminderWrite)) {
    menuItems.push({
      key: "markAsOptions",
      className: "mark_as",
      text: "Mark as",
      subMenuProps: {
        items: markAsSubItems
      }
    });
  }
  if (core.hasPermissions(Permission.CalendarEventWrite)) {
    menuItems.push({
      key: "book",
      className: "book",
      text: "Book appointment",
      onClick: () => {
        openBookingMenu();
      }
    });
  }
  if (core.hasPermissions(Permission.ClinicalReminderWrite)) {
    menuItems.push({
      key: "resend",
      className: "resend",
      text: "Resend",
      onClick: () => {
        const sendingRequest: ClinicalReminderCommCreateBatchArgs = {
          orgUnitId: core.locationId,
          activities: [item.activity.id]
        };
        clinical.createSendClinicalReminderBatch(sendingRequest);
        notification.info("Resending reminder...");
      }
    });
  }

  return (
    <Stack horizontalAlign="center" verticalAlign="center">
      <TooltipHost content="More">
        <IconButton
          {...dataAttribute(
            DataAttributes.Element,
            "followupReminder-list-actions-button"
          )}
          menuIconProps={{ iconName: "More" }}
          menuProps={{
            items: menuItems.map(i => ({
              ...dataAttribute(
                DataAttributes.Element,
                "followupReminder-list-actions-list-item"
              ),
              ...i
            })),
            calloutProps: {
              ...dataAttribute(
                DataAttributes.Element,
                "followupReminder-list-actions-list"
              )
            }
          }}
          styles={{
            root: { width: "32px", height: "36px", padding: 0 },
            flexContainer: { width: "32px", height: "36px" }
          }}
        />
      </TooltipHost>
    </Stack>
  );
};
